import { errorJSON, getErrorCode } from '../../utils/error-json';
import apiCall, { categoryTypes } from './index';
import { processTopic, processUser } from './utils';

export const ERRORS = {
  invalidParams: 'invalidParams',
  closedButNoTopic: 'closedButNoTopic',
  openedButNoTopic: 'openedButNoTopic',
};

export const getTopicById = async (topic_id) => {
  try {
    const data = await apiCall({
      url: '/m/topic.json',
      method: 'GET',
      queryParams: {
        topic_id,
      },
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return data;
    }
    return processTopic(data);
  } catch (error) {
    console.log(`Error getTopicById. Details: ${error}`);
    return undefined;
  }
};

export const createTopic = async (categorySlug, create_session_id, title, raw, raw_metadata, attachments) => {
  try {
    if (!categorySlug || !title || !raw) {
      return errorJSON(ERRORS.invalidParams, 'Category, title or raw content are invalid.');
    }
    const categoryObject = Object.values(categoryTypes).find((x) => x.slug === categorySlug);
    if (categoryObject.shouldHaveMetadata && !raw_metadata) {
      return errorJSON(ERRORS.invalidParams, `Metadata is required for ${categorySlug} topics.`);
    }
    const params = {
      category: categorySlug,
      title,
      raw,
      create_session_id,
    };
    if (raw_metadata) {
      params.raw_metadata = raw_metadata;
    }
    if (attachments) {
      params.attachments = attachments;
    }

    const data = await apiCall({
      url: '/m/topic-create.json',
      method: 'POST',
      bodyParams: params,
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return data;
    }
    return processTopic(data);
  } catch (error) {
    console.log(`Error createTopic. Details: ${error}`);
    return undefined;
  }
};

export const updateTopic = async (topic_id, title, raw, raw_metadata, attachments) => {
  try {
    if (!topic_id) {
      return errorJSON(ERRORS.invalidParams, 'Topic id is invalid.');
    }
    const params = {
      topic_id,
    };
    if (title) {
      params.title = title;
    }
    if (raw) {
      params.raw = raw;
    }
    if (raw_metadata) {
      params.raw_metadata = raw_metadata;
    }
    if (attachments) {
      params.attachments = attachments;
    }

    const data = await apiCall({
      url: '/m/topic.json',
      method: 'POST',
      bodyParams: params,
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return data;
    }
    return processTopic(data);
  } catch (error) {
    console.log(`Error updateTopic. Details: ${error}`);
    return undefined;
  }
};

export const closeTopic = async (topic_id, reason) => {
  try {
    const data = await apiCall({
      url: '/m/close-topic.json',
      bodyParams: {
        topic_id,
        reason,
      },
      method: 'POST',
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return data;
    }
    const topic = processTopic(data, true) || data;
    if (topic !== undefined) {
      return topic;
    }
    if (data?.id) {
      return errorJSON(ERRORS.closedButNoTopic);
    }
    return undefined;
  } catch {
    throw ERRORS.failedToFetchUser;
  }
};

export const openTopic = async (topic_id, resolution) => {
  try {
    const data = await apiCall({
      url: '/m/open-topic.json',
      bodyParams: {
        topic_id,
        resolution,
      },
      method: 'POST',
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return data;
    }
    const topic = processTopic(data, true) || data;
    if (topic !== undefined) {
      return topic;
    }
    if (data?.id) {
      return errorJSON(ERRORS.openedButNoTopic);
    }
    return undefined;
  } catch {
    throw ERRORS.failedToFetchUser;
  }
};
