import styled from 'styled-components/native';
import { SmallActivityIndicator } from '../../../../components/activity-indicators/ActivityIndicatorView';
import { AppCSS } from '../../../../containers';
import { SolidButton } from '../../../../containers/buttons';

export const CellContainer = styled.View`
  width: 100%;
  margin-bottom: 8px;
`;

export const CellButton = styled(SolidButton)`
  margin-left: 12px;
  ${(p) => (p.buttonWidth && !isNaN(p.buttonWidth) && p.buttonWidth > 0 ? `width: ${p.buttonWidth}px;` : '')};
`;

export const CellRow = styled.View`
  width: 100%;
  flex-direction: row;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-between;
`;

export const ReverseCellRow = styled.View`
  width: 100%;
  flex-direction: row;
  margin-bottom: 8px;
  align-items: center;
  justify-content: flex-end;
`;

export const CellLoader = styled(SmallActivityIndicator)`
  padding-left: 12px;
  padding-vertical: 7px;
`;

export const ProjectPicker = styled.Picker`
  ${AppCSS.inputStyle};
  ${(p) => (p.padding === 'true' ? 'margin-right: 12px;' : '')}
  margin-vertical: 4px;
`;
