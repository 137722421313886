import React from 'react';
import styled from 'styled-components/native';
import { SolidButton } from '../../../../containers/buttons';
import { SolidButtonLabel } from '../../../../containers/labels';
import useFormCreate from '../../../../hooks/useFormCreate';

export const SolidButtonWithBottomPadding = styled(SolidButton)`
  margin-bottom: 16px;
  margin-right: 12px;
`;

const FormCreateComponent = ({ categoryObject }) => {
  const openCreateForm = useFormCreate();

  if (!categoryObject) {
    return null;
  }

  const title = `CREATE ${categoryObject.object.toUpperCase()}`;

  return (
    <SolidButtonWithBottomPadding onPress={() => openCreateForm(categoryObject)}>
      <SolidButtonLabel title={title} />
    </SolidButtonWithBottomPadding>
  );
};

export default FormCreateComponent;
