import React, { useState } from 'react';
import { SolidButtonLabel } from '../../../../../../containers/labels';
import { SimpleAlert } from '../../../../../../utils/alert';
import isCompleteUrl from '../../../../../../utils/is-complete-url';
import { ATTACHMENT_BUTTON_WIDTH } from '../../../../../defines';
import DetailedInputRaw from '../../input/detailed-input-raw';
import { CellButton, CellRow } from '../../styled';

const AttachmentsAddUrl = ({ onSuccessfulProcessing, disabled, shouldStartProcess }) => {
  const [caption, setCaption] = useState('');
  const [url, setUrl] = useState('');

  const onPress = async () => {
    if (!shouldStartProcess()) {
      return;
    }
    if (isCompleteUrl(url)) {
      onSuccessfulProcessing(caption, { caption, url, type: 'link' });
      setCaption('');
      setUrl('');
      return;
    }
    SimpleAlert(
      'URL missing',
      "Please enter a valid url first. All urls need to start with 'https://' or 'http://' to be considered valid.",
    );
  };

  return (
    <CellRow>
      <DetailedInputRaw value={caption} setValue={setCaption} placeholder={'Enter url caption (optional)'} />
      <DetailedInputRaw addMarginLeft={true} value={url} setValue={setUrl} placeholder={'Enter url'} />
      <CellButton disabled={disabled} buttonWidth={ATTACHMENT_BUTTON_WIDTH} onPress={() => onPress()}>
        <SolidButtonLabel title={'ADD EXTERNAL URL'} />
      </CellButton>
    </CellRow>
  );
};

export default AttachmentsAddUrl;
