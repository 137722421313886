import React from 'react';
import { ActivityIndicator, StyleSheet, Platform } from 'react-native';
import styled from 'styled-components/native';
import { GENERIC_BLACK } from '../../../assets/colors';
import GlobalStyles from '../index';
import FillScreenAndCenterContainer from '../styled/views/FillScreenAndCenterContainer';
import FillScreenContainer from '../styled/views/FillScreenContainer';

const BackgroundView = styled(FillScreenContainer)`
  z-index: 99999;
`;

const CenterContainer = styled(FillScreenAndCenterContainer)``;

const FillScreenBottomHalfAndCenterContainer = styled(FillScreenAndCenterContainer)`
  top: 50%;
`;

const FillCenterBottomAndCenterContainer = styled(FillScreenAndCenterContainer)`
  top: 25%;
`;

const Container = styled.View`
  border-radius: 16px;
  shadow-offset: 0px 6px;
  shadow-radius: 4px;
  shadow-color: ${GENERIC_BLACK};
  shadow-opacity: 0.15;
  elevation: 2;
  background-color: ${(props) => props.backgroundColor};
  padding: 8px;
  z-index: 1000;
`;

export const POSITION = {
  center: 0,
  centerBottom: -1,
  bottom: -2,
};

export const SmallActivityIndicator = ({ ...rest }) => (
  <ActivityIndicator animating={true} style={styles.indicator} size={'small'} {...rest} />
);
export const LargeActivityIndicator = ({ ...rest }) => (
  <ActivityIndicator animating={true} style={styles.indicator} size={'large'} {...rest} />
);

const ActivityIndicatorView = ({ position = POSITION.center, color, backgroundColor, size = 'large' }) => {
  const OuterContainer = (() => {
    switch (position) {
      case POSITION.centerBottom:
        return FillCenterBottomAndCenterContainer;
      case POSITION.bottom:
        return FillScreenBottomHalfAndCenterContainer;
    }
    return CenterContainer;
  })();

  return (
    <BackgroundView>
      <OuterContainer>
        <Container backgroundColor={backgroundColor}>
          <ActivityIndicator animating={true} style={styles.indicator} size={size} color={color} />
        </Container>
      </OuterContainer>
    </BackgroundView>
  );
};

const styles = StyleSheet.create({
  indicator: {
    zIndex: 9999,
    ...GlobalStyles.absoluteCenter,
    marginTop: Platform.OS === 'ios' ? 3 : 0,
    marginLeft: Platform.OS === 'ios' ? 3 : 0, // issue activity view not centered on iOS
  },
});

export default ActivityIndicatorView;
