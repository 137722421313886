import { Platform } from 'react-native';
import Constants, { AppOwnership } from 'expo-constants';

const BUILD_NUMBER =
  Platform.OS === 'ios'
    ? Constants.manifest.ios.buildNumber
    : (Platform.OS === 'android' ? Constants.manifest.android.versionCode : undefined) || '0';
const FULL_VERSION = `${Constants.manifest.version || '0'}.${BUILD_NUMBER || '0'}`;
const SHORT_VERSION = `${Constants.manifest.version || '0'}`;

export const IS_EXPO_DEV = Constants.appOwnership
  ? Constants.appOwnership === AppOwnership.Expo
  : Constants?.manifest?.releaseChannel !== 'prod';

const EXPO_RELEASE_CHANNEL = Constants?.manifest?.releaseChannel;
const EXPO_VERSION = Constants?.manifest?.sdkVersion || Constants.expoVersion || 'unknown';
const RELEASE_CHANNEL_TEXT = EXPO_RELEASE_CHANNEL
  ? EXPO_RELEASE_CHANNEL === 'prod'
    ? ''
    : `\n[${EXPO_RELEASE_CHANNEL}]`
  : `\n[Expo ${EXPO_VERSION}]`;

export const FORMATTED_VERSION = `v${SHORT_VERSION}${RELEASE_CHANNEL_TEXT}`;

export const PLATFORMS_OBJECT = {
  ios: 'ios',
  android: 'android',
  default: 'unsupported',
};
