import React, { useEffect, useState } from 'react';
import { ProjectNavGreenText, ProjectNavRedText } from '../../../../containers/navigation/NavigationBarItem';
import { MiddleBlueSeparatorWithPadding } from '../../../../containers/views/Separators';
import WebScreenContainer from '../../../../containers/views/WebScreenContainer';
import TopicCell from '../../shared/cells/topic-cell';
import AttachmentsCell from '../../shared/form-cells/attachments/attachments-cell';
import DetailedInputCell from '../../shared/form-cells/input/detailed-input-cell';
import UserCell from '../../shared/cells/user-cell';
import RichTextEditorCell from '../../shared/form-cells/rich-text-editor-cell';
import TopicCloseComponent from '../../shared/TopicCloseComponent';

const typeCounts = { image: 100, video: 100, youtube: 100, vimeo: 100, topic: 100, link: 100 };

const UserPost = ({ navigation, route }) => {
  const item = route?.params?.item;

  const [userPost, setUserPost] = useState(item);

  const [files] = useState(
    item?.firstPost?.attachments?.list?.map((t, i) => ({ ...t, key: t?.id || t?.url || i })) || [],
  );

  useEffect(() => {
    if (userPost?.topic?.closed) {
      navigation.setOptions({
        headerRight: () => <ProjectNavRedText title={'USER POST CLOSED'} />,
      });
    } else {
      navigation.setOptions({
        headerRight: () => <ProjectNavGreenText title={'USER POST OPEN'} />,
      });
    }
  }, [userPost]);

  return (
    <WebScreenContainer>
      <TopicCell item={userPost} disabled={true} ignoreTitle={true} />
      <MiddleBlueSeparatorWithPadding />
      <TopicCloseComponent item={userPost} setItem={setUserPost} capitalizedCustomTag={'User Post'} />
      <MiddleBlueSeparatorWithPadding />
      <>
        <DetailedInputCell title={'id'} value={userPost?.id} disabled={true} />
        <DetailedInputCell title={'created on'} value={userPost?.dateString} disabled={true} />
        <DetailedInputCell title={'updated on'} value={userPost?.updatedDateString} disabled={true} />
        <RichTextEditorCell initial={userPost?.firstPost?.raw} disabled={true} />
      </>
      <AttachmentsCell value={files} />
      <MiddleBlueSeparatorWithPadding />
      <UserCell user={userPost?.firstPost?.user} />
    </WebScreenContainer>
  );
};

export default UserPost;
