import React, { useCallback } from 'react';
import { WhiteSeparatorWithPadding } from '../../../../containers/views/Separators';
import useDetails from '../../../../hooks/useDetails';
import {
  CellMainLabel,
  CellAdditionalLabel,
  CellAdditionalRedLabel,
  CellContent,
  CellImage,
  CellRowContent,
} from './styled';

const PostCell = ({ post, disabled = false, customTag }) => {
  const openDetails = useDetails();
  const _openDetails = () => openDetails(post);

  const user = `${post?.user?.fullName} | @${post?.user?.username || '-'}`;

  return (
    <CellContent disabled={disabled} onPress={_openDetails}>
      <CellRowContent>
        <CellMainLabel numberOfLines={1} text={post?.raw} />
        <WhiteSeparatorWithPadding />
        <CellAdditionalLabel numberOfLines={1} text={'ID: ' + post?.id} />
        <CellAdditionalLabel numberOfLines={1} text={'CREATOR: ' + user} />
        <CellAdditionalLabel numberOfLines={1} text={'LAST UPDATED ON: ' + post?.updatedDateString} />
        {post?.hidden && (
          <CellAdditionalRedLabel numberOfLines={1} text={`HIDDEN ${customTag?.toUpperCase() || 'POST'}`} />
        )}
      </CellRowContent>
    </CellContent>
  );
};

export default PostCell;
