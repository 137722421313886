import { getErrorCode } from '../../utils/error-json';
import apiCall, { categoryTypes } from './index';
import { processPosts } from './utils';

export const getQuestionAnswers = async (topic_id) => {
  try {
    const data = await apiCall({
      url: '/m/community-question-answers.json',
      method: 'GET',
      queryParams: {
        topic_id,
        allow_hidden: true,
      },
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return undefined;
    }
    return processPosts(data?.answers || []);
  } catch (error) {
    console.log(`Error getQuestionAnswers. Details: ${error}`);
    return undefined;
  }
};
