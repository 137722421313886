import React, { useEffect, useState } from 'react';
import { ConfirmationAlert } from '../../../../../utils/alert';
import DetailedInputCell from '../input/detailed-input-cell';
import { DetailedInputLabel } from '../input/styled';
import { CellContainer } from '../styled';
import AttachmentsFinalCell from './attachments-final-cell';
import AttachmentsUploadCell from './attachments-upload-cell';
import AttachmentsAddEpub from './rows/attachments-add-epub';
import AttachmentsAddImage from './rows/attachments-add-image';
import AttachmentsAddPdf from './rows/attachments-add-pdf';
import AttachmentsAddTopic from './rows/attachments-add-topic';
import AttachmentsAddUrl from './rows/attachments-add-url';
import AttachmentsAddVideo from './rows/attachments-add-video';
import AttachmentsAddVimeo from './rows/attachments-add-vimeo';
import AttachmentsAddYoutube from './rows/attachments-add-youtube';

export const AttachmentType = {
  JPEG: 'jpeg',
  PDF: 'pdf',
  MP4: 'mp4',
  EPUB: 'epub',
};

const typeCountsWithoutEpub = {
  image: 100,
  video: 100,
  pdf: 100,
  youtube: 100,
  vimeo: 100,
  link: 100,
  topic: 100,
  epub: 0,
};

const AttachmentsCell = ({
  title = 'Attachments',
  typeCounts = typeCountsWithoutEpub,
  value: list,
  setValue: setList,
}) => {
  const [pending, setPending] = useState(undefined);

  const disabled = !setList;

  const [allowImages, setAllowImages] = useState(!typeCounts || typeCounts?.image > 0);
  const [allowVideos, setAllowVideos] = useState(!typeCounts || typeCounts?.video > 0);
  const [allowPdfs, setAllowPdfs] = useState(!typeCounts || typeCounts?.pdf > 0);
  const [allowYoutube, setAllowYoutube] = useState(!typeCounts || typeCounts?.youtube > 0);
  const [allowVimeo, setAllowVimeo] = useState(!typeCounts || typeCounts?.vimeo > 0);
  const [allowUrls, setAllowUrls] = useState(!typeCounts || typeCounts?.link > 0);
  const [allowTopics, setAllowTopics] = useState(!typeCounts || typeCounts?.topic > 0);
  const [allowEpubs, setAllowEpubs] = useState(!typeCounts || typeCounts?.epub > 0);

  const getDateValueOf = () => {
    Date.now().valueOf();
  };

  const onSuccessfulProcessing = (c, item) => {
    setPending(undefined);
    setList && setList((old) => old.concat([{ caption: c, ...item, key: `${getDateValueOf()}` }]));
  };

  const onError = (caption) => {
    setPending(undefined);
  };

  const validateTopicId = (id) => {
    if (id?.length > 0) {
      const foundInList = list.find((p) => p.type === 'topic' && p.id === id);
      const foundInPending = pending?.type === 'topic' && pending?.id === id;
      if (!foundInList && !foundInPending) {
        return true;
      }
    }
    return false;
  };

  const afterFilePick = (base64, caption, type) => {
    if (base64) {
      setPending({ base64, caption, type });
    }
  };

  const shouldStartProcess = () => {
    if (pending) {
      return false;
    }
    setPending(undefined);
    return true;
  };

  const onDelete = (item) => {
    ConfirmationAlert('Confirmation', `Are you sure you want to remove '${item.caption || 'item'}'?`, () =>
      setList((old) =>
        [].concat(
          old.filter((p) => {
            console.log(p.key, item.key);
            return p.key !== item.key;
          }),
        ),
      ),
    );
  };

  useEffect(() => {
    if (typeCounts) {
      let imagesCount = 0;
      let videosCount = 0;
      let pdfsCount = 0;
      let youtubeCount = 0;
      let vimeoCount = 0;
      let urlsCount = 0;
      let topicsCount = 0;
      let epubCount = 0;

      const callback = (a) => {
        const type = a?.type || a?.extension;
        switch (type) {
          case 'jpeg':
          case 'png':
          case 'jpg':
          case 'image':
            imagesCount = imagesCount + 1;
            return;
          case 'mp4':
          case 'video':
            videosCount = videosCount + 1;
            return;
          case 'pdf':
            pdfsCount = pdfsCount + 1;
            return;
          case 'link':
            urlsCount = urlsCount + 1;
            return;
          case 'topic':
            topicsCount = topicsCount + 1;
            return;
          case 'youtube':
            youtubeCount = youtubeCount + 1;
            return;
          case 'vimeo':
            vimeoCount = vimeoCount + 1;
            return;
          case 'epub':
            epubCount = epubCount + 1;
            return;
        }
      };

      list.forEach(callback);
      pending && callback(pending);

      setAllowImages(imagesCount < typeCounts?.image);
      setAllowVideos(videosCount < typeCounts?.video);
      setAllowUrls(urlsCount < typeCounts?.link);
      setAllowTopics(topicsCount < typeCounts?.topic);
      setAllowYoutube(youtubeCount < typeCounts?.youtube);
      setAllowVimeo(vimeoCount < typeCounts?.vimeo);
      setAllowPdfs(pdfsCount < typeCounts?.pdf);
      setAllowEpubs(epubCount < typeCounts?.epub);
    }
  }, [list, pending]);

  const makeFirst = (item) => {
    setList((old) => [item].concat(old.filter((l) => l.key !== item?.key)));
  };

  return (
    <>
      {!disabled && (
        <CellContainer>
          <DetailedInputLabel title={title.toUpperCase()} />
          {(!typeCounts || allowImages) && (
            <AttachmentsAddImage disabled={pending} {...{ shouldStartProcess, afterFilePick }} />
          )}
          {(!typeCounts || allowEpubs) && (
            <AttachmentsAddEpub disabled={pending} {...{ shouldStartProcess, afterFilePick }} />
          )}
          {(!typeCounts || allowVideos) && (
            <AttachmentsAddVideo disabled={pending} {...{ shouldStartProcess, afterFilePick }} />
          )}
          {(!typeCounts || allowPdfs) && (
            <AttachmentsAddPdf disabled={pending} {...{ shouldStartProcess, afterFilePick }} />
          )}
          {(!typeCounts || allowYoutube) && (
            <AttachmentsAddYoutube disabled={pending} {...{ onSuccessfulProcessing, shouldStartProcess }} />
          )}
          {(!typeCounts || allowVimeo) && (
            <AttachmentsAddVimeo disabled={pending} {...{ shouldStartProcess, onSuccessfulProcessing }} />
          )}
          {(!typeCounts || allowUrls) && (
            <AttachmentsAddUrl disabled={pending} {...{ shouldStartProcess, onSuccessfulProcessing }} />
          )}
          {(!typeCounts || allowTopics) && (
            <AttachmentsAddTopic
              disabled={pending}
              {...{ onSuccessfulProcessing, shouldStartProcess, validateTopicId }}
            />
          )}
          {list.map((a, i) => {
            return (
              <AttachmentsFinalCell
                makeFirst={makeFirst}
                disabled={disabled}
                key={`u${i}`}
                item={a}
                onDelete={onDelete}
              />
            );
          })}
          {pending && (
            <AttachmentsUploadCell
              base64={pending.base64}
              caption={pending.caption}
              type={pending.type}
              onFinishUploading={onSuccessfulProcessing}
              onError={onError}
            />
          )}
        </CellContainer>
      )}
      {disabled &&
        (!list || list?.length === 0 ? (
          <DetailedInputCell title={title} value={'0 attachments found'} disabled={true} />
        ) : (
          <>
            {list.map((a, i) => {
              return (
                <AttachmentsFinalCell
                  makeFirst={makeFirst}
                  disabled={disabled}
                  key={`un${i}`}
                  item={a}
                  onDelete={onDelete}
                />
              );
            })}
          </>
        ))}
    </>
  );
};

export default AttachmentsCell;
