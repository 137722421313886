import * as ExpoFont from 'expo-font';

const regular = 'regular';
const medium = 'medium';
const bold = 'bold';
const italic = 'italic';

const load = async () => {
  try {
    await ExpoFont.loadAsync({
      [regular]: require('./fonts/TT-Norms/TT_Norms_Regular.ttf'),
      [medium]: require('./fonts/TT-Norms/TT_Norms_Medium.ttf'),
      [italic]: require('./fonts/TT-Norms/TT_Norms_Italic.ttf'),
      [bold]: require('./fonts/TT-Norms/TT_Norms_Bold.ttf'),
    });
  } catch (e) {
    console.log(e);
  }
};

const Fonts = {
  regular,
  medium,
  bold,
  italic,
  load,
};

export default Fonts;
