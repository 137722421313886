import styled from 'styled-components/native';
import { GENERIC_WHITE, GREEN, RED, TEXT_BLACK, TINT_RED } from '../../../../../assets/colors';
import { AppCSS, THUMBNAIL_RATIO, THUMBNAIL_WIDTH } from '../../../../containers';
import { StyledImage } from '../../../../containers/images/StyledImage';
import { MediumTitleLabel, SubtitleLabel } from '../../../../containers/labels';

export const CellContent = styled.TouchableOpacity`
  ${AppCSS.borderRadius};
  ${AppCSS.bottomShadow};
  padding-horizontal: 8px;
  padding-vertical: 8px;
  margin-bottom: 12px;
  flex-direction: row;
  background-color: ${GENERIC_WHITE};
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const CellRowContent = styled.View`
  padding-top: 4px;
  padding-right: 12px;
  flex: 1;
`;

export const CellImage = styled(StyledImage)`
  ${AppCSS.borderRadius};
  width: ${THUMBNAIL_WIDTH}px;
  height: ${THUMBNAIL_WIDTH}px;
  resize-mode: cover;
  aspect-ratio: ${THUMBNAIL_RATIO};
  overflow: hidden;
  margin-right: 8px;
`;

export const CellMainLabel = styled(MediumTitleLabel)`
  margin-bottom: 4px;
`;

export const CellAdditionalLabel = styled(SubtitleLabel)`
  margin-bottom: 2px;
`;

export const CellAdditionalRedLabel = styled(CellAdditionalLabel)`
  color: ${RED};
`;

export const CellAdditionalGreenLabel = styled(CellAdditionalLabel)`
  color: ${GREEN};
`;

export const BenefactorCellInfo = styled.View`
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: auto;
`;

export const BenefactorUsersCountLabel = styled(CellAdditionalLabel)`
  color: ${TEXT_BLACK};
`;

export const BenefactorCellButtonWrapper = styled.View`
  align-items: center;
  justify-content: center;
  margin-left: 24px;
`;
