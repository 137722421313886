import * as WebBrowser from 'expo-web-browser';
import { Platform } from 'react-native';
import { useEffect, useRef, useState } from 'react';
import { fetchCurrentUser } from '../networking/calls/user';
import useReduxState, { setAction } from '../store/genericSet';
import { clearSessionData, processAuthUrl, getLoginUrl } from '../networking/calls/session';
import { getUserApiKey, setUsername } from '../networking';
import * as AuthSession from 'expo-auth-session';

if (Platform.OS === 'web') {
  WebBrowser.maybeCompleteAuthSession();
}

const redirectUrl = AuthSession.makeRedirectUri({ useProxy: false }) + '/auth_redirect';

export const ERRORS = {
  authFailed: 'authFailed',
  invalidRedirectResult: 'invalidRedirectResult',
  failedToResumeSession: 'failedToResumeSession',
  failedToFetchUserData: 'failedToFetchUserData',
};

export const resumeSessionWithRawReduxStore = async (store) => {
  try {
    const user = await fetchCurrentUser();
    if (user) {
      store.dispatch(setAction('currentUser', user, 'auth'));
    }
  } catch {
    /* Do nothing */
  }
};

const useAuth = () => {
  const privateKeyRef = useRef();
  const uniqueStringRef = useRef();

  const [currentUser, setCurrentUser] = useReduxState('auth', 'currentUser');

  const login = async () => {
    const userApiKey = await getUserApiKey();
    if (userApiKey) {
      await refreshCurrentUser();
      return;
    }

    if (currentUser) {
      return;
    }

    await logout();

    const url = await getLoginUrl(redirectUrl, privateKeyRef, uniqueStringRef);
    if (url && redirectUrl) {
      const result = await AuthSession.startAsync({
        authUrl: url,
        returnUrl: redirectUrl,
      });
      if (
        (result && (result.type === 'cancel' || result.type === 'dismiss')) ||
        result.type === 'locked' ||
        result.type === 'opened'
      ) {
        return;
      } else if (result && result.type === 'success') {
        await processAuthUrl(result.url, privateKeyRef, uniqueStringRef);
        try {
          await refreshCurrentUser();
          return;
        } catch {
          throw ERRORS.failedToFetchUserData;
        }
      } else {
        throw ERRORS.invalidRedirectResult;
      }
    }
    throw ERRORS.authFailed;
  };

  const refreshCurrentUser = async () => {
    const userApiKey = await getUserApiKey();
    if (userApiKey) {
      const user = await fetchCurrentUser();
      const username = user?.username;
      if (user && username) {
        setCurrentUser(user);
        setUsername(username);
      } else {
        await logout();
        throw ERRORS.failedToFetchUserData;
      }
      return user;
    }
    return currentUser;
  };

  // Always use this logout. This logout updates the REDUX STORE, which holds the value for currentUser.
  const logout = async () => {
    await clearSessionData();
    setCurrentUser(undefined);
  };

  return {
    login,
    refreshCurrentUser,
    logout,
  };
};

export default useAuth;
