import React from 'react';
import { BLUE } from '../../../../assets/colors';
import styled from 'styled-components/native';
import { ButtonLabel } from '../../labels';
import AddIcon from '../../../../assets/icons/icon-add.svg';

const Container = styled.View`
  height: 24px;
  align-items: center;
  flex-direction: row;
`;

const IconWithContainer = styled.View`
  padding-right: 4px;
`;

const Text = styled(ButtonLabel)`
  color: ${BLUE};
`;

const PlusButtonContent = ({ title }) => {
  return (
    <Container>
      <IconWithContainer>
        <AddIcon />
      </IconWithContainer>
      <Text text={title} />
    </Container>
  );
};

export default PlusButtonContent;
