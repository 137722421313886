import { Platform, StyleSheet } from 'react-native';
import styled from 'styled-components/native';
import { BLUE, GRAY, RED, TEXT_DARK_GRAY, TRANSPARENT } from '../../../../../../assets/colors';
import Fonts from '../../../../../../assets/fonts';
import { SmallActivityIndicator } from '../../../../../components/activity-indicators/ActivityIndicatorView';
import { AppCSS } from '../../../../../containers';
import { InputTitleLabel, SubtitleLabel } from '../../../../../containers/labels';

export const styles = StyleSheet.create({
  placeholder: {
    fontFamily: Fonts.regular,
    fontSize: 11,
    color: TEXT_DARK_GRAY,
    alignSelf: 'center',
  },
});

export const DetailedInputLabel = styled(InputTitleLabel)`
  margin-bottom: 4px;
`;

export const DescriptionInputLabel = styled(InputTitleLabel)`
  margin-bottom: 4px;
`;

export const SeparatorContainer = styled.View`
  margin-top: -1px;
`;

export const DetailedInputRawContainer = styled.View`
  flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  ${(p) => (p.addMarginLeft === true ? 'margin-left: 12px;' : '')};
`;

export const DetailedInput = styled.TextInput`
  ${Platform.OS === 'ios' ? 'margin-top: -4px;' : ''};
  ${AppCSS.inputStyle};
  min-height: ${(p) => (p.multiline === true ? '94' : '26')}px;
  ${(p) =>
    p.multiline === true
      ? `border-width: 2px;
  border-color: ${!p.selected ? GRAY : TRANSPARENT};`
      : ''}
  ${(p) => (p.multiline === true && p.height && p.height > 0 ? `height: ${p.height}px;` : 'auto')};
`;

export const TitleRow = styled.View`
  width: auto;
  flex-direction: row;
  align-items: left;
  justify-content: flex-start;
`;

export const TitleSubRow = styled.View`
  width: auto;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
`;

export const TitleLoaderContainer = styled.View`
  padding-left: 4px;
`;

export const TitleLoader = styled(SmallActivityIndicator)`
  position: absolute;
  top: -4px;
`;
