import React, { useState } from 'react';
import { SolidButtonLabel } from '../../../../../../containers/labels';
import useImagePicker, { PICKER_TYPES } from '../../../../../../hooks/useImagePicker';
import { SimpleAlert } from '../../../../../../utils/alert';
import { getErrorCode } from '../../../../../../utils/error-json';
import { ATTACHMENT_BUTTON_WIDTH } from '../../../../../defines';
import DetailedInputRaw from '../../input/detailed-input-raw';
import { CellButton, CellRow } from '../../styled';
import { AttachmentType } from '../attachments-cell';

const AttachmentsAddImage = ({ afterFilePick, disabled, shouldStartProcess }) => {
  const [caption, setCaption] = useState('');

  const { pickImage } = useImagePicker(PICKER_TYPES.wide);

  const onUploadPress = async () => {
    if (!shouldStartProcess()) {
      return;
    }
    const result = await pickImage();
    const errorCode = getErrorCode(result);
    if (errorCode || !result) {
      SimpleAlert(
        'Choose Image',
        `Something went wrong while processing your image. Please try again. Error details: ${errorCode}`,
      );
      return;
    }

    const { base64 } = result;
    if (!base64) {
      SimpleAlert(
        'Choose Image',
        `Something went wrong while processing your image. Please try again. Error details: ${errorCode}`,
      );
      return;
    }

    afterFilePick(base64, caption, AttachmentType.JPEG);
    setCaption('');
  };

  return (
    <CellRow>
      <DetailedInputRaw value={caption} setValue={setCaption} placeholder={'Enter image caption (optional)'} />
      <CellButton disabled={disabled} buttonWidth={ATTACHMENT_BUTTON_WIDTH} onPress={() => onUploadPress()}>
        <SolidButtonLabel title={'UPLOAD IMAGE'} />
      </CellButton>
    </CellRow>
  );
};

export default AttachmentsAddImage;
