import React, { useEffect, useRef, useState } from 'react';
import { LargeActivityIndicator } from '../../../../components/activity-indicators/ActivityIndicatorView';
import { RegularTitleLabel } from '../../../../containers/labels';
import { getClosedTopics, getSuspendedUsers } from '../../../../networking/calls/disabled';
import SearchList, { ITEM_TYPE } from '../search/search-list';
import { SearchNoResultsContainer } from '../search/styled';

const DisabledListComponent = ({ itemType, types }) => {
  const [results, setResults] = useState([]);

  const noFiltersRef = useRef(true);

  const [loading, setLoading] = useState(false);
  const onRefresh = async () => {
    setLoading(true);

    let r = [];
    if (itemType === ITEM_TYPE.topic && types) {
      r = (await getClosedTopics(types)) || [];
    } else if (itemType === ITEM_TYPE.user) {
      r = (await getSuspendedUsers()) || [];
    }

    noFiltersRef.current = true;

    setResults(r);

    setLoading(false);
  };

  useEffect(() => {
    onRefresh();
  }, []);

  return (
    <>
      {!loading && results?.length > 0 && (
        <SearchList
          loading={loading}
          results={results}
          setResults={setResults}
          noFiltersRef={noFiltersRef}
          itemType={itemType}
        />
      )}
      {!loading && results?.length === 0 && (
        <SearchNoResultsContainer>
          <RegularTitleLabel text={'0 entries found'} />
        </SearchNoResultsContainer>
      )}
      {loading && <LargeActivityIndicator />}
    </>
  );
};

export default DisabledListComponent;
