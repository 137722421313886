import React, { useEffect, useMemo } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { navBarOptions, noNavBarOptions } from '../containers/navigation/NavigationBar';
import useAuth from '../hooks/useAuth';
import { fetchCurrentUser } from '../networking/calls/user';
import useReduxState from '../store/genericSet';
import AppComponent from './app';
import Login from './login';

const LoginStack = createStackNavigator();

const LoginComponent = () => {
  return (
    <LoginStack.Navigator headerMode={'screen'} screenOptions={noNavBarOptions}>
      <LoginStack.Screen name={'Login'} component={Login} />
    </LoginStack.Navigator>
  );
};

const SwitchNavigator = ({ currentUser, logout }) => {
  const userExists = useMemo(() => currentUser !== undefined, [currentUser?.id]);

  return userExists ? <AppComponent logout={logout} /> : <LoginComponent />;
};

const AppStack = () => {
  const [currentUser, setCurrentUser] = useReduxState('auth', 'currentUser');
  const { logout } = useAuth();

  useEffect(() => {
    const doWork = async () => {
      const user = await fetchCurrentUser();
      if (user) {
        setCurrentUser(user);
      }
    };

    doWork();
  }, []);

  return (
    <NavigationContainer>
      <SwitchNavigator currentUser={currentUser} logout={logout} />
    </NavigationContainer>
  );
};

export default AppStack;
