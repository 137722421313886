import React from 'react';
import { GREEN, RED } from '../../../../../../assets/colors';
import { SolidButtonLabel } from '../../../../../containers/labels';
import { CellButton, CellContainer, CellRow } from '../styled';
import DetailedInputRaw from './detailed-input-raw';
import {
  DescriptionInputLabel,
  DetailedInputLabel,
  TitleLoader,
  TitleLoaderContainer,
  TitleRow,
  TitleSubRow,
} from './styled';

const DetailedInputCell = ({
  value,
  setValue,
  title,
  description,
  onChange,
  placeholder,
  focusOnOpen = false,
  multiline = false,
  scrollRef,
  shouldChangeText,
  loading,
  disabled = false,
  buttonPress,
  buttonTitle,
  buttonColor = GREEN,
  buttonWidth,
  forceEnableButton = false,
  buttonPress2,
  buttonTitle2,
  buttonColor2 = RED,
  buttonWidth2,
}) => {
  return (
    <CellContainer>
      <TitleRow>
        <TitleSubRow>
          <DetailedInputLabel title={title.toUpperCase()} />
          {description && <DescriptionInputLabel title={description.toUpperCase()} />}
        </TitleSubRow>
        {loading && (
          <TitleLoaderContainer>
            <TitleLoader />
          </TitleLoaderContainer>
        )}
      </TitleRow>
      <CellRow>
        <DetailedInputRaw
          {...{
            disabled,
            value,
            setValue,
            onChange,
            placeholder,
            focusOnOpen,
            multiline,
            scrollRef,
            shouldChangeText,
          }}
        />
        {buttonPress && buttonTitle && (
          <CellButton
            buttonWidth={buttonWidth}
            disabled={disabled ? !forceEnableButton : disabled}
            onPress={buttonPress}
            backgroundColor={buttonColor}>
            <SolidButtonLabel title={buttonTitle} />
          </CellButton>
        )}
        {buttonPress2 && buttonTitle2 && (
          <CellButton
            buttonWidth={buttonWidth2}
            disabled={disabled ? !forceEnableButton : disabled}
            onPress={buttonPress2}
            backgroundColor={buttonColor2}>
            <SolidButtonLabel title={buttonTitle2} />
          </CellButton>
        )}
      </CellRow>
    </CellContainer>
  );
};

export default DetailedInputCell;
