import { get } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { currentReducerKeys } from '../reducers';
import { SET_ACTION_PREFIX } from './constants';

export const setAction = (key, value, reducerKey) => ({
  type: SET_ACTION_PREFIX + key,
  reducerKey,
  payload: value,
});

const invalidReducerSetAction = (reducerKey) => {
  console.log(`Invalid reducer dispatch for provided reducer "${reducerKey}"! Fix and reload the app.`);
};

const useReduxState = (reducerKey, stateKey) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => get(state, `${reducerKey}.${stateKey}`), shallowEqual);
  const setValue = (v) => {
    if (v && v instanceof Function) {
      dispatch(setAction(stateKey, v(value), reducerKey));
      return;
    }
    dispatch(setAction(stateKey, v, reducerKey));
  };

  const invalidReducerKey = !currentReducerKeys.includes(reducerKey);
  if (invalidReducerKey) {
    invalidReducerSetAction(reducerKey);
  }

  return [value, setValue];
};

export default useReduxState;
