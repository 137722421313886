import React, { useCallback } from 'react';
import { BigTitleLabel, RegularTitleLabel } from '../../../../containers/labels';
import TopicCell from '../cells/topic-cell';
import UserCell from '../cells/user-cell';
import SearchFilters from './search-filters';
import { SearchNoResultsContainer, SearchResultsHeader, SearchContainer, SearchSectionContainer } from './styled';

export const ITEM_TYPE = {
  topic: 0,
  post: 1,
  user: 2,
};

const SearchList = ({ loading, searchText, results, setResults, noFiltersRef, itemType, onCellPress }) => {
  const toggleFilter = useCallback((title) => {
    setResults((old) => {
      noFiltersRef.current = true;
      let changed = old.map((value) => {
        const key = value.title;
        const isUndefined = value?.show === undefined;
        const show = value?.show || false;
        const newValue = { ...value, show: key === title ? !show : isUndefined ? undefined : show };
        noFiltersRef.current = noFiltersRef.current && newValue.show !== true;
        return newValue;
      });
      if (noFiltersRef.current) {
        changed = changed.map((value) => {
          return { ...value, show: undefined };
        });
      }
      return changed;
    });
  }, []);

  const createItem = (r) => {
    if (itemType === ITEM_TYPE.topic) {
      return <TopicCell key={r.id} item={r} />;
    } else if (itemType === ITEM_TYPE.user) {
      return <UserCell key={r.id} user={r} onCellPress={onCellPress} />;
    }
    return null;
  };

  const createTopicsSection = (item, i) => {
    if (!noFiltersRef.current && !item?.show) {
      return null;
    }
    return (
      <SearchSectionContainer key={i}>
        <SearchResultsHeader>
          <BigTitleLabel text={item.title} />
        </SearchResultsHeader>
        {item.topics.map((r, j) => createItem && createItem(r, j))}
      </SearchSectionContainer>
    );
  };

  return (
    <SearchContainer>
      {results.length > 0 ? (
        <>
          {itemType === ITEM_TYPE.topic && (
            <>
              <SearchFilters toggleFilter={toggleFilter} results={results} />
              {results.map((item, i) => createTopicsSection(item, i))}
            </>
          )}
          {itemType === ITEM_TYPE.user && <>{results.map((item, i) => createItem(item, i))}</>}
        </>
      ) : (
        <>
          {searchText && searchText.length > 0 && !loading && (
            <SearchNoResultsContainer>
              <RegularTitleLabel text={`No results for "${searchText}"`} />
            </SearchNoResultsContainer>
          )}
        </>
      )}
    </SearchContainer>
  );
};

export default SearchList;
