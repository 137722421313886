import { Platform, Dimensions } from 'react-native';
import Constants from 'expo-constants';

export const DEVICE_HAS_NOTCH = (() => {
  return (
    (Platform.OS === 'ios' && Constants.statusBarHeight > 20) ||
    (Platform.OS === 'android' && Constants.statusBarHeight > 24)
  );
})();

export const STATUS_BAR_HEIGHT = (() => Constants.statusBarHeight)();
export const CHIN_HEIGHT = (() => (Platform.OS === 'ios' && DEVICE_HAS_NOTCH ? 34 : 0))();
export const CHIN_LAND_WIDTH = (() => (Platform.OS === 'ios' && DEVICE_HAS_NOTCH ? 21 : 0))();
export const SIDES_LAND_WIDTH = (() => (Platform.OS === 'ios' && DEVICE_HAS_NOTCH ? 44 : 0))();
export const SCREEN_WIDTH = (() => Dimensions.get('window').width)();
export const SCREEN_HEIGHT = (() => Dimensions.get('window').height)();
