// Private file; Recommended to store outside git

const categoryTypes = {
  news: { title: 'News', slug: 'news', object: 'News Post' },
  posts: { title: 'Posts', slug: 'posts', object: 'User Post' },
  fatwas: { title: 'Fatwas', slug: 'fatwas', object: 'Fatwa' },
  glossary: { title: 'Glossary', slug: 'glossary', object: 'Glossary' },
  excerpts: { title: 'Excerpts', slug: 'excerpts', object: 'Excerpt' },
  fiqh: { title: 'Fiqh', slug: 'fiqh', object: 'Fiqh' },
  questions: { title: 'Questions', slug: 'questions', object: 'Question' },
  books: { title: 'Books', slug: 'books', shouldHaveMetadata: true, object: 'Book' },
  researchPapers: {
    title: 'Research Papers',
    slug: 'research-papers',
    shouldHaveMetadata: true,
    object: 'Research Paper',
  },
  ifikrDigests: { title: 'Featured', slug: 'i-fikr-digests', shouldHaveMetadata: true, object: 'Featured' },
  articles: { title: 'Articles', slug: 'articles', shouldHaveMetadata: true, object: 'Article' },
  events: { title: 'Events', slug: 'events', shouldHaveMetadata: true, object: 'Event' },
  eventbrite: { title: 'Eventbrite', slug: 'eventbrite', shouldHaveMetadata: true, object: 'Event' }, // not present in dashboard
};

const ifikrContent = [
  categoryTypes.news,
  categoryTypes.fatwas,
  categoryTypes.glossary,
  categoryTypes.excerpts,
  categoryTypes.fiqh,
  categoryTypes.books,
  categoryTypes.researchPapers,
  categoryTypes.ifikrDigests,
  categoryTypes.articles,
  categoryTypes.events,
];

const userContent = [categoryTypes.posts, categoryTypes.questions];

const common = {
  models: {
    categoryTypes,
    userContent,
    ifikrContent,
  },
};

const production = {
  ...common,

  networking: {
    baseUrl: 'https://ifikrapp.isra.my',
  },
};

const staging = {
  ...common,

  networking: {
    baseUrl: 'https://ifikr.atta.works',
  },
};

const local = {
  ...common,

  networking: {
    baseUrl: 'http://localhost:3000',
  },
};

// Set current environment here
export default production;
