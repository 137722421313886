import React, { useEffect, useState } from 'react';
import { ProjectNavGreenText, ProjectNavRedText } from '../../../../containers/navigation/NavigationBarItem';
import { MiddleBlueSeparatorWithPadding } from '../../../../containers/views/Separators';
import WebScreenContainer from '../../../../containers/views/WebScreenContainer';
import PostCell from '../../shared/cells/post-cell';
import UserCell from '../../shared/cells/user-cell';
import DetailedInputCell from '../../shared/form-cells/input/detailed-input-cell';
import RichTextEditorCell from '../../shared/form-cells/rich-text-editor-cell';
import PostHideComponent from '../../shared/PostHideComponent';

const Answer = ({ navigation, route }) => {
  const item = route?.params?.item;

  const [answer, setAnswer] = useState(item);

  useEffect(() => {
    if (answer?.hidden) {
      navigation.setOptions({
        headerRight: () => <ProjectNavRedText title={'ANSWER HIDDEN'} />,
      });
    } else {
      navigation.setOptions({
        headerRight: () => <ProjectNavGreenText title={'ANSWER VISIBLE'} />,
      });
    }
  }, [answer]);

  return (
    <WebScreenContainer>
      <PostCell post={answer} disabled={true} customTag={'answer'} />
      <MiddleBlueSeparatorWithPadding />
      <PostHideComponent item={answer} setItem={setAnswer} capitalizedCustomTag={'Answer'} />
      <MiddleBlueSeparatorWithPadding />
      <DetailedInputCell title={'id'} value={answer?.id} disabled={true} />
      <DetailedInputCell title={'created on'} value={answer?.dateString} disabled={true} />
      <DetailedInputCell title={'updated on'} value={answer?.updatedDateString} disabled={true} />
      <RichTextEditorCell initial={answer.raw} disabled={true} />
      <MiddleBlueSeparatorWithPadding />
      <UserCell user={answer.user} />
    </WebScreenContainer>
  );
};

export default Answer;
