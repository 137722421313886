import React, { useEffect, useState, useRef } from 'react';
import { GENERIC_WHITE } from '../../../assets/colors';
import { IS_EXPO_DEV } from '../../Constants';
import ProjectLoader from '../../containers/activity-indicators/ProjectLoader';
import { SolidButtonLabel } from '../../containers/labels';
import WebScreenWithCenterContainer from '../../containers/views/WebScreenWithCenterContainer';
import useAuth from '../../hooks/useAuth';
import Logo from '../../../assets/logo/logo-medium.svg';
import { SimpleAlert } from '../../utils/alert';
import { LoginButton, LoginContainer } from './styled';

const Login = () => {
  const { login, currentUser } = useAuth();

  const [loading, setLoading] = useState(false);

  const isInFocusRef = useRef(false);

  useEffect(() => {
    isInFocusRef.current = true;
    return () => {
      isInFocusRef.current = false;
    };
  }, []);

  const onPress = async () => {
    setLoading(true);
    try {
      await login();
    } catch (error) {
      SimpleAlert(
        'Authentication failed',
        `Something went wrong. Please try again.${IS_EXPO_DEV ? ` [Details: ${error}]` : ''}`,
      );
    } finally {
      if (isInFocusRef.current) {
        setLoading(false);
      }
    }
  };

  return (
    <WebScreenWithCenterContainer backgroundColor={GENERIC_WHITE}>
      {!currentUser && loading && <ProjectLoader />}
      {!currentUser && !loading && (
        <>
          <Logo />
          <LoginContainer>
            <LoginButton onPress={onPress}>
              <SolidButtonLabel text={'CONNECT'} />
            </LoginButton>
          </LoginContainer>
        </>
      )}
    </WebScreenWithCenterContainer>
  );
};

export default Login;
