import React from 'react';
import { Text } from 'react-native';
import styled from 'styled-components/native';
import { BLUE, GENERIC_WHITE, TEXT_DARK_GRAY } from '../../../assets/colors';
import { AppCSS } from '../index';

const Label = ({ text, title, children, ...rest }) => {
  return <Text {...rest}>{title || text || children || ''}</Text>;
};

export const BigTitleLabel = styled(Label)`
  ${AppCSS.bigTitleStyle};
`;

export const BoldTitleLabel = styled(Label)`
  ${AppCSS.boldTitleStyle};
`;

export const MediumTitleLabel = styled(Label)`
  ${AppCSS.mediumTitleStyle};
`;

export const RegularTitleLabel = styled(Label)`
  ${AppCSS.regularTitleStyle};
`;

export const SubtitleLabel = styled(Label)`
  ${AppCSS.subtitleStyle};
`;

export const SmallTitleLabel = styled(Label)`
  ${AppCSS.smallTitleStyle};
`;

export const SmallBoldTextLabel = styled(Label)`
  ${AppCSS.smallBoldTextStyle};
`;

export const DetailsLabel = styled(Label)`
  ${AppCSS.detailStyle};
`;

export const SectionedListItemTitleLabel = styled(Label)`
  ${AppCSS.sectionedListTitleStyle};
`;

export const BoldDetailsLabel = styled(Label)`
  ${AppCSS.boldDetailStyle};
`;

export const TextLabel = styled(Label)`
  ${AppCSS.textStyle};
`;

export const BoldTextLabel = styled(Label)`
  ${AppCSS.boldTextStyle};
`;

export const InputTitleLabel = styled(Label)`
  ${AppCSS.inputTitleStyle};
`;

export const ButtonLabel = styled(Label)`
  ${AppCSS.buttonStyle};
`;

export const SolidButtonLabel = styled(ButtonLabel)`
  color: ${GENERIC_WHITE};
`;

export const RouteButtonLabel = styled(ButtonLabel)`
  margin-right: 6px;
  color: ${BLUE};
`;

export const OptionalRouteButtonLabel = styled(RouteButtonLabel)`
  color: ${TEXT_DARK_GRAY};
`;
