export const parseQueryParams = (url) => {
  const parsed = {};
  (url.split('?')[1] || url)
    .split('&')
    .map((item) => {
      return item.split('=');
    })
    .forEach((item) => {
      parsed[item[0]] = decodeURIComponent(item[1]);
    });
  return parsed;
};

export const createQueryStringFromHash = (queryParams) => {
  const result =
    queryParams &&
    Object.entries(queryParams).reduce((add, [key, value]) => {
      let string = '';

      if (Array.isArray(value)) {
        string =
          value !== undefined && value !== null
            ? `${add}${value.reduce((sec, x) => {
                return x
                  ? `${sec}${add.length > 0 || sec.length > 0 ? '&' : '?'}${encodeURIComponent(
                      key,
                    )}[]=${encodeURIComponent(x)}`
                  : sec;
              }, '')}`
            : add;
        return string;
      }
      string =
        value !== undefined && value !== null
          ? `${add}${add.length > 0 ? '&' : '?'}${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          : add;
      return string;
    }, '');
  return result || '';
};
