import * as ImagePicker from 'expo-image-picker';
import { SimpleAlert } from '../utils/alert';
import * as ImageManipulator from 'expo-image-manipulator';
import { errorJSON } from '../utils/error-json';

export const PICKER_TYPES = {
  square: 0,
  wide: 1,
  tall: 2,
};

export const ERRORS = {
  tooWide: 'tooWide',
  failedPick: 'failedPick',
  invalidSize: 'invalidSize',
  failedResize: 'failedResize',
};

const useImagePicker = (type = PICKER_TYPES.wide) => {
  const getAspectRatio = () => {
    switch (type) {
      case PICKER_TYPES.square:
        return [1, 1];
      case PICKER_TYPES.tall:
        return [3, 4];
      default:
        return [16, 10];
    }
  };

  const desiredWidth = () => {
    switch (type) {
      case PICKER_TYPES.square:
        return 414;
      case PICKER_TYPES.tall:
        return 414;
      default:
        return 414 * 2;
    }
  };

  const checkForPermissions = async () => {
    const { granted, canAskAgain, status } = await ImagePicker.requestCameraRollPermissionsAsync();
    if (granted) {
      return true;
    }
    if (!canAskAgain) {
      SimpleAlert(
        'Photo Library Access',
        'I-FIKR app needs access to your Photo Library to be able to upload an image.',
      );
    }
    return false;
  };

  const pickImage = async () => {
    if (!(await checkForPermissions())) {
      return;
    }
    const options = {
      aspect: getAspectRatio(),
      allowsEditing: type === PICKER_TYPES.square,
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsMultipleSelection: false,
      quality: 1,
      exif: false,
      base64: false,
    };
    const result = await ImagePicker.launchImageLibraryAsync(options);
    if (result?.cancelled) {
      return { cancelled: true };
    }
    const { uri, width, height } = result;
    if (!width || !height) {
      return errorJSON(ERRORS.invalidSize);
    }
    const isTooWide = Math.max(width, height) / Math.min(width, height) >= 2;
    if (isTooWide) {
      return errorJSON(ERRORS.tooWide);
    }
    if (!uri) {
      return errorJSON(ERRORS.failedPick);
    }
    const actions = [{ resize: { width: desiredWidth() } }];
    const saveOptions = { compress: 1, format: ImageManipulator.SaveFormat.JPEG, base64: true };
    const { uri: imageUri, base64 } = await ImageManipulator.manipulateAsync(uri, actions, saveOptions);

    if (!imageUri || !base64) {
      return errorJSON(ERRORS.failedResize);
    }

    const newBase64 = base64.replace('data:image/jpeg;base64,', '');

    return { uri: imageUri, base64: newBase64 };
  };

  return { checkForPermissions, pickImage };
};

export default useImagePicker;
