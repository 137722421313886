const isCompleteUrl = (raw_url) => {
  const url = raw_url?.toLowerCase();
  if (
    url &&
    url?.length > 0 &&
    (url.startsWith('http://') || url.startsWith('https://')) &&
    url !== 'http://' &&
    url !== 'https://'
  ) {
    return url;
  }
  return undefined;
};

export default isCompleteUrl;
