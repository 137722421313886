import { useNavigation } from '@react-navigation/native';
import { SimpleAlert } from '../utils/alert';
import { getRouteFromSlug } from './useDetails';

export const generateSessionId = () => new Date().valueOf().toString();

const useFormCreate = () => {
  const navigation = useNavigation();

  const openCreateForm = async (categoryObject) => {
    const route = getRouteFromSlug(categoryObject?.slug);

    if (route) {
      navigation && navigation.push(route, { session_id: generateSessionId() });
    } else {
      SimpleAlert(
        'Invalid Operation',
        `Something went wrong when trying to create topic in '${categoryObject?.slug}'.`,
      );
    }
  };

  return openCreateForm;
};

export default useFormCreate;
