import styled from 'styled-components/native';
import { SolidButton } from '../../containers/buttons';

export const LoginContainer = styled.View`
  margin-top: 16px;
  width: 260px;
  align-self: center;
`;

export const LoginButton = styled(SolidButton)`
  margin-top: 8px;
`;
