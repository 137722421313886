import styled from 'styled-components/native';

const FillScreenContainer = styled.View`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  ${(props) => (props.backgroundColor ? `background-color: ${props.backgroundColor};` : '')}
`;

export default FillScreenContainer;
