import React, { useState } from 'react';
import { RED } from '../../../../assets/colors';
import { hidePost, unhidePost, ERRORS } from '../../../networking/calls/posts';
import { ErrorAlert, SuccessAlert } from '../../../utils/alert';
import { getErrorCode } from '../../../utils/error-json';
import DetailedInputCell from './form-cells/input/detailed-input-cell';

const PostCloseComponent = ({ item, setItem, capitalizedCustomTag }) => {
  const [loading, setLoading] = useState(false);

  const capitalizedTag = capitalizedCustomTag || 'Post';
  const normalTag = capitalizedCustomTag?.toLowerCase() || 'post';
  const uppercasedTag = capitalizedCustomTag?.toUpperCase() || 'POST';

  const [reason, setReason] = useState('');
  const [resolution, setResolution] = useState('');
  const hidden = item?.hidden;

  const hide = async () => {
    if (hidden) {
      return undefined;
    }
    setLoading(true);
    try {
      const newPost = await hidePost(item?.id, reason);
      const errorCode = getErrorCode(newPost);
      if (errorCode || !newPost) {
        if (!newPost) {
          ErrorAlert(`Failed to hide ${normalTag}. Details: unknown`);
        } else {
          if (ERRORS.hiddenButNoPost === errorCode) {
            setReason('');
            SuccessAlert(`${capitalizedTag} was hidden, but interface could not be updated.`);
          } else {
            ErrorAlert(newPost?.error?.message || errorCode);
          }
        }
      } else {
        setReason('');
        setItem(newPost);
        SuccessAlert(`${capitalizedTag} was hidden.`);
      }
    } catch (error) {
      ErrorAlert(`Failed to hide ${normalTag}. Details: ${error}`);
    }
    setLoading(false);
  };

  const unhide = async () => {
    if (hidden) {
      setLoading(true);
      try {
        const newPost = await unhidePost(item?.id, resolution);
        const errorCode = getErrorCode(newPost);
        if (errorCode || !newPost) {
          if (!newPost) {
            ErrorAlert(`Failed to make ${normalTag} visible. Details: unknown`);
          } else {
            if (ERRORS.unhiddenButNoPost === errorCode) {
              setResolution('');
              SuccessAlert(`${capitalizedTag} is visible, but interface could not be updated.`);
            } else {
              ErrorAlert(newPost?.error?.message || errorCode);
            }
          }
        } else {
          setResolution('');
          setItem(newPost);
          SuccessAlert(`${capitalizedTag} is now visible.`);
        }
      } catch (error) {
        ErrorAlert(`Failed to make ${normalTag} visible. Details: ${error}`);
      }
      setLoading(false);
    }
  };

  return (
    <>
      {hidden && (
        <DetailedInputCell
          title={'MAKE VISIBLE'}
          value={resolution}
          setValue={setResolution}
          loading={loading}
          placeholder={`Enter a resolution for making this ${normalTag} visible`}
          buttonTitle={`MAKE ${uppercasedTag} VISIBLE`}
          buttonPress={unhide}
        />
      )}
      {!hidden && (
        <DetailedInputCell
          title={'HIDE'}
          value={reason}
          setValue={setReason}
          loading={loading}
          placeholder={`Enter a reason for hiding this ${normalTag}`}
          buttonTitle={`HIDE ${uppercasedTag}`}
          buttonColor={RED}
          buttonPress={hide}
        />
      )}
    </>
  );
};

export default PostCloseComponent;
