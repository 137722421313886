import React, { useState } from 'react';
import { SolidButtonLabel } from '../../../../../../containers/labels';
import { SimpleAlert } from '../../../../../../utils/alert';
import { ATTACHMENT_BUTTON_WIDTH } from '../../../../../defines';
import { CREATE_VIMEO_VIDEO_URL } from '../../../../../defines';
import DetailedInputRaw from '../../input/detailed-input-raw';
import { CellButton, CellRow } from '../../styled';

const AttachmentsAddVimeo = ({ onSuccessfulProcessing, disabled, shouldStartProcess }) => {
  const [caption, setCaption] = useState('');
  const [id, setId] = useState('');

  const validId = () => {
    if (!(id?.length > 0)) {
      return false;
    }
    return !(id?.startsWith('http') || id?.startsWith('www') || id?.startsWith('vimeo') || id?.startsWith('player'));
  };

  const onPress = async () => {
    if (!shouldStartProcess()) {
      return;
    }
    if (!validId()) {
      SimpleAlert(
        'Vimeo video id missing',
        'Please enter only the Vimeo video id.\n\nExamples:' +
          '\nhttps://vimeo.com/67940402 => 67940402' +
          '\nhttps://player.vimeo.com/video/67940402?background=1&muted=0&controls=0 => 67940402',
      );
      return;
    }
    onSuccessfulProcessing(caption, { caption, id, url: CREATE_VIMEO_VIDEO_URL(id), type: 'vimeo' });
    setCaption('');
    setId('');
  };

  return (
    <CellRow>
      <DetailedInputRaw value={caption} setValue={setCaption} placeholder={'Enter vimeo caption (optional)'} />
      <DetailedInputRaw addMarginLeft={true} value={id} setValue={setId} placeholder={'Enter vimeo video id'} />
      <CellButton disabled={disabled} buttonWidth={ATTACHMENT_BUTTON_WIDTH} onPress={() => onPress()}>
        <SolidButtonLabel title={'ADD VIMEO VIDEO'} />
      </CellButton>
    </CellRow>
  );
};

export default AttachmentsAddVimeo;
