import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import LogoSmall from '../../../assets/logo/logo-small.svg';
import { DrawerContent } from '../../containers/navigation/Drawer';
import { navBarOptions } from '../../containers/navigation/NavigationBar';
import useDimensions from '../../hooks/useDimensions';
import useReduxState from '../../store/genericSet';
import { DrawerButton } from '../styled';
import IFIKRContent from './ifikr-content';
import Article from './ifikr-content/details/article';
import Book from './ifikr-content/details/book';
import Glossary from './ifikr-content/details/glossary';
import EventPage from './ifikr-content/details/event';
import Excerpt from './ifikr-content/details/excerpt';
import Fatwa from './ifikr-content/details/fatwa';
import Fiqh from './ifikr-content/details/fiqh';
import IfikrDigest from './ifikr-content/details/i-fikr-digest';
import News from './ifikr-content/details/news';
import ResearchPaper from './ifikr-content/details/research-paper';
import UserContent from './user-content';
import Answer from './user-content/details/answer';
import Question from './user-content/details/question';
import UserPost from './user-content/details/user-post';
import User from './users/user-details';
import Users from './users/users';
import Benefactors from './benefactors/benefactors';
import Benefactor from './benefactors/benefactor-details';

const Drawer = createDrawerNavigator();

const noAnimation = ({ current }) => ({ cardStyle: { opacity: 1 } });

const generateScreensForStack = (Stack) => {
  return (
    <>
      <Stack.Screen name={'User'} component={User} options={{ ...navBarOptions }} />
      <Stack.Screen name={'User Post'} component={UserPost} options={{ ...navBarOptions }} />
      <Stack.Screen name={'Benefactor'} component={Benefactor} options={{ ...navBarOptions }} />
      <Stack.Screen name={'Question'} component={Question} options={{ ...navBarOptions }} />
      <Stack.Screen name={'Answer'} component={Answer} options={{ ...navBarOptions }} />
      <Stack.Screen name={'News Post'} component={News} options={{ ...navBarOptions }} />
      <Stack.Screen name={'Fatwa'} component={Fatwa} options={{ ...navBarOptions }} />
      <Stack.Screen name={'Glossary'} component={Glossary} options={{ ...navBarOptions }} />
      <Stack.Screen name={'Excerpt'} component={Excerpt} options={{ ...navBarOptions }} />
      <Stack.Screen name={'Fiqh'} component={Fiqh} options={{ ...navBarOptions }} />
      <Stack.Screen name={'Book'} component={Book} options={{ ...navBarOptions }} />
      <Stack.Screen name={'Research Paper'} component={ResearchPaper} options={{ ...navBarOptions }} />
      <Stack.Screen name={'I-FIKR Digest'} component={IfikrDigest} options={{ ...navBarOptions }} />
      <Stack.Screen name={'Article'} component={Article} options={{ ...navBarOptions }} />
      <Stack.Screen name={'Event'} component={EventPage} options={{ ...navBarOptions }} />
    </>
  );
};

const AppComponent = ({ logout }) => {
  const [currentUser] = useReduxState('auth', 'currentUser');
  const dimensions = useDimensions();

  const isLargeScreen = dimensions.width >= 1024;

  const getFirstScreenOptions = ({ navigation }) => {
    return {
      headerLeft: () =>
        isLargeScreen ? undefined : (
          <DrawerButton onPress={() => navigation.toggleDrawer()}>
            <LogoSmall />
          </DrawerButton>
        ),
      cardStyleInterpolator: noAnimation,
    };
  };

  return (
    <Drawer.Navigator
      drawerType={isLargeScreen ? 'permanent' : undefined}
      drawerContent={(props) => DrawerContent({ ...props, logout, currentUser })}
      initialRouteName={'I-FIKR Content'}>
      <Drawer.Screen name={'I-FIKR Content'}>
        {(navigation) => <IFIKRContentScreenContent {...{ navigation, getFirstScreenOptions }} />}
      </Drawer.Screen>
      <Drawer.Screen name={'User Content'}>
        {(navigation) => <UserContentScreenContent {...{ navigation, getFirstScreenOptions }} />}
      </Drawer.Screen>
      <Drawer.Screen name={'Users'}>
        {(navigation) => <UsersScreenContent {...{ navigation, getFirstScreenOptions }} />}
      </Drawer.Screen>
      <Drawer.Screen name={'Benefactors'}>
        {(navigation) => <BenefactorsScreenContent {...{ navigation, getFirstScreenOptions }} />}
      </Drawer.Screen>
    </Drawer.Navigator>
  );
};

const IFIKRContentStack = createStackNavigator();
const IFIKRContentExtras = generateScreensForStack(IFIKRContentStack);
const IFIKRContentScreenContent = ({ navigation, getFirstScreenOptions }) => {
  const firstScreenOptions = getFirstScreenOptions(navigation);
  return (
    <IFIKRContentStack.Navigator screenOptions={navBarOptions}>
      <IFIKRContentStack.Screen
        name={'IFIKRContent'}
        component={IFIKRContent}
        options={{ ...firstScreenOptions, title: 'I-FIKR Content' }}
      />
      {IFIKRContentExtras}
    </IFIKRContentStack.Navigator>
  );
};

const UserContentStack = createStackNavigator();
const UserContentExtras = generateScreensForStack(UserContentStack);
const UserContentScreenContent = ({ navigation, getFirstScreenOptions }) => {
  const firstScreenOptions = getFirstScreenOptions(navigation);
  return (
    <UserContentStack.Navigator screenOptions={navBarOptions}>
      <UserContentStack.Screen
        name={'UserContent'}
        component={UserContent}
        options={{ ...firstScreenOptions, title: 'User Content' }}
      />
      {UserContentExtras}
    </UserContentStack.Navigator>
  );
};

const UsersStack = createStackNavigator();
const UsersExtras = generateScreensForStack(UsersStack);
const UsersScreenContent = ({ navigation, getFirstScreenOptions }) => {
  const firstScreenOptions = getFirstScreenOptions(navigation);
  return (
    <UsersStack.Navigator screenOptions={navBarOptions}>
      <UsersStack.Screen name={'Users'} component={Users} options={firstScreenOptions} />
      {UsersExtras}
    </UsersStack.Navigator>
  );
};

const BenefactorsStack = createStackNavigator();
const BenefactorsExtras = generateScreensForStack(BenefactorsStack);
const BenefactorsScreenContent = ({ navigation, getFirstScreenOptions }) => {
  const firstScreenOptions = getFirstScreenOptions(navigation);
  return (
    <BenefactorsStack.Navigator screenOptions={navBarOptions}>
      <BenefactorsStack.Screen name={'Benefactors'} component={Benefactors} options={firstScreenOptions} />
      {BenefactorsExtras}
    </BenefactorsStack.Navigator>
  );
};

export default AppComponent;
