import { StyleSheet } from 'react-native';
import {
  BLUE,
  GENERIC_BLACK,
  GENERIC_WHITE,
  GREEN,
  SHADOW_GRAY,
  TAB_BAR_BACKGROUND,
  TEXT_BLACK,
  TEXT_DARK_GRAY,
  TEXT_LIGHT_GRAY,
} from '../../assets/colors';
import Fonts from '../../assets/fonts';

export const borderRadiusValue = 6;

export const genericImageRatio = 16 / 10;
export const resourceImageRatio = 3 / 4;

export const THUMBNAIL_RATIO = 1;
export const THUMBNAIL_WIDTH = 48;

export const AppCSS = {
  genericPaddingForNavBarItems: `
    padding-horizontal: 16px;
    height: 44px;
  `,
  borderRadius: `border-radius: ${borderRadiusValue}px;`,
  borderRadiusOnlyTop: `
    border-top-right-radius: ${borderRadiusValue}px;
    border-top-left-radius: ${borderRadiusValue}px;
  `,
  searchTextStyle: `
    line-height: 22px;
    font-size: 16px;
    font-family: ${Fonts.regular};
    color: ${TEXT_BLACK};
  `,
  inputStyle: `
    line-height: 22px;
    font-size: 16px;
    font-family: ${Fonts.regular};
    color: ${TEXT_BLACK};
  `,
  placeholderTextStyle: `
    line-height: 22px;
    font-size: 16px;
    font-family: ${Fonts.regular};
    color: ${TEXT_LIGHT_GRAY};
  `,
  bigTitleStyle: `
    line-height: 22px;
    font-size: 20px;
    font-family: ${Fonts.bold};
    color: ${TEXT_BLACK};
  `,
  boldTitleStyle: `
    line-height: 18px;
    font-size: 16px;
    font-family: ${Fonts.bold};
    color: ${TEXT_BLACK};
  `,
  mediumTitleStyle: `
    line-height: 18px;
    font-size: 16px;
    font-family: ${Fonts.medium};
    color: ${TEXT_BLACK};
  `,
  regularTitleStyle: `
    line-height: 18px;
    font-size: 16px;
    font-family: ${Fonts.regular};
    color: ${TEXT_BLACK};
  `,
  subtitleStyle: `
    line-height: 18px;
    font-size: 13px;
    font-family: ${Fonts.bold};
    color: ${TEXT_LIGHT_GRAY};
  `,
  smallTitleStyle: `
    line-height: 18px;
    font-size: 13px;
    font-family: ${Fonts.bold};
    color: ${TEXT_DARK_GRAY};
  `,
  buttonStyle: `
    line-height: 22px;
    font-size: 13px;
    font-family: ${Fonts.bold};
  `,
  textStyle: `
    line-height: 18px;
    font-size: 13px;
    font-family: ${Fonts.regular};
    color: ${TEXT_BLACK};
  `,
  boldTextStyle: `
    line-height: 18px;
    font-size: 13px;
    font-family: ${Fonts.medium};
    color: ${TEXT_BLACK};
  `,
  italicTextStyle: `
    line-height: 18px;
    font-size: 13px;
    font-family: ${Fonts.italic};
    color: ${TEXT_BLACK};
  `,
  smallBoldTextStyle: `
    font-size: 13px;
    font-family: ${Fonts.bold};
    color: ${TEXT_DARK_GRAY};
  `,
  linkStyle: `
    line-height: 18px;
    font-size: 13px;
    font-family: ${Fonts.medium};
    color: ${BLUE};
    text-decoration-line: underline;
  `,
  boldDetailStyle: `
    line-height: 13px;
    font-size: 11px;
    font-family: ${Fonts.bold};
    color: ${GREEN};
  `,
  detailStyle: `
    line-height: 13px;
    font-size: 11px;
    font-family: ${Fonts.regular};
    color: ${TEXT_LIGHT_GRAY};
  `,
  sectionedListTitleStyle: `
    line-height: 18px;
    font-size: 11px;
    font-family: ${Fonts.bold};
    color: ${TEXT_LIGHT_GRAY};
  `,
  inputTitleStyle: `
    line-height: 18px;
    font-size: 11px;
    font-family: ${Fonts.bold};
    color: ${TEXT_LIGHT_GRAY};
  `,
  bottomShadow: ` 
    box-shadow: 0px 2px 8px ${SHADOW_GRAY};
    elevation: 3;
  `,
  smallBottomShadow: ` 
    box-shadow: 0px 2px 4px ${SHADOW_GRAY};
    elevation: 1;
  `,
  navBarShadow: ` 
    box-shadow: 0px 2px 20px ${SHADOW_GRAY};
    elevation: 2;
  `,
};

const AppStyles = StyleSheet.create({
  noNavBarShadowStyle: {
    backgroundColor: GENERIC_WHITE,
    elevation: 0,
    shadowOpacity: 0,
    shadowRadius: 0,
    shadowOffset: { width: 0, height: 0 },
    shadowColor: GENERIC_WHITE,
  },
  navBarStyle: {
    backgroundColor: GENERIC_WHITE,
    elevation: 2,
    shadowOpacity: 1,
    shadowRadius: 20,
    shadowOffset: { width: 0, height: 2 },
    shadowColor: SHADOW_GRAY,
  },
  navBarTitleStyle: {
    fontSize: 18,
    fontFamily: Fonts.bold,
    color: GENERIC_BLACK,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  navBarButtonStyle: {
    fontSize: 18,
    fontFamily: Fonts.bold,
    color: GENERIC_BLACK,
  },
  tabBarStyle: {
    backgroundColor: TAB_BAR_BACKGROUND,
  },
  tabBarLabelStyle: {
    fontSize: 9,
    fontFamily: Fonts.bold,
  },
  borderRadius: { borderRadius: borderRadiusValue },
  borderRadiusTopOnly: { borderTopLeftRadius: borderRadiusValue, borderTopRightRadius: borderRadiusValue },
});

export default AppStyles;
