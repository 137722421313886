import { BLUE, GRAY, SEPARATOR_WHITE, TRANSPARENT } from '../../../assets/colors';
import styled from 'styled-components/native';

export const WhiteSeparator = styled.View`
  background-color: ${SEPARATOR_WHITE};
  height: 1.5px;
`;

export const WhiteSeparatorWithPadding = styled(WhiteSeparator)`
  margin-vertical: 4px;
`;

export const BlueSeparator = styled.View`
  background-color: ${BLUE};
  height: 2px;
`;

export const MiddleBlueSeparatorWithPadding = styled.View`
  background-color: ${BLUE};
  height: 2px;
  width: 48px;
  align-self: center;
  margin-top: 48px;
  margin-bottom: 48px;
`;

export const InvisibleSeparator = styled.View`
  background-color: ${TRANSPARENT};
  height: 2px;
`;

export const GraySeparator = styled.View`
  background-color: ${GRAY};
  height: 2px;
`;
