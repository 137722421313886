import React from 'react';
import { ScrollView } from 'react-native';
import { ToggleButton } from '../../../../containers/buttons';
import { SearchFiltersContainer, SearchFiltersLabel, styles } from './styled';

const SearchFilters = ({ toggleFilter, results }) => {
  return (
    <SearchFiltersContainer>
      <SearchFiltersLabel title={'Filter Results'} />
      <ScrollView horizontal={true} style={styles.filtersStyle} contentContainerStyle={styles.filtersWrapper}>
        {results.map((t) => (
          <ToggleButton
            key={t.title}
            title={t.title.toUpperCase()}
            selected={!!t.show}
            onPress={() => toggleFilter(t.title)}
          />
        ))}
      </ScrollView>
    </SearchFiltersContainer>
  );
};

export default SearchFilters;
