import React, { useEffect, useState } from 'react';
import { ProjectNavBlueButton, ProjectNavTextButton } from '../../../containers/navigation/NavigationBarItem';
import WebScreenContainer from '../../../containers/views/WebScreenContainer';
import useDetails from '../../../hooks/useDetails';
import { userContentArray } from '../../../networking/calls';
import DisabledListComponent from '../shared/disabled-list';
import SearchComponent from '../shared/search';
import { ITEM_TYPE } from '../shared/search/search-list';

const UserContent = ({ navigation }) => {
  const openDetails = useDetails();

  const [showClosedOnly, setShowClosedOnly] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!showClosedOnly) {
      navigation.setOptions({
        headerRight: () => <ProjectNavBlueButton title={'CLOSED TOPICS'} onPress={() => setShowClosedOnly(true)} />,
      });
    } else {
      navigation.setOptions({
        headerRight: () => <ProjectNavBlueButton title={'SHOW SEARCH'} onPress={() => setShowClosedOnly(false)} />,
      });
    }
  }, [showClosedOnly]);

  return (
    <WebScreenContainer>
      {!showClosedOnly && (
        <SearchComponent
          itemType={ITEM_TYPE.topic}
          types={userContentArray}
          title={'SEARCH USER CREATED CONTENT'}
          placeholder={
            'Enter an id, part of the title or a complete username (@username) to search for USER added content'
          }
        />
      )}
      {showClosedOnly && (
        <>
          <DisabledListComponent itemType={ITEM_TYPE.topic} types={userContentArray} />
        </>
      )}
    </WebScreenContainer>
  );
};

export default UserContent;
