import { Alert, Platform } from 'react-native';

export const ErrorAlert = (desc, onPress = () => {}) => {
  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    Alert.alert('Error', desc, [{ text: 'OK', onPress: () => onPress() }], { cancelable: false });
    return;
  }
  alert && alert(`Error\n${desc}`);
  onPress && onPress();
};

export const SuccessAlert = (desc, onPress = () => {}) => {
  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    Alert.alert('Success', desc, [{ text: 'OK', onPress: () => onPress() }], { cancelable: false });
    return;
  }
  alert && alert(`Success\n${desc}`);
  onPress && onPress();
};

export const SimpleAlert = (title, desc, onPress = () => {}) => {
  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    Alert.alert(title, desc, [{ text: 'OK', onPress: () => onPress() }], { cancelable: false });
    return;
  }
  alert && alert(`${title}\n${desc}`);
  onPress && onPress();
};

export const ConfirmationAlert = (title, desc, onPositivePress = () => {}, onNegativePress = () => {}) => {
  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    Alert.alert(
      title,
      desc,
      [
        { text: 'Yes', onPress: () => onPositivePress() },
        { text: 'No', onPress: () => onNegativePress() },
      ],
      { cancelable: false },
    );
  }
  const res = window?.confirm(`${title}\n${desc}`);
  if (res) {
    onPositivePress && onPositivePress();
    return;
  }
  onNegativePress && onNegativePress();
};
