import keypair from 'keypair';
import forge from 'node-forge';

const ERRORS = {
  cannotGenerateKeyPair: (details) => `Cannot generate certificate info. Details: ${details}`,
  cannotDecrypt: (details) => `Cannot decrypt payload. Details: ${details}`,
};

export const createKeyPair = async () => {
  console.log('Generating keyPair');
  try {
    return await keypair({ bits: 1024 });
  } catch (error) {
    throw ERRORS.cannotGenerateKeyPair(error);
  }
};

export const decryptBase64 = async (base64Payload, privateKey) => {
  try {
    const key = forge.pki.privateKeyFromPem(privateKey);
    const raw = forge.util.decode64(base64Payload);
    return key.decrypt(raw);
  } catch (error) {
    throw ERRORS.cannotDecrypt(error);
  }
};
