import styled from 'styled-components/native';
import { GENERIC_WHITE } from '../../assets/colors';
import { DetailedInputLabel } from './app/shared/form-cells/input/styled';
import { BlueSeparator } from '../containers/views/Separators';

export const DrawerButton = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  margin-horizontal: 20px;
`;

export const DrawerSeparator = styled(BlueSeparator)`
  margin-top: 4px;
  width: 24px;
  align-self: center;
`;

export const DrawerVersionLabel = styled(DetailedInputLabel)`
  text-align: center;
  align-self: center;
`;

export const DrawerHeader = styled.View`
  align-items: center;
  margin-top: 50px;
  margin-bottom: 32px;
`;

export const DrawerFooter = styled.View`
  background-color: ${GENERIC_WHITE};
  padding-top: 32px;
  padding-bottom: 50px;
  align-items: center;
`;
