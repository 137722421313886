import React, { useEffect, useState } from 'react';
import { LargeActivityIndicator } from '../../../../components/activity-indicators/ActivityIndicatorView';
import { RegularTitleLabel } from '../../../../containers/labels';
import { ProjectNavGreenText, ProjectNavRedText } from '../../../../containers/navigation/NavigationBarItem';
import { MiddleBlueSeparatorWithPadding } from '../../../../containers/views/Separators';
import WebScreenContainer from '../../../../containers/views/WebScreenContainer';
import { getQuestionAnswers } from '../../../../networking/calls/community';
import PostCell from '../../shared/cells/post-cell';
import TopicCell from '../../shared/cells/topic-cell';
import UserCell from '../../shared/cells/user-cell';
import DetailedInputCell from '../../shared/form-cells/input/detailed-input-cell';
import RichTextEditorCell from '../../shared/form-cells/rich-text-editor-cell';
import TopicCloseComponent from '../../shared/TopicCloseComponent';
import { NoResultsContainer } from '../../styled';

const Question = ({ navigation, route }) => {
  const item = route?.params?.item;

  const [question, setQuestion] = useState(item);

  const [answers, setAnswers] = useState([]);
  const [loadingAnswers, setLoadingAnswers] = useState([]);

  const loadAnswers = async () => {
    setLoadingAnswers(true);
    const a = (await getQuestionAnswers(question?.topic.id)) || [];
    setAnswers(a);
    setLoadingAnswers(false);
  };

  useEffect(() => {
    loadAnswers();
  }, []);

  useEffect(() => {
    if (question?.topic?.closed) {
      navigation.setOptions({
        headerRight: () => <ProjectNavRedText title={'QUESTION CLOSED'} />,
      });
    } else {
      navigation.setOptions({
        headerRight: () => <ProjectNavGreenText title={'QUESTION OPEN'} />,
      });
    }
  }, [question]);

  return (
    <WebScreenContainer>
      <TopicCell item={question} disabled={true} ignoreTitle={true} />
      <MiddleBlueSeparatorWithPadding />
      <TopicCloseComponent item={question} setItem={setQuestion} capitalizedCustomTag={'Question'} />
      <MiddleBlueSeparatorWithPadding />
      <>
        <DetailedInputCell title={'id'} value={question?.id} disabled={true} />
        <DetailedInputCell title={'created on'} value={question?.dateString} disabled={true} />
        <DetailedInputCell title={'updated on'} value={question?.updatedDateString} disabled={true} />
        <RichTextEditorCell initial={question?.firstPost?.raw} disabled={true} />
      </>
      <MiddleBlueSeparatorWithPadding />
      {!loadingAnswers && (
        <>
          {answers?.map((a) => (
            <PostCell key={a.id} post={a} customTag={'answer'} />
          ))}
          {answers?.length === 0 && (
            <NoResultsContainer>
              <RegularTitleLabel text={'0 answers found'} />
            </NoResultsContainer>
          )}
          <MiddleBlueSeparatorWithPadding />
        </>
      )}
      {loadingAnswers && (
        <>
          <LargeActivityIndicator /> <MiddleBlueSeparatorWithPadding />
        </>
      )}
      <UserCell user={question?.firstPost?.user} />
    </WebScreenContainer>
  );
};

export default Question;
