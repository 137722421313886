import env from '../../../env';
import { setAction } from '../../store/genericSet';
import { SimpleAlert } from '../../utils/alert';
import { errorJSON } from '../../utils/error-json';
import { createQueryStringFromHash } from '../utils/query-params';
import { baseUrl, getUserApiKey, getClientId, setUserApiKey, setClientId, setUsername } from '../index';
import store from '../../store';

const ERRORS = {
  invalidHTTPMethod: 'invalidHTTPMethod',
  fetchError: 'fetchError',
  userIsNotLoggedIn: 'userIsNotLoggedIn',
  invalidJSON: 'invalidJSON',
  invalidResponse: 'invalidResponse',
};

export const categoryTypes = env.models.categoryTypes;
export const ifikrContentArray = env.models.ifikrContent;
export const userContentArray = env.models.userContent;

export const forceLogout = async (store) => {
  try {
    store.dispatch(setAction('currentUser', undefined, 'auth'));
    await setUserApiKey(undefined);
    await setClientId(undefined);
    await setUsername(undefined);

    SimpleAlert('Logout', 'Something went wrong. Your account was logged out.');
  } catch {
    /* Do nothing */
  }
};

const apiCall = async ({ url: suffix, method = 'GET', customHeaders = {}, queryParams, bodyParams }) => {
  try {
    if (method !== 'GET' && method !== 'POST' && method !== 'PUT' && method !== 'DELETE') {
      console.log('API-CALL-ERROR-FWR', suffix, ERRORS.invalidHTTPMethod);
      return errorJSON(ERRORS.invalidHTTPMethod);
    }

    const headers = {
      ...(customHeaders ? customHeaders : {}),
      'Content-Type': 'application/json',
    };

    const userApiKey = await getUserApiKey();
    if (userApiKey) {
      headers['User-Api-Key'] = userApiKey;
      headers['User-Api-Client-Id'] = (await getClientId()) || '';
    }

    const body = bodyParams && JSON.stringify(bodyParams);
    const queryString = (queryParams && createQueryStringFromHash(queryParams)) || '';
    const url = baseUrl + suffix + queryString;
    const options = body ? { headers, method, body } : { headers, method };

    const response = await fetch(url, options);
    if (!response) {
      console.log('API-CALL-ERROR-FWR', suffix, ERRORS.invalidResponse);
      return errorJSON(ERRORS.invalidResponse);
    }

    const json = await response.json();
    if (!json) {
      console.log('API-CALL-ERROR-FWR', suffix, ERRORS.invalidJSON);
      return errorJSON(ERRORS.invalidJSON);
    }

    if (json?.error?.code !== undefined) {
      console.log('API-CALL-ERROR-FWR', suffix, json?.error);
      return json;
    }

    // Discourse access control error - force log out if seen
    if (json?.error_type === 'invalid_access') {
      forceLogout(store);
      return undefined;
    }

    // Discourse custom errors - not caught
    if (json?.errors) {
      return undefined;
    }

    console.log('API-CALL', suffix);
    return json;
  } catch (error) {
    console.log('API-CALL-ERROR', suffix, error);
    return errorJSON('connection', error?.message);
  }
};

export default apiCall;
