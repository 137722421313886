import cacheImages from '../utils/CacheImages';

export default async function preloadImages() {
  try {
    await Promise.all(cacheImages([]));
  } catch {
    console.log('Asset prefetch ended early. Promise.all[] aborted.');
  }
  try {
    Promise.all(cacheImages([]));
  } catch {
    console.log('Big asset prefetch ended early. Promise.all[] aborted.');
  }
}
