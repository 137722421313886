import Picker from '@react-native-community/picker/js/Picker';
import React, { useEffect, useState } from 'react';
import { dateDisplayFromDates, daysInMonth, timeDistanceToDate } from '../../../utils/date-helpers';
import { get } from 'lodash';
import { ProjectPicker } from '../../../ui/app/shared/form-cells/styled';
import { BoldTextLabel, TextLabel } from '../../labels';
import { DateTimePickerContainer } from './styled';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const valueForMonthNumber = (v) => {
  return get(monthNames, v) || 'Invalid';
};

const serverStartDate = new Date().getFullYear();

const DateTimePicker = ({ initial = new Date(), setValue, refreshDate }) => {
  const [year, setYear] = useState(initial.getFullYear());
  const [month, setMonth] = useState(initial.getMonth());
  const [day, setDay] = useState(initial.getDate());
  const [hour, setHour] = useState(initial.getHours());
  const [minute, setMinute] = useState(Math.round(initial.getMinutes() / 5) * 5);

  const [days, setDays] = useState(daysInMonth(new Date(year, month, day, hour, minute)));

  const [outputDate, setOutputDate] = useState(dateDisplayFromDates(initial.toISOString()));
  const [outputDistance, setOutputDistance] = useState(timeDistanceToDate(initial.toISOString()));

  useEffect(() => {
    if (refreshDate && refreshDate instanceof Date) {
      setYear(refreshDate.getFullYear());
      setMonth(refreshDate.getMonth());
      setDay(refreshDate.getDate());
      setHour(refreshDate.getHours());
      setMinute(Math.round(refreshDate.getMinutes() / 5) * 5);

      setOutputDate(dateDisplayFromDates(refreshDate.toISOString()));
      setOutputDistance(timeDistanceToDate(refreshDate.toISOString()));
    }
  }, [refreshDate]);

  useEffect(() => {
    if (hour !== undefined && minute !== undefined && day !== undefined && month !== undefined && year !== undefined) {
      const newMonth = new Date(year, month);
      const newDays = daysInMonth(newMonth);
      const newDate = new Date(year, month, day, hour, minute);
      if (day > newDays) {
        setDay(newDays);
      }
      setDays(newDays);
      setValue && setValue(newDate);
      setOutputDistance(timeDistanceToDate(newDate.toISOString()));
      setOutputDate(dateDisplayFromDates(newDate.toISOString()));
    }
  }, [hour, minute, day, month, year]);

  return (
    <>
      {day !== undefined && month !== undefined && year !== undefined && (
        <>
          <DateTimePickerContainer>
            <TextLabel title={'Time:     '} />
            <ProjectPicker padding={'true'} onValueChange={(v) => setHour(v)} selectedValue={hour}>
              {Array.from(Array(24), (_, i) => i).map((v) => {
                return <Picker.Item key={`${v}`} label={`${v < 10 ? '0' : ''}${v}`} value={v} />;
              })}
            </ProjectPicker>
            <ProjectPicker
              padding={'true'}
              onValueChange={(v) => {
                setMinute(v);
              }}
              selectedValue={minute}>
              {Array.from(Array(12), (_, i) => i * 5).map((v) => {
                return <Picker.Item key={`${v}`} label={`${v < 10 ? '0' : ''}${v}`} value={v} />;
              })}
            </ProjectPicker>
            <TextLabel title={'Date:     '} />
            <ProjectPicker padding={'true'} onValueChange={(v) => setDay(v)} selectedValue={day}>
              {Array.from(Array(days), (_, i) => i + 1).map((v) => {
                return <Picker.Item key={`${v}`} label={v} value={v} />;
              })}
            </ProjectPicker>
            <ProjectPicker padding={'true'} onValueChange={(v) => setMonth(v)} selectedValue={month}>
              {Array.from(Array(12), (_, i) => i).map((v) => {
                return <Picker.Item key={`${v}`} label={valueForMonthNumber(v)} value={v} />;
              })}
            </ProjectPicker>
            <ProjectPicker onValueChange={(v) => setYear(v)} selectedValue={year}>
              {Array.from(Array(20), (_, i) => i + serverStartDate - 10).map((v) => {
                return <Picker.Item key={`${v}`} label={v} value={v} />;
              })}
            </ProjectPicker>
            {outputDate && (
              <>
                <TextLabel title={'     Display:     '} />
                <BoldTextLabel title={outputDate} />
              </>
            )}
            {outputDistance && (
              <>
                <TextLabel title={'     |     '} />
                <BoldTextLabel title={outputDistance} />
              </>
            )}
          </DateTimePickerContainer>
        </>
      )}
    </>
  );
};

export default DateTimePicker;
