import React, { useState } from 'react';
import { SolidButtonLabel } from '../../../../../../containers/labels';
import { SimpleAlert } from '../../../../../../utils/alert';
import { ATTACHMENT_BUTTON_WIDTH } from '../../../../../defines';
import DetailedInputRaw from '../../input/detailed-input-raw';
import { CellButton, CellRow } from '../../styled';

const AttachmentsAddTopic = ({ validateTopicId, disabled, onSuccessfulProcessing, shouldStartProcess }) => {
  const [caption, setCaption] = useState('');
  const [id, setId] = useState('');

  const onPress = async () => {
    if (!shouldStartProcess()) {
      return;
    }
    if (validateTopicId(id)) {
      onSuccessfulProcessing(caption, { caption, id, type: 'topic' });
      setCaption('');
      setId('');
      return;
    }
    SimpleAlert(
      'Topic id is invalid or missing',
      'Please enter a valid I-FIKR topic id. Already used I-FIKR ids are considered invalid.',
    );
  };

  return (
    <CellRow>
      <DetailedInputRaw value={caption} setValue={setCaption} placeholder={'Enter topic caption (optional)'} />
      <DetailedInputRaw addMarginLeft={true} value={id} setValue={setId} placeholder={'Enter topic id'} />
      <CellButton disabled={disabled} buttonWidth={ATTACHMENT_BUTTON_WIDTH} onPress={() => onPress()}>
        <SolidButtonLabel title={'ADD I-FIKR TOPIC'} />
      </CellButton>
    </CellRow>
  );
};

export default AttachmentsAddTopic;
