import * as DocumentPicker from 'expo-document-picker';
import { errorJSON } from '../utils/error-json';

const ERRORS = {
  failedToLoadFile: 'failedToLoadFile',
  failedToPrepareFile: 'failedToPrepareFile',
  failedToCompressFile: 'failedToCompressFile',
};

const useDocumentPicker = () => {
  const pickPdf = async () => {
    const file = await DocumentPicker.getDocumentAsync({
      base64: true,
      type: 'application/pdf',
      copyToCacheDirectory: false,
      multiple: false,
    });
    if (file) {
      if (file?.type === 'cancel') {
        return undefined;
      }

      if (file?.uri) {
        const base64 = file?.uri?.replace('data:application/pdf;base64,', '');
        if (base64) {
          return base64;
        }
        return errorJSON(ERRORS.failedToCompressFile, 'Failed to compress PDF file');
      }
      return errorJSON(ERRORS.failedToPrepareFile, 'Failed to prepare PDF file');
    }
    return errorJSON(ERRORS.failedToLoadFile, 'Failed to load PDF file');
  };

  const pickEpub = async () => {
    const file = await DocumentPicker.getDocumentAsync({
      base64: true,
      type: 'application/epub+zip',
      copyToCacheDirectory: false,
      multiple: false,
    });
    if (file) {
      if (file?.type === 'cancel') {
        return undefined;
      }

      if (file?.uri) {
        const base64 = file?.uri?.replace('data:application/epub+zip;base64,', '');
        if (base64) {
          return base64;
        }
        return errorJSON(ERRORS.failedToCompressFile, 'Failed to compress EPUB file');
      }
      return errorJSON(ERRORS.failedToPrepareFile, 'Failed to prepare EPUB file');
    }
    return errorJSON(ERRORS.failedToLoadFile, 'Failed to load EPUB file');
  };

  return { pickPdf, pickEpub };
};

export default useDocumentPicker;
