import { getErrorCode } from '../../utils/error-json';
import { getUserApiKey } from '../index';
import apiCall from './index';
import { processUser } from './utils';

export const clearUserPushTokens = async (user_id) => {
  try {
    const userApiKey = await getUserApiKey();
    if (!userApiKey) {
      return undefined;
    }
    if (!user_id) {
      return undefined;
    }
    const data = await apiCall({
      url: '/m/push-clear-tokens.json',
      method: 'POST',
      queryParams: {
        user_id,
      },
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return data;
    }
    return processUser(data, true);
  } catch {
    return undefined;
  }
};
