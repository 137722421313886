import { getErrorCode } from '../../utils/error-json';
import apiCall from './index';
import { parseCategorySlugsArray, processTopics, processUser, processUsers } from './utils';

export const searchTopics = async (query, categoryObjects) => {
  if (query === undefined || query?.length === 0) {
    return undefined;
  }
  if (categoryObjects === undefined || categoryObjects?.length === 0) {
    console.log('Empty categoryObjects for search.');
    return undefined;
  }
  try {
    const params = {
      query,
      search_content: true,
      category_slugs: parseCategorySlugsArray(categoryObjects),
    };
    const data = await apiCall({
      url: '/m/search-topic.json',
      queryParams: params,
      method: 'GET',
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return undefined;
    }
    const results = (data?.results || [])
      .map((d) => {
        const value = processTopics(d?.topics);
        const key = d?.slug;
        if (key && value) {
          const title = categoryObjects.find((ct) => ct.slug === key)?.title;
          if (title) {
            return { title, topics: value };
          }
        }
        return undefined;
      })
      .filter((x) => x !== undefined && x?.topics?.length > 0);

    return results;
  } catch (error) {
    console.log(`Error searchTopics. Details: ${error}`);
    return undefined;
  }
};

export const searchUsers = async (query) => {
  try {
    if (query === undefined || query?.length === 0 || (query?.length === 1 && query === '@')) {
      return undefined;
    }
    const params = {
      query,
    };
    const data = await apiCall({
      url: '/m/search-user.json',
      queryParams: params,
      method: 'GET',
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return undefined;
    }
    const results = processUsers(data?.users || []);
    return results;
  } catch (error) {
    console.log(`Error searchUsers. Details: ${error}`);
    return undefined;
  }
};
