import { VideoExportPreset } from 'expo-image-picker';
import * as ImagePicker from 'expo-image-picker';
import { SimpleAlert } from '../utils/alert';
import { errorJSON } from '../utils/error-json';

export const ERRORS = {
  failedPick: 'failedPick',
};

const useVideoPicker = () => {
  const checkForPermissions = async () => {
    const { granted, canAskAgain, status } = await ImagePicker.getCameraRollPermissionsAsync();
    if (granted) {
      return true;
    }
    if (!canAskAgain) {
      SimpleAlert(
        'Photo Library Access',
        'I-FIKR app needs access to your Photo Library to be able to upload an image or a video.',
      );
    }
    return false;
  };

  const pickVideo = async () => {
    if (!(await checkForPermissions())) {
      return;
    }
    const options = {
      allowsEditing: true,
      mediaTypes: ImagePicker.MediaTypeOptions.Videos,
      allowsMultipleSelection: false,
      quality: 1,
      exif: false,
      videoExportPreset: VideoExportPreset.H264_1920x1080,
      base64: true,
    };
    const result = await ImagePicker.launchImageLibraryAsync(options);
    if (result?.cancelled) {
      return { cancelled: true };
    }
    const { uri: base64 } = result;
    if (!base64) {
      return errorJSON(ERRORS.failedPick);
    }

    const newBase64 = base64.replace('data:video/mp4;base64,', '');

    return { base64: newBase64 };
  };

  return { checkForPermissions, pickVideo };
};

export default useVideoPicker;
