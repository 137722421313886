import React, { useEffect, useState } from 'react';
import { RegularTitleLabel } from '../../../../containers/labels';
import UserCell from '../cells/user-cell';
import { BenefactorUserCell, ContentContainer, ContentNoResultsContainer } from './styled';
import BenefactorCell from '../cells/benefactor-cell';
import { getBenefactorDetails, getBenefactors } from '../../../../networking/calls/benefactors';
import { LargeActivityIndicator } from '../../../../components/activity-indicators/ActivityIndicatorView';
import { dateDisplayFromDates, parseDate } from '../../../../utils/date-helpers';

export const CONTENT_TYPE = {
  benefactor: 0,
  benefactorUser: 1,
};

const ContentList = ({ contentType, itemId, refreshTrigger, buttonPress, buttonTitle }) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const onRefresh = async () => {
    setLoading(true);

    let r = [];
    if (contentType === CONTENT_TYPE.benefactor) {
      r = (await getBenefactors()) || [];
    } else if (contentType === CONTENT_TYPE.benefactorUser) {
      const benefactorDetails = await getBenefactorDetails(itemId);
      r = benefactorDetails?.userData || [];
    }

    setResults(r);
    setLoading(false);
  };

  useEffect(() => {
    onRefresh();
  }, [refreshTrigger]);

  const createItem = (r) => {
    if (contentType === CONTENT_TYPE.benefactorUser) {
      const _buttonPress = buttonPress ? () => buttonPress?.(r?.user?.username) : undefined;
      const additionalDetails = r?.expirationDate ? dateDisplayFromDates(r?.expirationDate) : undefined;

      return (
        <BenefactorUserCell>
          <UserCell
            key={r?.user?.id}
            user={r?.user}
            buttonPress={_buttonPress}
            buttonTitle={buttonTitle}
            additionalDetails={additionalDetails}
          />
        </BenefactorUserCell>
      );
    } else if (contentType === CONTENT_TYPE.benefactor) {
      const _buttonPress = buttonPress ? () => buttonPress?.(r?.id) : undefined;

      return <BenefactorCell key={r.id} benefactor={r} buttonTitle={buttonTitle} buttonPress={_buttonPress} />;
    }
    return null;
  };

  return (
    <ContentContainer>
      {!loading && results.length > 0 ? (
        <>
          {contentType === CONTENT_TYPE.benefactorUser && <>{results.map((item, i) => createItem(item, i))}</>}
          {contentType === CONTENT_TYPE.benefactor && <>{results.map((item, i) => createItem(item, i))}</>}
        </>
      ) : !loading && results?.length === 0 ? (
        <ContentNoResultsContainer>
          <RegularTitleLabel text={'0 entries found'} />
        </ContentNoResultsContainer>
      ) : (
        loading && <LargeActivityIndicator />
      )}
    </ContentContainer>
  );
};

export default ContentList;
