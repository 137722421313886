import React, { useEffect, useState } from 'react';
import { RegularTitleLabel } from '../../../../../containers/labels';
import { uploadFile } from '../../../../../networking/calls/upload';
import { SimpleAlert } from '../../../../../utils/alert';
import { getErrorCode } from '../../../../../utils/error-json';
import { CellLoader, CellRow } from '../styled';

const AttachmentsUploadCell = ({ base64, caption, type, onFinishUploading, onError }) => {
  const [started, setStarted] = useState(false);

  const hasCaption = caption?.length > 0;

  useEffect(() => {
    if (!started) {
      setStarted(true);
    }
  }, []);

  const upload = async () => {
    let json;

    try {
      json = await uploadFile(base64, type);
    } catch (error) {
      SimpleAlert(
        'Upload Error',
        `Something went wrong while uploading ${hasCaption ? caption : 'file'}. Unknown error: ${error?.message}.`,
      );
      onError && onError(caption);
      return undefined;
    }

    const errorCode = getErrorCode(json);

    if (errorCode || !json) {
      if (errorCode === 'file_too_big') {
        SimpleAlert('Upload Error', `${hasCaption ? caption : 'File'} size is too big. Please choose a smaller one.`);
      } else if (!json) {
        SimpleAlert(
          'Upload Error',
          `Something went wrong while uploading ${hasCaption ? caption : 'file'}. Please try again.`,
        );
      } else {
        SimpleAlert(
          'Upload Error',
          `Failed to upload ${hasCaption ? caption : 'file'}. Details: ${json?.error?.message || errorCode}`,
        );
      }

      onError && onError(caption);
      return undefined;
    }
    return json;
  };

  useEffect(() => {
    if (started) {
      const doWork = async () => {
        const json = await upload();
        if (json === undefined) {
          return; // Handled in upload
        }
        onFinishUploading && onFinishUploading(caption, json);
      };
      doWork();
    }
  }, [started]);

  return (
    <CellRow>
      <RegularTitleLabel title={`Uploading ${hasCaption ? caption : 'file'}...`} />
      <CellLoader />
    </CellRow>
  );
};

export default AttachmentsUploadCell;
