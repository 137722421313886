import toNowStrict from 'date-fns/formatDistanceToNowStrict';
import isFuture from 'date-fns/isFuture';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import isSameMonth from 'date-fns/isSameMonth';
import isSameDay from 'date-fns/isSameDay';
import getDaysInMonth from 'date-fns/getDaysInMonth';

const dayMonthAndYearFormat = 'd MMMM, y';
const timeAndDayMonthAndYearFormat = 'HH:mm - d MMMM, y';

export const parseDate = (rawDate) => {
  if (rawDate !== undefined && rawDate !== null && rawDate !== '') {
    return parseISO(rawDate) || undefined;
  }
  return undefined;
};

export const timeDistanceToDate = (rawDate) => {
  const date = parseDate(rawDate);
  return `${isFuture(date) ? 'in ' : ''}${toNowStrict(date)}${!isFuture(date) ? ' ago' : ''}`;
};

export const daysInMonth = (date) => {
  return date && getDaysInMonth(date);
};

export const dateDisplayFromISO = (isoString) => {
  const date = parseDate(isoString);
  return date && `${format(date, dayMonthAndYearFormat)}`;
};

export const dateTimeDisplayFromISO = (isoString) => {
  const date = parseDate(isoString);
  return date && `${format(date, timeAndDayMonthAndYearFormat)}`;
};

export const dateDisplayFromDates = (firstIsoString, secondIsoString) => {
  const dStart = parseDate(firstIsoString);
  if (!dStart) {
    return undefined;
  }
  if (!secondIsoString) {
    return dateDisplayFromISO(firstIsoString);
  }

  const dEnd = parseDate(secondIsoString);
  if (isSameDay(dStart, dEnd)) {
    return dStart && `${format(dStart, dayMonthAndYearFormat)}`;
  }

  return isSameMonth(dStart, dEnd)
    ? `${format(dStart, 'd')}-${format(dEnd, dayMonthAndYearFormat)}`
    : `${format(dStart, 'd MMMM')} - ${format(dEnd, dayMonthAndYearFormat)}`;
};
