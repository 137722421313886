import { useCallback, useEffect, useRef, useState } from 'react';
import { ITEM_TYPE } from '../ui/app/shared/search/search-list';
import { searchTopics, searchUsers } from '../networking/calls/search';
import { debounce } from 'lodash';

const useSearch = ({ itemType, types }) => {
  const [results, setResults] = useState([]);

  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const noFiltersRef = useRef(true);
  const didResetSearchingRef = useRef(false);

  const textToSearch = useRef();

  const searchForText = async (t) => {
    textToSearch.current = t;
    if (t && t?.length < 1) {
      setResults([]);
      return;
    }

    setLoading(true);

    let r;
    if (itemType === ITEM_TYPE.topic) {
      r = (await searchTopics(textToSearch.current, types)) || [];
    } else {
      r = (await searchUsers(textToSearch.current)) || [];
    }
    noFiltersRef.current = true;
    setResults(r);

    setLoading(false);
  };

  const searchDebounce = useCallback(debounce(searchForText, 250), []);

  useEffect(() => {
    if (searchText !== undefined && !didResetSearchingRef.current) {
      searchDebounce(searchText);
    }
    didResetSearchingRef.current = false;
  }, [searchText]);

  const resetSearching = () => {
    didResetSearchingRef.current = true;
    setResults([]);
  };

  return { results, loading, searchText, setSearchText, setResults, noFiltersRef, resetSearching };
};

export default useSearch;
