import { Platform } from 'react-native';
import * as Device from 'expo-device';
import AsyncRawStorage from '../utils/AsyncRawStorage';
import env from '../../env';
import { createKeyPair } from './utils/crypto-utils';

export const baseUrl = env.networking.baseUrl;

const SECURE_KEYS = {
  CLIENT_ID: 'auth_client_id',
  USER_API_KEY: 'auth_user_api_key',
  PRIVATE_KEY: 'private_key_auth',
  PUBLIC_KEY: 'public_key_auth',
  USERNAME: 'username',
};

const ERRORS = {
  invalidKeyPairFormat: 'Certificate format is invalid',
};

let clientIdCache, privateKeyCache, publicKeyCache, userApiKeyCache, usernameCache;

export const getKeyPair = async () => {
  if (!publicKeyCache || !privateKeyCache) {
    const currentPublicKey = await AsyncRawStorage.getString(SECURE_KEYS.PUBLIC_KEY);
    const currentPrivateKey = await AsyncRawStorage.getString(SECURE_KEYS.PRIVATE_KEY);

    if (!currentPublicKey || !currentPrivateKey) {
      const keyPair = await createKeyPair();
      if (keyPair && keyPair.public && keyPair.private) {
        await AsyncRawStorage.setString(SECURE_KEYS.PUBLIC_KEY, keyPair.public);
        await AsyncRawStorage.setString(SECURE_KEYS.PRIVATE_KEY, keyPair.private);

        publicKeyCache = keyPair.public;
        privateKeyCache = keyPair.private;

        return keyPair;
      }
      throw ERRORS.invalidKeyPairFormat;
    }

    publicKeyCache = currentPublicKey;
    privateKeyCache = currentPrivateKey;
  }
  return { public: publicKeyCache, private: privateKeyCache };
};

const getSecureCachedValue = async (key, cachedValue, refreshCacheCallback) => {
  if (cachedValue) {
    return cachedValue;
  }
  const value = await AsyncRawStorage.getString(key);
  refreshCacheCallback(value);
  return value;
};

const setSecureCacheValue = async (key, value, refreshCacheCallback) => {
  await AsyncRawStorage.setString(key, value);
  refreshCacheCallback(value);
};

const clientIdChanged = (v) => {
  clientIdCache = v;
};
export const getClientId = async () =>
  await getSecureCachedValue(SECURE_KEYS.CLIENT_ID, clientIdCache, clientIdChanged);
export const setClientId = async (value) => await setSecureCacheValue(SECURE_KEYS.CLIENT_ID, value, clientIdChanged);

const userApiKeyChanged = (v) => {
  userApiKeyCache = v;
};
export const getUserApiKey = async () =>
  await getSecureCachedValue(SECURE_KEYS.USER_API_KEY, userApiKeyCache, userApiKeyChanged);
export const setUserApiKey = async (value) =>
  await setSecureCacheValue(SECURE_KEYS.USER_API_KEY, value, userApiKeyChanged);

const usernameChanged = (v) => {
  usernameCache = v;
};
export const getUsername = async () => await getSecureCachedValue(SECURE_KEYS.USERNAME, usernameCache, usernameChanged);
export const setUsername = async (value) => await setSecureCacheValue(SECURE_KEYS.USERNAME, value, usernameChanged);

export const pushUrl = `https://api.discourse.org/api/publish_${Platform.OS}`;
export const clientDeviceName = `${Device.osName || ''} ${Device.osVersion || ''}`;
