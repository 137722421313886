import React, { useState } from 'react';
import { SolidButtonLabel } from '../../../../../../containers/labels';
import useVideoPicker from '../../../../../../hooks/useVideoPicker';
import { SimpleAlert } from '../../../../../../utils/alert';
import { getErrorCode } from '../../../../../../utils/error-json';
import { ATTACHMENT_BUTTON_WIDTH } from '../../../../../defines';
import DetailedInputRaw from '../../input/detailed-input-raw';
import { CellButton, CellRow } from '../../styled';
import { AttachmentType } from '../attachments-cell';

const AttachmentsAddVideo = ({ afterFilePick, disabled, shouldStartProcess }) => {
  const [caption, setCaption] = useState('');

  const { pickVideo } = useVideoPicker();

  const onUploadPress = async () => {
    if (!shouldStartProcess()) {
      return;
    }
    const result = await pickVideo();

    const errorCode = getErrorCode(result);
    if (errorCode || !result) {
      SimpleAlert(
        'Choose Video',
        `Something went wrong while processing your video. Please try again. Error details: ${errorCode}`,
      );
      return;
    }

    const { base64 } = result;
    afterFilePick(base64, caption, AttachmentType.MP4);
    setCaption('');
  };

  return (
    <CellRow>
      <DetailedInputRaw value={caption} setValue={setCaption} placeholder={'Enter video caption (optional)'} />
      <CellButton disabled={disabled} buttonWidth={ATTACHMENT_BUTTON_WIDTH} onPress={() => onUploadPress()}>
        <SolidButtonLabel title={'UPLOAD VIDEO'} />
      </CellButton>
    </CellRow>
  );
};

export default AttachmentsAddVideo;
