import React from 'react';
import { GENERIC_WHITE } from '../../../assets/colors';
import styled from 'styled-components/native';

export const WebScreenPaddingRaw = styled.View`
  margin: 32px;
  flex: 1;
`;

export const BackgroundScrollView = styled.ScrollView`
  background-color: ${GENERIC_WHITE};
  width: 100%;
`;

export const FooterView = styled.View`
  position: relative;
  height: 32px;
  width: 100%;
`;

export const WebScreenPadding = ({ children, rest }) => (
  <BackgroundScrollView>
    <WebScreenPaddingRaw {...{ rest }}>{children}</WebScreenPaddingRaw>
  </BackgroundScrollView>
);

const WebScreenContainer = ({ children, rest }) => (
  <WebScreenPadding {...rest}>
    {children}
    <FooterView />
  </WebScreenPadding>
);

export default WebScreenContainer;
