import styled from 'styled-components/native';

export const ContentContainer = styled.View`
  width: 100%;
  flex: 1;
  overflow: visible;
  flex-basis: auto;
  flex-shrink: 0;
`;

export const BenefactorUserCell = styled.View`
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 12px;
`;

export const ContentNoResultsContainer = styled.View`
  margin-top: 64px;
  align-self: center;
`;
