import React, { useState } from 'react';
import { RED } from '../../../../assets/colors';
import { closeTopic, openTopic, ERRORS } from '../../../networking/calls/topics';
import { ErrorAlert, SuccessAlert } from '../../../utils/alert';
import { getErrorCode } from '../../../utils/error-json';
import DetailedInputCell from './form-cells/input/detailed-input-cell';

const TopicCloseComponent = ({ item, setItem, capitalizedCustomTag }) => {
  const [loading, setLoading] = useState(false);

  const capitalizedTag = capitalizedCustomTag || 'Topic';
  const normalTag = capitalizedCustomTag?.toLowerCase() || 'topic';
  const uppercasedTag = capitalizedCustomTag?.toUpperCase() || 'TOPIC';

  const [reason, setReason] = useState('');
  const [resolution, setResolution] = useState('');
  const closed = item?.topic?.closed;

  const close = async () => {
    if (closed) {
      return undefined;
    }
    setLoading(true);
    try {
      const newTopic = await closeTopic(item?.topic?.id, reason);
      const errorCode = getErrorCode(newTopic);
      if (errorCode || !newTopic) {
        if (!newTopic) {
          ErrorAlert(`Failed to close ${normalTag}. Details: unknown`);
        } else {
          if (ERRORS.closedButNoTopic === errorCode) {
            setReason('');
            SuccessAlert(`${capitalizedTag} was closed, but interface could not be updated.`);
          } else {
            ErrorAlert(newTopic?.error?.message || errorCode);
          }
        }
      } else {
        setReason('');
        setItem(newTopic);
        SuccessAlert(`${capitalizedTag} was closed.`);
      }
    } catch (error) {
      ErrorAlert(`Failed to close ${normalTag}. Details: ${error}`);
    }
    setLoading(false);
  };

  const reopen = async () => {
    if (closed) {
      setLoading(true);
      try {
        const newTopic = await openTopic(item?.topic?.id, resolution);
        const errorCode = getErrorCode(newTopic);
        if (errorCode || !newTopic) {
          if (!newTopic) {
            ErrorAlert(`Failed to open ${normalTag}. Details: unknown`);
          } else {
            if (ERRORS.openedButNoTopic === errorCode) {
              setResolution('');
              SuccessAlert(`${capitalizedTag} was reopened, but interface could not be updated.`);
            } else {
              ErrorAlert(newTopic?.error?.message || errorCode);
            }
          }
        } else {
          setResolution('');
          setItem(newTopic);
          SuccessAlert(`${capitalizedTag} was reopened.`);
        }
      } catch (error) {
        ErrorAlert(`Failed to reopen ${normalTag}. Details: ${error}`);
      }
      setLoading(false);
    }
  };

  return (
    <>
      {closed && (
        <DetailedInputCell
          title={'reopen'}
          value={resolution}
          setValue={setResolution}
          loading={loading}
          placeholder={`Enter a resolution for reopening this ${normalTag}`}
          buttonTitle={`REOPEN ${uppercasedTag}`}
          buttonPress={reopen}
        />
      )}
      {!closed && (
        <DetailedInputCell
          title={'close'}
          value={reason}
          setValue={setReason}
          loading={loading}
          placeholder={`Enter a reason for closing this ${normalTag}`}
          buttonTitle={`CLOSE ${uppercasedTag}`}
          buttonColor={RED}
          buttonPress={close}
        />
      )}
    </>
  );
};

export default TopicCloseComponent;
