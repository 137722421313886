import React, { useCallback } from 'react';
import { WhiteSeparatorWithPadding } from '../../../../containers/views/Separators';
import useDetails from '../../../../hooks/useDetails';
import {
  CellMainLabel,
  CellAdditionalLabel,
  CellAdditionalRedLabel,
  CellContent,
  CellImage,
  CellRowContent,
  CellAdditionalGreenLabel,
} from './styled';
import { RED } from '../../../../../assets/colors';
import { SolidButtonLabel } from '../../../../containers/labels';
import { SolidButton } from '../../../../containers/buttons';

const Placeholder = require('../../../../../assets/placeholders/placeholder-generic-square.png');

const UserCell = ({ user, disabled = false, onCellPress, buttonPress, buttonTitle, additionalDetails }) => {
  const openDetails = useDetails();
  const _openDetails = () => openDetails(user);

  const _onCellPress = () => {
    if (onCellPress) {
      onCellPress?.(user);
    } else {
      _openDetails();
    }
  };

  const rawPoints = user?.leaderboard?.points;
  const totalPoints =
    typeof rawPoints === 'number' && rawPoints > 0 ? `${rawPoints} ${rawPoints === 1 ? 'point' : 'points'}` : undefined;
  const userString = `@${user?.username || ''}`;
  const isPaying =
    user?.expirationDateTs !== undefined && user?.expirationDateTs !== null
      ? user?.expirationDateTs > Date.now().valueOf()
      : false;
  const specialTag = isPaying ? user?.private?.subscription?.specialTag : undefined;

  return (
    <CellContent disabled={disabled} onPress={_onCellPress}>
      <CellImage placeholder={Placeholder} source={{ uri: user?.imageUrl }} />
      <CellRowContent>
        <CellMainLabel numberOfLines={3} text={user?.fullName} />
        <CellAdditionalLabel numberOfLines={3} text={userString} />
        {totalPoints && <CellAdditionalLabel numberOfLines={3} text={totalPoints} />}
        {additionalDetails && <CellAdditionalLabel numberOfLines={1} text={additionalDetails} />}
        <WhiteSeparatorWithPadding />
        <CellAdditionalLabel numberOfLines={3} text={'ID: ' + user?.id} />
        {user?.private?.admin && <CellAdditionalGreenLabel numberOfLines={3} text={'ADMIN: YES'} />}
        {user?.private?.moderator && <CellAdditionalGreenLabel numberOfLines={3} text={'MODERATOR: YES'} />}
        {specialTag && <CellAdditionalGreenLabel numberOfLines={3} text={specialTag?.toUpperCase()} />}
        {user?.suspended && <CellAdditionalRedLabel numberOfLines={1} text={'SUSPENDED USER'} />}
      </CellRowContent>
      {buttonPress && buttonTitle && (
        <SolidButton disabled={false} onPress={buttonPress} backgroundColor={RED}>
          <SolidButtonLabel title={buttonTitle} />
        </SolidButton>
      )}
    </CellContent>
  );
};

export default UserCell;
