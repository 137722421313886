import { sortBy } from 'lodash';
import ct from 'countries-and-timezones';

const allTimezones = Object.values(ct.getAllTimezones()).map((t) => ({
  ...t,
  formatted: t?.name?.replace(/^\w*\//, '').replace('_', ' '),
}));

const timezonesWithData = sortBy(allTimezones, ['utcOffset', 'name']);

export const offsetForTimezone = (timezone) => {
  return ct.getTimezone(timezone)?.utcOffset || 0;
};

export default timezonesWithData;
