import styled from 'styled-components/native';
import { TextLabel } from '../../../../containers/labels';

export const styles = {
  filtersWrapper: {
    paddingRight: 12,
  },
  filtersStyle: {
    paddingBottom: 16,
  },
};

export const SearchResultsHeader = styled.View`
  margin-top: 4px;
  margin-bottom: 12px;
  align-items: center;
  flex-direction: row;
`;

export const SearchNoResultsContainer = styled.View`
  margin-top: 64px;
  align-self: center;
`;

export const SearchSectionContainer = styled.View``;

export const SearchFiltersContainer = styled.View`
  flex-direction: column;
`;

export const SearchFiltersLabel = styled(TextLabel)`
  padding-top: 0px;
  padding-bottom: 4px;
`;

export const SearchContainer = styled.View`
  width: 100%;
  flex: 1;
  overflow: visible;
  flex-basis: auto;
  flex-shrink: 0;
`;
