import React, { useEffect, useState } from 'react';
import { GREEN, RED } from '../../../../../assets/colors';
import { LargeActivityIndicator } from '../../../../components/activity-indicators/ActivityIndicatorView';
import { SolidButton } from '../../../../containers/buttons';
import { SolidButtonLabel } from '../../../../containers/labels';
import { ProjectNavGreenText, ProjectNavRedText } from '../../../../containers/navigation/NavigationBarItem';
import { clearUserPushTokens } from '../../../../networking/calls/push';
import { forceSubscriptionCheck, forceSubscriptionClear } from '../../../../networking/calls/subscriptions';
import { dateDisplayFromDates } from '../../../../utils/date-helpers';
import DetailedInputCell from '../../shared/form-cells/input/detailed-input-cell';
import { MiddleBlueSeparatorWithPadding } from '../../../../containers/views/Separators';
import WebScreenContainer from '../../../../containers/views/WebScreenContainer';
import { deleteUserById, ERRORS, fetchUser, suspendUser, unsuspendUser } from '../../../../networking/calls/user';
import { ConfirmationAlert, ErrorAlert, SuccessAlert } from '../../../../utils/alert';
import { getErrorCode } from '../../../../utils/error-json';
import UserCell from '../../shared/cells/user-cell';
import BenefactorCell from '../../shared/cells/benefactor-cell';
import { DescriptionInputLabel } from '../../shared/form-cells/input/styled';
import { SolidGrayLabel } from './styled';

const genericPlaceholder = 'NOT SET';

const User = ({ navigation, route }) => {
  const item = route?.params?.item;

  const [user, setUser] = useState(item);
  const [reason, setReason] = useState('');
  const [resolution, setResolution] = useState('');

  const [loadingPrivate, setLoadingPrivate] = useState(false);

  const [suspendLoading, setSuspendLoading] = useState(false);
  const [refreshSubLoading, setRefreshSubLoading] = useState(false);

  const [clearPushTokenLoading, setClearPushTokenLoading] = useState(false);

  const suspendedString = user?.suspended === true ? `Yes, on ${user?.suspendedAtDateString}` : 'No';

  const sub = user?.private?.subscription;
  const platform = sub?.platform;
  const prod = sub?.product;
  const subscriptionString =
    user?.adminOrModerator === true
      ? 'Activated automatically for admins'
      : sub
      ? `${sub?.active ? 'Active' : 'Inactive'} - ${platform} - ${prod?.id || 'unknown product id'} - ${
          prod?.defaultPriceDisplay
        }, expiring on ${user?.subscriptionExpireDateString}`
      : 'No';

  const fetchPrivateInfo = async () => {
    setLoadingPrivate(true);
    const privateUser = await fetchUser(user?.id);
    setLoadingPrivate(false);
    if (privateUser) {
      setUser(privateUser);
      return;
    }
    ErrorAlert(
      'Private information for user could not be retrieved. Some functionality on the page will be disabled. Please access the user from the Users tab.',
    );
  };

  useEffect(() => {
    if (!user?.private) {
      fetchPrivateInfo();
    }
  }, []);

  useEffect(() => {
    if (user?.suspended) {
      navigation.setOptions({
        headerRight: () => <ProjectNavRedText title={'USER SUSPENDED'} />,
      });
    } else {
      navigation.setOptions({
        headerRight: () => <ProjectNavGreenText title={'USER ACTIVE'} />,
      });
    }
  }, [user]);

  const suspend = async () => {
    if (user?.suspended) {
      return undefined;
    }
    setSuspendLoading(true);
    try {
      const newUser = await suspendUser(user?.id, reason);
      const errorCode = getErrorCode(newUser);
      if (errorCode || !newUser) {
        if (!newUser) {
          ErrorAlert('Failed to suspend user. Details: unknown');
        } else {
          if (ERRORS.suspendedButNoUser === errorCode) {
            setReason('');
            SuccessAlert('User was suspended, but interface could not be updated.');
          } else {
            ErrorAlert(newUser?.error?.message || errorCode);
          }
        }
      } else {
        setReason('');
        setUser(newUser);
        SuccessAlert('User was suspended.');
      }
    } catch (error) {
      ErrorAlert(`Failed to suspend user. Details: ${error}`);
    }
    setSuspendLoading(false);
  };

  const deleteUser = async () => {
    const id = user?.id;
    const username = user?.username;
    id &&
      username &&
      ConfirmationAlert(
        `Are you sure you want to delete user @${username}?`,
        'Please make sure this user does not have a benefactor set or a subscription associated.' +
          '\n\nThis action will delete all posts created by the user and cannot be undone! ',
        async () => {
          if (id && username && (await deleteUserById(id))) {
            SuccessAlert(`User @${username}(ID: ${id}) was deleted.`);
            navigation?.goBack?.();
          } else {
            ErrorAlert('User deletion failed. This usually happens if the user is a moderator or an admin.');
          }
        },
      );
  };

  const unsuspend = async () => {
    if (user?.suspended) {
      setSuspendLoading(true);
      try {
        const newUser = await unsuspendUser(user?.id, resolution);
        const errorCode = getErrorCode(newUser);
        if (errorCode || !newUser) {
          if (!newUser) {
            ErrorAlert('Failed to reactivate user. Details: unknown');
          } else {
            if (ERRORS.unsuspendedButNoUser === errorCode) {
              setResolution('');
              SuccessAlert('User was reactivated, but interface could not be updated.');
            } else {
              ErrorAlert(newUser?.error?.message || errorCode);
            }
          }
        } else {
          setResolution('');
          setUser(newUser);
          SuccessAlert('User was reactivated.');
        }
      } catch (error) {
        ErrorAlert(`Failed to reactivate user. Details: ${error}`);
      }
      setSuspendLoading(false);
    }
  };

  const validateFieldValue = (value) => {
    return value !== undefined && value !== null ? value : '';
  };

  const adminOrModerator = user?.adminOrModerator === true;

  const updateSubscriptionDetails = async () => {
    if (user?.id) {
      setRefreshSubLoading(true);
      const updatedUser = await forceSubscriptionCheck(user?.id);
      const errorCode = getErrorCode(updatedUser);
      if (errorCode || !updatedUser) {
        if (!updatedUser) {
          ErrorAlert('Failed to update user subscription. Details: unknown');
        } else {
          ErrorAlert(updatedUser?.error?.message || errorCode);
        }
      } else {
        setUser(updatedUser);
        SuccessAlert('User subscription was successfully updated.');
      }
      setRefreshSubLoading(false);
    }
  };

  const clearSubscriptionDetails = async () => {
    if (user?.id) {
      ConfirmationAlert(
        'Clear user subscription info?',
        'Only use this option for special cases. For subscriptions cancellations, go to the App Store Connect Dashboard or the Google Play Store Order Management section. If the user subscription from mobile is still active or renewing, the user subscription will be restored while using the app or automatically from the server, but the user may need to tap Restore Purchases button to speed this up. This is the same operation that makes migration possible from Android to iOS.',
        async () => {
          setRefreshSubLoading(true);
          const updatedUser = await forceSubscriptionClear(user?.id);
          const errorCode = getErrorCode(updatedUser);
          if (errorCode || !updatedUser) {
            if (!updatedUser) {
              ErrorAlert('Failed to clear user subscription. Details: unknown');
            } else {
              ErrorAlert(updatedUser?.error?.message || errorCode);
            }
          } else {
            setUser(updatedUser);
            SuccessAlert('User subscription was successfully cleared.');
          }
          setRefreshSubLoading(false);
        },
      );
    }
  };

  const badgesText = user?.badges?.map((b) => `${b.id} - ${b.name} - ${b.shortDescription}`)?.join('\n');
  const pushTokensText = user?.private?.pushTokens?.join('\n\n');

  const clearPushTokens = () => {
    if (user?.id) {
      ConfirmationAlert(
        'Clear user push tokens?',
        'Only use this option for special cases. This is only useful when an old user still gets notifications on his devices. Use with care.',
        async () => {
          setClearPushTokenLoading(true);
          const updatedUser = await clearUserPushTokens(user?.id);
          const errorCode = getErrorCode(updatedUser);
          if (errorCode || !updatedUser) {
            if (!updatedUser) {
              ErrorAlert('Failed to clear user push tokens. Details: unknown');
            } else {
              ErrorAlert(updatedUser?.error?.message || errorCode);
            }
          } else {
            setUser(updatedUser);
            SuccessAlert("User's push tokens were cleared.");
          }
          setClearPushTokenLoading(false);
        },
      );
    }
  };

  const rawPoints = user?.leaderboard?.points;
  const totalPoints =
    typeof rawPoints === 'number' && rawPoints > 0 ? `${rawPoints} ${rawPoints === 1 ? 'point' : 'points'}` : '';

  return (
    <WebScreenContainer>
      <UserCell user={user} disabled={true} />
      <MiddleBlueSeparatorWithPadding />
      <>
        {!loadingPrivate && user?.private && !adminOrModerator && (
          <>
            {user?.suspended && (
              <DetailedInputCell
                title={'reactivate'}
                value={resolution}
                setValue={setResolution}
                loading={suspendLoading}
                placeholder={'Enter a resolution for reactivating this user'}
                buttonTitle={'REACTIVATE USER'}
                buttonPress={unsuspend}
              />
            )}
            {!user?.suspended && (
              <DetailedInputCell
                title={'suspend'}
                value={reason}
                setValue={setReason}
                loading={suspendLoading}
                placeholder={'Enter a reason for suspending this user'}
                buttonTitle={'SUSPEND USER'}
                buttonColor={RED}
                buttonPress={suspend}
              />
            )}
            <MiddleBlueSeparatorWithPadding />
          </>
        )}
        {loadingPrivate && (
          <>
            <LargeActivityIndicator />
            <MiddleBlueSeparatorWithPadding />
          </>
        )}
      </>
      <DetailedInputCell
        title={'id'}
        value={validateFieldValue(user?.id)}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'email'}
        value={validateFieldValue(user?.private?.email)}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'admin'}
        value={user?.private?.admin === true ? 'Yes' : 'No'}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'moderator'}
        value={user?.private?.moderator === true ? 'Yes' : 'No'}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'username'}
        value={validateFieldValue(user?.username)}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'full Name'}
        value={validateFieldValue(user?.fullName)}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'first Name'}
        value={validateFieldValue(user?.firstName)}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'last Name'}
        value={validateFieldValue(user?.lastName)}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'position'}
        value={validateFieldValue(user?.title)}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'institution'}
        value={validateFieldValue(user?.institution)}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'country'}
        value={validateFieldValue(user?.country)}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'city'}
        value={validateFieldValue(user?.city)}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'badges'}
        value={validateFieldValue(badgesText)}
        multiline={true}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'bio'}
        value={validateFieldValue(user?.bio)}
        multiline={true}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'education'}
        value={validateFieldValue(user?.education)}
        multiline={true}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'date of birth'}
        value={validateFieldValue(dateDisplayFromDates(user?.private?.dateOfBirth))}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'gender'}
        value={validateFieldValue(user?.private?.gender)}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'Phone Number'}
        value={validateFieldValue(user?.private?.phoneNumber)}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'created on'}
        value={user?.dateString}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'updated on'}
        value={user?.updatedDateString}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell
        title={'last seen on'}
        value={user?.lastSeenAtDateString}
        disabled={true}
        placeholder={genericPlaceholder}
      />
      <DetailedInputCell title={'suspended'} value={suspendedString} disabled={true} placeholder={genericPlaceholder} />
      {user?.private && (
        <>
          <DetailedInputCell
            title={'Push tokens'}
            value={validateFieldValue(pushTokensText)}
            multiline={true}
            disabled={true}
            placeholder={genericPlaceholder}
            buttonPress={clearPushTokens}
            buttonTitle={'CLEAR PUSH TOKENS'}
            buttonColor={RED}
            loading={clearPushTokenLoading}
            forceEnableButton={true}
          />
          <DetailedInputCell
            title={'subscription summary'}
            value={subscriptionString}
            disabled={true}
            loading={refreshSubLoading}
            forceEnableButton={user?.adminOrModerator !== true}
            buttonPress={user?.adminOrModerator !== true ? updateSubscriptionDetails : undefined}
            buttonTitle={'REFRESH'}
            buttonColor={GREEN}
            buttonPress2={user?.adminOrModerator !== true ? clearSubscriptionDetails : undefined}
            buttonTitle2={'CLEAR SUBSCRIPTION'}
            buttonColor2={RED}
            placeholder={genericPlaceholder}
          />
          <DetailedInputCell
            title={'subscription platform'}
            value={validateFieldValue(user?.private?.subscription?.platform)}
            disabled={true}
            placeholder={genericPlaceholder}
          />
          <DetailedInputCell
            title={'subscription transaction id (iOS) / purchase token (Android)'}
            value={validateFieldValue(user?.private?.subscription?.transactionId)}
            disabled={true}
            placeholder={genericPlaceholder}
          />
          <DetailedInputCell
            title={'subscription updated at'}
            value={validateFieldValue(user?.private?.subscription?.updatedAtString)}
            disabled={true}
            placeholder={genericPlaceholder}
          />
          <DetailedInputCell
            title={'subscription auto refreshed at'}
            value={validateFieldValue(user?.private?.subscription?.autoRefreshedAtString)}
            disabled={true}
            placeholder={genericPlaceholder}
          />
          {user?.private?.providers?.length > 0 &&
            (user?.private?.providers || []).map((p) => {
              return (
                <>
                  <DetailedInputCell
                    title={p.provider + ' Sign Up ID'}
                    value={validateFieldValue(p?.id)}
                    disabled={true}
                    placeholder={genericPlaceholder}
                  />
                  <DetailedInputCell
                    title={p.provider + ' Sign Up Email'}
                    value={validateFieldValue(p?.email)}
                    disabled={true}
                    placeholder={genericPlaceholder}
                  />
                </>
              );
            })}
          {user?.private?.benefactor && (
            <>
              <DescriptionInputLabel>BENEFACTOR</DescriptionInputLabel>
              <BenefactorCell benefactor={user?.private?.benefactor} />
            </>
          )}
          <MiddleBlueSeparatorWithPadding />
          <DetailedInputCell
            title={'Leaderboard points'}
            value={validateFieldValue(totalPoints)}
            disabled={true}
            placeholder={genericPlaceholder}
          />
          {Object.entries(user?.private?.leaderboard?.breakdown).map(([key, value]) => {
            const _value = Array.isArray(value.data) ? value.data.join(', ') : value.data;
            const points = value.points;
            const nrOfItems = Array.isArray(value.data) ? value.data.length : undefined;
            const title = `${value?.title} (1 x ${points} ${points == '1' ? 'point' : 'points'} ${
              nrOfItems !== undefined ? ' | ' + nrOfItems + (nrOfItems === 1 ? ' item)' : ' items)') : ')'
            }`;
            return (
              <DetailedInputCell
                key={key}
                description={value?.description}
                title={title}
                value={_value}
                disabled={true}
                placeholder={genericPlaceholder}
              />
            );
          })}
          {user && user?.id && user?.username && !user?.private?.benefactor && user?.suspended && !adminOrModerator ? (
            <>
              <MiddleBlueSeparatorWithPadding />
              <SolidButton backgroundColor={'red'} onPress={deleteUser}>
                <SolidButtonLabel title={`Delete @${user.username}`} />
              </SolidButton>
            </>
          ) : (
            <>
              <MiddleBlueSeparatorWithPadding />
              <SolidGrayLabel
                title={`To delete @${user.username}, make sure they do not have benefactors and they are suspended.`}
              />
            </>
          )}
        </>
      )}
    </WebScreenContainer>
  );
};

export default User;
