import styled from 'styled-components/native';
import { AppCSS } from '../index';
import { Platform } from 'react-native';

const commonStyleCSS = `
  ${AppCSS.genericPaddingForNavBarItems};
  align-items: center;
  flex-direction: row;
`;

const backStyleCSS = `
  ${AppCSS.genericPaddingForNavBarItems};
  ${Platform.OS === 'android' ? 'padding-left: 4px;' : ''};
  align-items: center;
  flex-direction: row;
`;

const backTouchStyleCSS = `
  ${AppCSS.genericPaddingForNavBarItems};
  ${Platform.OS === 'android' ? 'padding-left: 12px;' : ''};
  align-items: center;
  flex-direction: row;
`;

export const NavigationBackItemSimple = styled.View`
  ${backStyleCSS}
`;

export const NavigationBackItemTouchable = styled.TouchableOpacity`
  ${backTouchStyleCSS}
`;

export const NavigationBarItemSimple = styled.View`
  ${commonStyleCSS}
`;

export const NavigationBarItemTouchable = styled.TouchableOpacity`
  ${commonStyleCSS}
`;
