import AsyncStorage from '@react-native-community/async-storage';

const _getObjectOrInit = async (key, initialValue) => {
  const cachedObject = await AsyncRawStorage.getObject(key);
  if (cachedObject) {
    return cachedObject;
  }
  if (initialValue) {
    await AsyncRawStorage.setObject(key, initialValue);
  }
  return initialValue;
};

const _getObject = async (key) => {
  const json = await AsyncRawStorage.getString(key);
  const cachedObject = json && JSON.parse(json);
  if (cachedObject) {
    return cachedObject;
  }
};

const _setObject = async (key, object) => {
  const string = JSON.stringify(object);
  if (string) {
    await AsyncRawStorage.setString(key, string);
  }
};

const AsyncRawStorage = {
  getString: async (key) => {
    try {
      const value = await AsyncStorage.getItem(key);
      if (value === null) {
        return undefined;
      }
      return value;
    } catch (error) {
      console.log(`AsyncRawStorage: Could not get value for key ${key}. [Details: ${error}]`);
      return undefined;
    }
  },
  setString: async (key, value) => {
    if (value && typeof value !== 'string') {
      return;
    }
    try {
      value === undefined || value === null
        ? await AsyncStorage.removeItem(key)
        : await AsyncStorage.setItem(key, value);
    } catch (error) {
      console.log(`AsyncRawStorage: Could not set value ${value} to key ${key}. [Details: ${error}]`);
    }
  },
  getObject: _getObject,
  setObject: _setObject,
  getObjectOrInit: _getObjectOrInit,
};

export default AsyncRawStorage;
