import React from 'react';
import { BLUE, RED } from '../../../../../../assets/colors';
import { SolidButtonLabel } from '../../../../../containers/labels';
import useDetails from '../../../../../hooks/useDetails';
import { getTopicById } from '../../../../../networking/calls/topics';
import { ErrorAlert } from '../../../../../utils/alert';
import { getErrorCode } from '../../../../../utils/error-json';
import { CellButton, CellRow } from '../styled';
import * as WebBrowser from 'expo-web-browser';
import { AttachementsFinalLabel, AttachementsFinalLabelContainer } from './styled';

const getTag = (item) => {
  const type = item?.type || item?.extension;
  switch (type) {
    case 'jpeg':
    case 'png':
    case 'jpg':
    case 'image':
      return 'image';
    case 'mp4':
    case 'video':
      return 'video';
    case 'pdf':
      return type;
    case 'link':
      return type;
    case 'topic':
      return `I-FIKR-${type}`;
    case 'youtube':
      return type;
    case 'vimeo':
      return type;
    case 'epub':
      return type;
  }
};

const AttachmentsFinalCell = ({ item, disabled, onDelete, makeFirst }) => {
  const openDetails = useDetails();

  const _onDelete = () => {
    onDelete && onDelete(item);
  };

  const view = async () => {
    const url = item?.url;
    const type = item?.type || item?.extension;
    if (url) {
      WebBrowser.openBrowserAsync(item?.url);
    } else if (type === 'topic') {
      const topic = await getTopicById(item?.id);
      const errorCode = getErrorCode(topic);
      if (errorCode) {
        ErrorAlert(`Something went wrong. Details: ${topic?.error?.message || errorCode}`);
        return;
      }
      if (topic) {
        openDetails(topic);
      } else {
        ErrorAlert('Something went wrong. Topic may be invalid or closed.');
      }
    }
  };

  const tag = getTag(item)?.toUpperCase() || 'ATTACHMENT';

  const _makeFirst = () => makeFirst(item);

  return (
    <CellRow>
      <AttachementsFinalLabelContainer>
        <AttachementsFinalLabel
          title={`${tag} - Caption: ${item.caption || 'none'}${
            item?.type === 'topic' || item?.type === 'epub' ? ` - ID: ${item?.id}` : ` - URL: ${item?.url}`
          }`}
        />
      </AttachementsFinalLabelContainer>
      <CellButton backgroundColor={BLUE} onPress={view}>
        <SolidButtonLabel title={'VIEW'} />
      </CellButton>
      {!disabled && (
        <>
          <CellButton backgroundColor={BLUE} onPress={_makeFirst}>
            <SolidButtonLabel title={'MAKE FIRST'} />
          </CellButton>
          <CellButton backgroundColor={RED} onPress={_onDelete}>
            <SolidButtonLabel title={'DELETE'} />
          </CellButton>
        </>
      )}
    </CellRow>
  );
};

export default AttachmentsFinalCell;
