import React from 'react';
import TextEditor from '../text-editor';
import { DetailedInputLabel } from './input/styled';
import { CellContainer } from './styled';

const RichTextEditorCell = ({ initial, setValue, title = 'Content', disabled = false, placeholder = '' }) => {
  return (
    <CellContainer>
      <DetailedInputLabel title={title.toUpperCase()} />
      <TextEditor initial={initial} disabled={disabled} placeholder={placeholder} setValue={setValue} />
    </CellContainer>
  );
};

export default RichTextEditorCell;
