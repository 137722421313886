import React, { useState } from 'react';
import { SolidButtonLabel } from '../../../../../../containers/labels';
import useDocumentPicker from '../../../../../../hooks/useDocumentPicker';
import { SimpleAlert } from '../../../../../../utils/alert';
import { getErrorCode } from '../../../../../../utils/error-json';
import { ATTACHMENT_BUTTON_WIDTH } from '../../../../../defines';
import DetailedInputRaw from '../../input/detailed-input-raw';
import { CellButton, CellRow } from '../../styled';
import { AttachmentType } from '../attachments-cell';

const AttachmentsAddEpub = ({ afterFilePick, disabled, shouldStartProcess }) => {
  const [caption, setCaption] = useState('');

  const { pickEpub } = useDocumentPicker();

  const onUploadPress = async () => {
    if (!shouldStartProcess()) {
      return;
    }
    const result = await pickEpub();
    if (!result) {
      return;
    }
    const errorCode = getErrorCode(result);
    if (errorCode) {
      const message = result?.error?.message;
      SimpleAlert('Choose Epub', `Something went wrong. Details: ${message || errorCode}`);
      return;
    }

    afterFilePick(result, caption, AttachmentType.EPUB);
    setCaption('');
  };

  return (
    <CellRow>
      <DetailedInputRaw value={caption} setValue={setCaption} placeholder={'Enter epub caption (optional)'} />
      <CellButton disabled={disabled} buttonWidth={ATTACHMENT_BUTTON_WIDTH} onPress={() => onUploadPress()}>
        <SolidButtonLabel title={'UPLOAD EPUB'} />
      </CellButton>
    </CellRow>
  );
};

export default AttachmentsAddEpub;
