import apiCall from './index';
import { getErrorCode } from '../../utils/error-json';

export const ERRORS = {
  failedToFetchBenefactors: 'failedToFetchBenefactors',
  failedToAddUserToBenefactor: 'failedToAddUserToBenefactor',
  failedToUpdateBenefactor: 'failedToUpdateBenefactor',
  failedToCreateBenefactor: 'failedToCreateBenefactor',
  failedToRemoveUserFromBenefactor: 'failedToRemoveUserFromBenefactor',
};

export const createBenefactor = async (title) => {
  try {
    const data = await apiCall({
      url: '/m/benefactors/create.json',
      bodyParams: { title },
      method: 'POST',
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return data;
    }

    return data;
  } catch {
    throw ERRORS.failedToCreateBenefactor;
  }
};

export const updateBenefactor = async (benefactorId, name, description, canPost, canPostVideos) => {
  try {
    const result = await apiCall({
      url: '/m/benefactors/update.json',
      bodyParams: {
        topicId: benefactorId,
        title: name,
        description,
        canPost: canPost?.toString(),
        canPostVideos: canPostVideos?.toString(),
      },
      method: 'POST',
    });
    const errorCode = getErrorCode(result);
    if (errorCode) {
      return result;
    }

    return result?.data;
  } catch {
    throw ERRORS.failedToUpdateBenefactor;
  }
};

export const addUserToBenefactor = async (benefactorId, username, expirationDate) => {
  try {
    const result = await apiCall({
      url: '/m/benefactors/add-user.json',
      bodyParams: { topicId: benefactorId, username, expirationDate },
      method: 'POST',
    });
    const errorCode = getErrorCode(result);
    if (errorCode) {
      return result;
    }

    return result?.data;
  } catch {
    throw ERRORS.failedToAddUserToBenefactor;
  }
};

export const removeUserFromBenefactor = async (benefactorId, username) => {
  try {
    const result = await apiCall({
      url: '/m/benefactors/remove-user.json',
      bodyParams: { topicId: benefactorId, username },
      method: 'POST',
    });
    const errorCode = getErrorCode(result);
    if (errorCode) {
      return result;
    }

    return result?.data;
  } catch {
    throw ERRORS.failedToRemoveUserFromBenefactor;
  }
};

export const getBenefactors = async (includeUsers) => {
  try {
    const result = await apiCall({
      url: '/m/benefactors/all.json',
      method: 'POST',
      bodyParams: { includeUsers },
    });
    const errorCode = getErrorCode(result);
    if (errorCode) {
      return [];
    }
    return result?.data || [];
  } catch (error) {
    console.log(`Error getBenefactors. Details: ${error}`);
    return undefined;
  }
};

export const getBenefactorDetails = async (benefactorId) => {
  try {
    const result = await apiCall({
      url: '/m/benefactor.json',
      method: 'POST',
      bodyParams: { topicId: benefactorId },
    });
    const errorCode = getErrorCode(result);
    if (errorCode) {
      return undefined;
    }
    return result?.data;
  } catch (error) {
    console.log(`Error getBenefactorDetails. Details: ${error}`);
    return undefined;
  }
};

export const removeBenefactor = async (benefactorId) => {
  try {
    const result = await apiCall({
      url: '/m/benefactors/delete.json',
      method: 'POST',
      bodyParams: { topicId: benefactorId },
    });
    const errorCode = getErrorCode(result);
    if (errorCode) {
      return result;
    }
    return result?.data;
  } catch (error) {
    console.log(`Error removeBenefactor. Details: ${error}`);
    return undefined;
  }
};
