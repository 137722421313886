import React, { useCallback, useState } from 'react';
import { GraySeparator, InvisibleSeparator } from '../../../../../containers/views/Separators';
import { DetailedInput, DetailedInputRawContainer, SeparatorContainer, styles } from './styled';

const DetailedInputRaw = ({
  value,
  setValue = (text) => {
    console.log('Text change not mapped');
  },
  placeholder = '',
  focusOnOpen = false,
  multiline = false,
  scrollRef,
  shouldChangeText,
  disabled = false,
  addMarginLeft = false,
}) => {
  const [height, setHeight] = useState(0);

  const [selected, setSelected] = useState(false);

  const onFocus = useCallback(() => {
    if (disabled) {
      return;
    }
    scrollRef?.current?.scrollToEnd();
    setSelected(true);
  }, []);

  const onBlur = useCallback(() => {
    if (disabled) {
      return;
    }
    setSelected(false);
  }, []);

  const _onChangeText = async (text) => {
    if (disabled) {
      return;
    }
    if (shouldChangeText) {
      const processed = await shouldChangeText(text);
      if (processed) {
        setValue(processed);
      } else {
        setValue('');
      }
    } else {
      setValue(text);
    }
  };

  const onContentSizeChange = (event) => {
    if (multiline) {
      setHeight(event?.nativeEvent?.contentSize?.height || 0);
    }
  };

  return (
    <DetailedInputRawContainer addMarginLeft={addMarginLeft}>
      <DetailedInput
        height={height}
        editable={!disabled}
        value={value}
        onChangeText={_onChangeText}
        multiline={multiline}
        placeholderStyle={styles.placeholder}
        numberOfLines={0}
        onContentSizeChange={onContentSizeChange}
        onFocus={onFocus}
        autoFocus={focusOnOpen}
        selected={selected}
        onBlur={onBlur}
        clearButtonMode={'never'}
        placeholder={placeholder}
      />
      {!multiline && <SeparatorContainer>{selected ? <InvisibleSeparator /> : <GraySeparator />}</SeparatorContainer>}
    </DetailedInputRawContainer>
  );
};

export default DetailedInputRaw;
