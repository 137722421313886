import { errorJSON, getErrorCode } from '../../utils/error-json';
import apiCall from './index';
import { processPost } from './utils';

export const ERRORS = {
  hiddenButNoPost: 'hiddenButNoPost',
  unhiddenButNoPost: 'unhiddenButNoPost',
};

export const hidePost = async (post_id, reason) => {
  try {
    const data = await apiCall({
      url: '/m/hide-post.json',
      bodyParams: {
        post_id,
        reason,
      },
      method: 'POST',
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return data;
    }
    const post = processPost(data, true) || data;
    if (post !== undefined) {
      return post;
    }
    if (data?.id) {
      return errorJSON(ERRORS.hiddenButNoPost);
    }
    return undefined;
  } catch {
    throw ERRORS.failedToFetchUser;
  }
};

export const unhidePost = async (post_id, resolution) => {
  try {
    const data = await apiCall({
      url: '/m/unhide-post.json',
      bodyParams: {
        post_id,
        resolution,
      },
      method: 'POST',
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return data;
    }
    const post = processPost(data, true) || data;
    if (post !== undefined) {
      return post;
    }
    if (data?.id) {
      return errorJSON(ERRORS.unhiddenButNoPost);
    }
    return undefined;
  } catch {
    throw ERRORS.failedToFetchUser;
  }
};
