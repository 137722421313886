import { useNavigation } from '@react-navigation/native';
import { categoryTypes } from '../networking/calls';

export const getRouteFromSlug = (slug) => {
  let route;
  switch (slug) {
    case categoryTypes.news.slug:
      route = 'News Post';
      break;

    case categoryTypes.posts.slug:
      route = 'User Post';
      break;

    case categoryTypes.fatwas.slug:
      route = 'Fatwa';
      break;

    case categoryTypes.glossary.slug:
      route = 'Glossary';
      break;

    case categoryTypes.excerpts.slug:
      route = 'Excerpt';
      break;

    case categoryTypes.fiqh.slug:
      route = 'Fiqh';
      break;

    case categoryTypes.questions.slug:
      route = 'Question';
      break;

    case categoryTypes.books.slug:
      route = 'Book';
      break;

    case categoryTypes.researchPapers.slug:
      route = 'Research Paper';
      break;

    case categoryTypes.ifikrDigests.slug:
      route = 'I-FIKR Digest';
      break;

    case categoryTypes.articles.slug:
      route = 'Article';
      break;

    case categoryTypes.events.slug:
      route = 'Event';
      break;
  }
  return route;
};

const useDetails = () => {
  const navigation = useNavigation();

  const openDetails = (item) => {
    const id = item?.id;
    if (!id) {
      console.log('Cannot open details for undefined item');
      return;
    }

    if (item?.username) {
      navigation.push('User', { item });
      return;
    }

    if (item?.topicId) {
      navigation.push('Answer', { item });
      return;
    }

    const slug = item?.category?.slug;
    if (slug) {
      const route = getRouteFromSlug(slug);
      if (route) {
        navigation.push(route, { item });
        return;
      }
      console.log(`Cannot open details for id: ${id}, slug: ${slug}`);
      return;
    }

    if (item?.name && item?.userData) {
      navigation.push('Benefactor', { item });
      return;
    }

    console.log(`Cannot open details for id: ${id}`);
  };

  return openDetails;
};

export default useDetails;
