import { createStore, applyMiddleware, compose } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { IS_EXPO_DEV } from '../Constants';
import rootReducer from './reducers';

function configureStore(initialState) {
  const middleWareEnhancer = applyMiddleware(...[promise, thunk]);
  const devTools = devToolsEnhancer({});
  const composedEnhancers = compose(middleWareEnhancer, devTools);

  const store = createStore(rootReducer, initialState, composedEnhancers);
  if (module.hot && IS_EXPO_DEV) {
    module.hot.accept(() => {
      console.log('Hot reload callback');
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}

const uniqueStore = configureStore();

export default uniqueStore;
