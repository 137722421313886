import { useNavigation } from '@react-navigation/native';
import React from 'react';
import styled from 'styled-components/native';
import { BLUE, GREEN, RED, TEXT_BLACK } from '../../../assets/colors';
import useReduxState from '../../store/genericSet';
import { ButtonLabel } from '../labels';
import PlusButtonContent from './partials/PlusButtonContent';
import UserAvatar, { USER_AVATER_SIZE } from '../images/UserAvatar';
import ProjectSmallLogo from '../../../assets/logo/logo-small.svg';
import BackIcon from '../../../assets/icons/icon-back.svg';
import SettingsIcon from '../../../assets/icons/icon-settings.svg';
import {
  NavigationBackItemSimple,
  NavigationBackItemTouchable,
  NavigationBarItemSimple,
  NavigationBarItemTouchable,
} from './utils';

export const UserProfileButton = () => {
  const navigation = useNavigation();
  const [currentUser] = useReduxState('auth', 'currentUser');

  const onPress = () => navigation.navigate('Profile');

  return (
    <NavigationBarItemTouchable onPress={onPress}>
      <UserAvatar size={USER_AVATER_SIZE.small} uri={currentUser?.imageUrl} />
    </NavigationBarItemTouchable>
  );
};

export const PlusButton = ({ title, onPress }) => {
  return (
    <NavigationBarItemTouchable onPress={onPress}>
      <PlusButtonContent title={title} />
    </NavigationBarItemTouchable>
  );
};

export const ProjectLogo = () => <ProjectSmallLogo />;

export const ProjectBackImage = () => (
  <NavigationBackItemSimple>
    <BackIcon />
  </NavigationBackItemSimple>
);

export const ProjectSettingsButton = () => {
  const navigation = useNavigation();
  const onPress = () => navigation.navigate('Settings');

  return (
    <NavigationBarItemTouchable onPress={onPress}>
      <SettingsIcon />
    </NavigationBarItemTouchable>
  );
};

export const ProjectBackButton = ({ onPress }) => (
  <NavigationBackItemTouchable onPress={onPress}>
    <BackIcon />
  </NavigationBackItemTouchable>
);

export const ProjectNavBackButton = ({ onPress }) => (
  <NavigationBarItemTouchable onPress={onPress}>
    <BackIcon />
  </NavigationBarItemTouchable>
);

const NavTextLabel = styled(ButtonLabel)`
  color: ${TEXT_BLACK};
`;

const NavRedTextLabel = styled(ButtonLabel)`
  color: ${RED};
`;

const NavGreenTextLabel = styled(ButtonLabel)`
  color: ${GREEN};
`;

export const ProjectNavTextButton = ({ title, onPress }) => (
  <NavigationBarItemTouchable onPress={onPress}>
    <NavTextLabel title={title} />
  </NavigationBarItemTouchable>
);

export const ProjectNavRedText = ({ title }) => (
  <NavigationBarItemSimple>
    <NavRedTextLabel title={title} />
  </NavigationBarItemSimple>
);

export const ProjectNavGreenText = ({ title }) => (
  <NavigationBarItemSimple>
    <NavGreenTextLabel title={title} />
  </NavigationBarItemSimple>
);

const NavBlueLabel = styled(ButtonLabel)`
  color: ${BLUE};
`;

export const ProjectNavBlueButton = ({ title, onPress }) => (
  <NavigationBarItemTouchable onPress={onPress}>
    <NavBlueLabel title={title} />
  </NavigationBarItemTouchable>
);
