import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import timezonesWithData from '../../../timezones-with-data';
import { ProjectPicker } from '../../../ui/app/shared/form-cells/styled';
import { SimpleAlert } from '../../../utils/alert';

const initialIndex = (initial) => timezonesWithData.findIndex((t) => t.name === initial);

export const TimezonePicker = ({ initial, setValue }) => {
  const [timezone, setTimezone] = useState(0);

  useEffect(() => {
    const index = initial && initial?.length > 0 && initialIndex(initial);
    if (index && index > 0) {
      setTimezone(index);
    } else {
      const defaultIndex = initialIndex('Asia/Kuala_Lumpur');
      if (defaultIndex > 0) {
        setTimezone(defaultIndex);
      } else {
        setTimezone(270);
      }
      if (initial?.length > 0) {
        SimpleAlert('Invalid Timezone', 'Failed to find set timezone. Setting timezone to Kuala Lumpur, Malaysia.');
      }
    }
  }, []);

  useEffect(() => {
    if (timezone !== undefined) {
      const name = get(timezonesWithData, `${timezone}.name`);
      setValue && setValue(name);
    }
  }, [timezone]);

  return (
    <ProjectPicker onValueChange={(v) => setTimezone(v)} selectedValue={timezone}>
      {timezonesWithData.map((v, i) => {
        const text = `${v.formatted} [UTC${v.utcOffsetStr || ''}]`;
        return <ProjectPicker.Item key={`${i}`} label={text} value={i} />;
      })}
    </ProjectPicker>
  );
};
