import React from 'react';
import {
  BenefactorCellButtonWrapper,
  BenefactorCellInfo,
  BenefactorUsersCountLabel,
  CellAdditionalGreenLabel,
  CellAdditionalLabel,
  CellAdditionalRedLabel,
  CellContent,
  CellImage,
  CellMainLabel,
  CellRowContent,
} from './styled';
import Placeholder from '../../../../../assets/placeholders/placeholder-generic-square.png';
import useDetails from '../../../../hooks/useDetails';
import { SolidButton } from '../../../../containers/buttons';
import { RED } from '../../../../../assets/colors';
import { SolidButtonLabel } from '../../../../containers/labels';

const BenefactorCell = ({ benefactor, buttonPress, buttonTitle }) => {
  const openDetails = useDetails();
  const _openDetails = () => openDetails(benefactor);

  return (
    <CellContent onPress={_openDetails}>
      <CellImage placeholder={Placeholder} source={{ uri: undefined }} />
      <CellRowContent>
        <CellMainLabel numberOfLines={3} text={benefactor?.name} />
      </CellRowContent>
      <BenefactorCellInfo>
        {benefactor?.canPost ? (
          <CellAdditionalGreenLabel numberOfLines={1} text={'CAN POST: YES'} />
        ) : (
          <CellAdditionalRedLabel numberOfLines={1} text={'CAN POST: NO'} />
        )}
        {benefactor?.canPostVideos ? (
          <CellAdditionalGreenLabel numberOfLines={1} text={'CAN POST VIDEOS: YES'} />
        ) : (
          <CellAdditionalRedLabel numberOfLines={1} text={'CAN POST VIDEOS: NO'} />
        )}
        {typeof benefactor?.usersCount === 'number' && (
          <BenefactorUsersCountLabel numberOfLines={1} text={`USERS: ${benefactor?.usersCount}`} />
        )}
      </BenefactorCellInfo>
      {buttonPress && buttonTitle && (
        <BenefactorCellButtonWrapper>
          <SolidButton disabled={false} onPress={buttonPress} backgroundColor={RED}>
            <SolidButtonLabel title={buttonTitle} />
          </SolidButton>
        </BenefactorCellButtonWrapper>
      )}
    </CellContent>
  );
};

export default BenefactorCell;
