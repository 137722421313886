import DrawerContentScrollView from '@react-navigation/drawer/src/views/DrawerContentScrollView';
import DrawerItem from '@react-navigation/drawer/src/views/DrawerItem';
import DrawerItemList from '@react-navigation/drawer/src/views/DrawerItemList';
import React from 'react';
import { RED, TINT_RED } from '../../../assets/colors';
import Fonts from '../../../assets/fonts';
import LogoMedium from '../../../assets/logo/logo-medium.svg';
import { FORMATTED_VERSION } from '../../Constants';
import { DrawerFooter, DrawerHeader, DrawerVersionLabel } from '../../ui/styled';
import { ConfirmationAlert } from '../../utils/alert';
import * as WebBrowser from 'expo-web-browser';
import { ANALYTICS_DATA_STUDIO_URL } from '../../ui/defines';

export const drawerLabelStyle = { fontFamily: Fonts.bold };

export const drawerLogOutItemStyle = { width: '100' };
export const drawerLogOutLabelStyle = { fontFamily: Fonts.bold, color: RED };

export const DrawerContent = ({ logout, currentUser, ...props }) => {
  return (
    <DrawerContentScrollView {...props}>
      <DrawerHeader>
        <LogoMedium />
      </DrawerHeader>
      <DrawerItemList labelStyle={drawerLabelStyle} {...props} />
      <DrawerItem
        labelStyle={drawerLabelStyle}
        label='Analytics'
        onPress={() =>
          WebBrowser.openBrowserAsync(ANALYTICS_DATA_STUDIO_URL, {
            windowFeatures: { popup: 0, width: 1000, height: 1000 },
          }).then()
        }
      />
      <DrawerItem
        style={drawerLogOutItemStyle}
        labelStyle={drawerLogOutLabelStyle}
        label='Log out'
        onPress={() => ConfirmationAlert('Confirmation', 'Are you sure you want to log out?', logout)}
      />
      <DrawerFooter>
        {currentUser && <DrawerVersionLabel title={`Logged in as @${currentUser?.username || 'unknown'}`} />}
        <DrawerVersionLabel title={FORMATTED_VERSION} />
      </DrawerFooter>
    </DrawerContentScrollView>
  );
};
