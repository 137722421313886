import React from 'react';
import DetailedInputCell from '../form-cells/input/detailed-input-cell';
import SearchList from './search-list';
import useSearch from '../../../../hooks/useSearch';

const SearchComponent = ({ itemType, types, title, placeholder }) => {
  const { results, setResults, searchText, loading, setSearchText, noFiltersRef } = useSearch({ itemType, types });

  return (
    <>
      <DetailedInputCell
        loading={loading}
        title={title}
        value={searchText}
        setValue={setSearchText}
        placeholder={placeholder}
      />
      <SearchList
        loading={loading}
        searchText={searchText}
        results={results}
        setResults={setResults}
        noFiltersRef={noFiltersRef}
        itemType={itemType}
      />
    </>
  );
};

export default SearchComponent;
