import { StyleSheet } from 'react-native';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../utils/DeviceDimensions';

const shadowOffset = 2;
const shadowOpacity = 0.5;

const GenericStyles = StyleSheet.create({
  noPadding: { padding: 0 },
  noMargin: { margin: 0 },
  fullScreenSize: { height: SCREEN_HEIGHT, width: SCREEN_WIDTH },
  justFlex: { flex: 1 },
  justAbsolute: { position: 'absolute' },
  justRelative: { position: 'relative' },
  absoluteCenter: {
    alignSelf: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  absoluteFill: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  stackVertically: { flexDirection: 'column' },
  stackHorizontally: { flexDirection: 'row' },
  forceTextContainerToRespectBounds: { width: undefined, flex: 1, flexGrow: 1 },
  forceImageToCalculateWidthAndHeightBasedOnBounds: { width: undefined, height: undefined },
  backgroundCoverImage: { flex: 1, alignSelf: 'stretch', width: undefined, height: undefined, resizeMode: 'cover' },
  backgroundContainImage: { flex: 1, alignSelf: 'stretch', width: undefined, height: undefined, resizeMode: 'contain' },
  zeroWidth: { width: 0 },
  zeroHeight: { height: 0 },
  zeroSize: { width: 0, height: 0 },
  bottomShadow: {
    shadowOffset: { width: 0, height: shadowOffset / 2 },
    shadowRadius: shadowOffset,
    shadowOpacity: shadowOpacity,
    elevation: 2,
  },
  topShadow: {
    shadowOffset: { width: 0, height: -shadowOffset / 2 },
    shadowRadius: shadowOffset,
    shadowOpacity: shadowOpacity,
    elevation: 2,
  },
  centerShadow: {
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: shadowOffset,
    shadowOpacity: shadowOpacity,
    elevation: 2,
  },
});

export default GenericStyles;
