import { errorJSON, getErrorCode } from '../../utils/error-json';
import { getUserApiKey } from '../index';
import apiCall from './index';
import { processUser } from './utils';

export const ERRORS = {
  failedToFetchCurrentUser: 'failedToFetchCurrentUser',
  failedToFetchUser: 'failedToFetchUser',
  suspendedButNoUser: 'suspendedButNoUser',
  unsuspendedButNoUser: 'unsuspendedButNoUser',
};

export const fetchCurrentUser = async () => {
  const userApiKey = await getUserApiKey();
  if (!userApiKey) {
    return undefined;
  }
  return fetchUser(undefined);
};

export const fetchUser = async (user_id) => {
  try {
    const data = await apiCall({
      url: '/m/user-by-admin.json',
      queryParams: { user_id },
      method: 'GET',
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return undefined;
    }
    return processUser(data, true);
  } catch {
    throw ERRORS.failedToFetchUser;
  }
};

export const deleteUserById = async (user_id) => {
  try {
    const data = await apiCall({
      url: `/admin/users/${user_id}.json`,
      bodyParams: { delete_posts: true },
      method: 'DELETE',
    });
    if (data?.deleted === true) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

export const suspendUser = async (user_id, reason) => {
  try {
    const data = await apiCall({
      url: '/m/user-suspend.json',
      bodyParams: {
        user_id,
        reason,
      },
      method: 'POST',
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return data;
    }
    const user = processUser(data, true) || data;
    if (user !== undefined) {
      return user;
    }
    if (data?.id) {
      return errorJSON(ERRORS.suspendedButNoUser);
    }
    return undefined;
  } catch {
    throw ERRORS.failedToFetchUser;
  }
};

export const unsuspendUser = async (user_id, resolution) => {
  try {
    const data = await apiCall({
      url: '/m/user-unsuspend.json',
      bodyParams: {
        user_id,
        resolution,
      },
      method: 'POST',
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return data;
    }
    const user = processUser(data, true) || data;
    if (user !== undefined) {
      return user;
    }
    if (data?.id) {
      return errorJSON(ERRORS.suspendedButNoUser);
    }
    return undefined;
  } catch {
    throw ERRORS.failedToFetchUser;
  }
};
