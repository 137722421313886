import { SET_ACTION_PREFIX } from './constants';

export const setActionReducer = (oldState = {}, action, reducerKey) => {
  const innerType = action && action.reducerKey === reducerKey && action.type.replace(SET_ACTION_PREFIX, '');
  return innerType && innerType !== action.type
    ? {
        ...oldState,
        [innerType]: action.payload,
      }
    : oldState;
};
