export const GENERIC_BLACK = '#000000';
export const GENERIC_WHITE = '#FFFFFF';
export const TRANSPARENT = '#00000000';

export const GREEN = '#67AD31';
export const BLUE = '#1D98CE';
export const GRAY = '#AAAAAA';
export const YELLOW = '#DFBE12';
export const RED = '#EE2A28';
export const TINT_RED = '#EE2A2811';

export const TEXT_BLACK = '#282828';
export const TEXT_DARK_GRAY = '#787878';
export const TEXT_LIGHT_GRAY = GRAY;

export const TAB_BAR_BACKGROUND = '#EEFAFF';

export const BACKGROUND = '#FEFEFE';

export const SEPARATOR_WHITE = '#F5F5F5';
export const SHADOW_GRAY = 'rgba(0, 0, 0, 0.1)';
export const NAV_BAR_SHADOW = '#f8f8f8';
