import styled from 'styled-components/native';
import React from 'react';
import { BLUE, GENERIC_WHITE, GREEN } from '../../../assets/colors';
import { AppCSS } from '../index';
import BlueArrowIcon from '../../../assets/icons/icon-arrow-blue.svg';
import WhiteArrowIcon from '../../../assets/icons/icon-arrow-white.svg';
import DarkGrayArrowIcon from '../../../assets/icons/icon-arrow-dark-gray.svg';
import CloseIcon from '../../../assets/icons/icon-close-blue.svg';
import BackIcon from '../../../assets/icons/icon-back.svg';
import { ButtonLabel } from '../labels';

export const SolidButton = styled.TouchableOpacity`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding-horizontal: 8px;
  background-color: ${(props) => `${props.backgroundColor || GREEN}`};
  height: ${(props) => `${props.height || 34}px`};
  ${AppCSS.borderRadius};
`;

const RouteButtonContent = styled.TouchableOpacity`
  align-items: center;
  flex-direction: row;
  margin-right: auto;
  height: ${(props) => `${props.height || 24}px`};
`;

export const RouteButton = ({ children, ...rest }) => {
  return (
    <RouteButtonContent {...rest}>
      {children !== undefined && children}
      <BlueArrowIcon />
    </RouteButtonContent>
  );
};

const IconButtonContent = styled.TouchableOpacity`
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = ({ children, ...rest }) => {
  return (
    <IconButtonContent {...rest}>
      <CloseIcon />
    </IconButtonContent>
  );
};

export const BackButton = ({ children, ...rest }) => {
  return (
    <IconButtonContent {...rest}>
      <BackIcon />
    </IconButtonContent>
  );
};

export const ArrowButton = ({ children, ...rest }) => {
  return (
    <IconButtonContent {...rest}>
      <WhiteArrowIcon />
    </IconButtonContent>
  );
};

export const OptionalRouteButton = ({ children, ...rest }) => {
  return (
    <RouteButtonContent {...rest}>
      {children !== undefined && children}
      <DarkGrayArrowIcon />
    </RouteButtonContent>
  );
};

const ToggleButtonContent = styled.TouchableOpacity`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-width: 1px;
  ${(props) => (props.selected === true ? `background-color: ${BLUE};` : '')};
  border-color: ${BLUE};
  height: ${(props) => `${props.height || 28}px`};
  ${AppCSS.borderRadius};
  margin-right: 4px;
`;

const ToggleButtonLabel = styled(ButtonLabel)`
  padding-horizontal: 6px;
  color: ${(props) => (props.color !== undefined ? props.color : GENERIC_WHITE)};
`;

export const ToggleButton = ({ selected, title, ...rest }) => {
  const textColor = selected ? GENERIC_WHITE : BLUE;
  return (
    <ToggleButtonContent {...rest} selected={selected}>
      <ToggleButtonLabel title={title} color={textColor} />
    </ToggleButtonContent>
  );
};
