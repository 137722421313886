import React, { useEffect, useState } from 'react';
import WebScreenContainer from '../../../containers/views/WebScreenContainer';
import DetailedInputCell from '../shared/form-cells/input/detailed-input-cell';
import { MiddleBlueSeparatorWithPadding } from '../../../containers/views/Separators';
import { getErrorCode } from '../../../utils/error-json';
import { ConfirmationAlert, ErrorAlert, SuccessAlert } from '../../../utils/alert';
import { createBenefactor, removeBenefactor } from '../../../networking/calls/benefactors';
import ContentList, { CONTENT_TYPE } from '../shared/content-list';
import { useIsFocused } from '@react-navigation/native';

const Benefactors = () => {
  const [benefactorName, setBenefactorName] = useState('');
  const [refreshTrigger, setRefreshTrigger] = useState(new Date().toISOString());

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      setRefreshTrigger(new Date().toISOString());
    }
  }, [isFocused]);

  const onCreateBenefactor = async () => {
    try {
      const _benefactorName = benefactorName ? benefactorName?.trim() : '';
      if (_benefactorName?.length === 0) {
        ErrorAlert('Please enter a valid benefactor name and try again.');
        return;
      }
      const newBenefactor = await createBenefactor(benefactorName);
      const errorCode = getErrorCode(newBenefactor);
      if (errorCode || !newBenefactor) {
        if (!newBenefactor) {
          ErrorAlert('Failed to create benefactor. Details: unknown');
        } else {
          ErrorAlert(newBenefactor?.error?.message || errorCode);
        }
      } else {
        setBenefactorName('');
        SuccessAlert('Benefactor was created successfully.');
        setRefreshTrigger(new Date().toISOString());
      }
    } catch (error) {
      ErrorAlert(`Failed to create benefactor. Details: ${error}`);
    }
  };

  const removeBenefactorAction = async (itemId) => {
    try {
      const result = await removeBenefactor(itemId);
      const errorCode = getErrorCode(result);
      if (errorCode) {
        ErrorAlert(result?.error?.message || errorCode);
      } else {
        SuccessAlert('Benefactor was removed successfully.');
        setRefreshTrigger(new Date().toISOString());
      }
    } catch (error) {
      ErrorAlert(`Failed to delete benefactor. Details: ${error}`);
    }
  };

  const onRemoveBenefactor = async (itemId) => {
    ConfirmationAlert('Confirmation', 'Are you sure you want to remove the benefactor?', async () =>
      removeBenefactorAction(itemId),
    );
  };

  return (
    <WebScreenContainer>
      <DetailedInputCell
        value={benefactorName}
        setValue={setBenefactorName}
        title={'Create benefactor'}
        placeholder={'Enter benefactor name'}
        buttonPress={onCreateBenefactor}
        buttonTitle={'Create benefactor'}
      />
      <MiddleBlueSeparatorWithPadding />
      <ContentList
        contentType={CONTENT_TYPE.benefactor}
        refreshTrigger={refreshTrigger}
        buttonPress={onRemoveBenefactor}
        buttonTitle={'Remove'}
      />
    </WebScreenContainer>
  );
};

export default Benefactors;
