import React, { useCallback } from 'react';
import { WhiteSeparatorWithPadding } from '../../../../containers/views/Separators';
import useDetails from '../../../../hooks/useDetails';
import { categoryTypes } from '../../../../networking/calls';
import {
  CellMainLabel,
  CellAdditionalLabel,
  CellAdditionalRedLabel,
  CellContent,
  CellImage,
  CellRowContent,
} from './styled';

const TopicCell = ({ item, disabled }) => {
  const openDetails = useDetails();
  const _openDetails = () => openDetails(item);

  const ignoreTitle =
    item?.category?.slug === categoryTypes.questions.slug || item?.category?.slug === categoryTypes.posts.slug;

  const user = `${item?.firstPost?.user?.fullName} | @${item?.firstPost?.user?.username || '-'}`;

  return (
    <CellContent disabled={disabled} onPress={_openDetails}>
      <CellImage source={{ uri: item?.firstPost?.imageUrl }} />
      <CellRowContent>
        {!ignoreTitle && (
          <>
            <CellMainLabel numberOfLines={3} text={item?.topic?.title} />
            <CellAdditionalLabel numberOfLines={1} text={item?.firstPost?.summary} />
          </>
        )}
        {ignoreTitle && (
          <>
            <CellMainLabel numberOfLines={1} text={item?.firstPost?.summary} />
          </>
        )}
        <WhiteSeparatorWithPadding />
        <CellAdditionalLabel numberOfLines={1} text={'ID: ' + item?.topic?.id} />
        <CellAdditionalLabel numberOfLines={1} text={'CREATOR: ' + user} />
        <CellAdditionalLabel numberOfLines={1} text={'LAST UPDATED ON: ' + item?.firstPost?.updatedDateString} />
        {item?.topic?.closed && <CellAdditionalRedLabel numberOfLines={1} text={'CLOSED TOPIC'} />}
      </CellRowContent>
    </CellContent>
  );
};

export default TopicCell;
