import React, { useState } from 'react';
import RichTextEditor from 'react-rte';
import { SimpleAlert } from '../../../../utils/alert';
import { TextEditorContainer, style } from './styled';

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
};

const TextEditor = ({ placeholder, disabled = false, initial = '', setValue }) => {
  const [data, setData] = useState(RichTextEditor.createValueFromString(initial, 'markdown'));

  const onChange = (v) => {
    const text = v.toString('markdown');

    setData(v);
    setValue && setValue(text);
  };

  return (
    <TextEditorContainer>
      <RichTextEditor
        readOnly={disabled}
        editorStyle={style}
        toolbarConfig={toolbarConfig}
        placeholder={placeholder}
        value={data}
        onChange={onChange}
      />
    </TextEditorContainer>
  );
};

export default TextEditor;
