import React from 'react';
import { DetailedInputLabel } from './input/styled';
import DateTimePicker from '../../../../containers/views/date-time-picker';
import { CellContainer } from './styled';

export const DateTimeCell = ({ title = 'Date', initial, setValue }) => {
  return (
    <CellContainer>
      <DetailedInputLabel title={(title || '').toUpperCase()} />
      <DateTimePicker {...{ initial, setValue }} />
    </CellContainer>
  );
};
