import React from 'react';
import styled from 'styled-components/native';
import { AppCSS } from '../index';

export const USER_AVATER_SIZE = {
  verySmall: 18,
  small: 24,
  medium: 32,
  big: 90,
};

const processSize = (size) => {
  switch (size) {
    case USER_AVATER_SIZE.medium:
    case USER_AVATER_SIZE.big:
    case USER_AVATER_SIZE.verySmall:
      return size;
    default:
      return USER_AVATER_SIZE.small;
  }
};

const Avatar = styled.Image`
  ${AppCSS.borderRadius};
  resize-mode: cover;
  width: ${(props) => (props.size !== undefined ? `${props.size}px` : `${USER_AVATER_SIZE.small}px`)};
  height: ${(props) => (props.size !== undefined ? `${props.size}px` : `${USER_AVATER_SIZE.small}px`)};
`;

const UserAvatar = ({ size = USER_AVATER_SIZE.small, uri, ...rest }) => {
  const processedSize = processSize(size);
  return <Avatar source={{ uri: uri }} size={processedSize} {...rest} />;
};

export default UserAvatar;
