import React, { useEffect, useState } from 'react';
import { ProjectNavBlueButton } from '../../../containers/navigation/NavigationBarItem';
import WebScreenContainer from '../../../containers/views/WebScreenContainer';
import { ifikrContentArray } from '../../../networking/calls';
import FormCreateComponent from '../shared/form-cells';
import DisabledListComponent from '../shared/disabled-list';
import SearchComponent from '../shared/search';
import { ITEM_TYPE } from '../shared/search/search-list';
import { FormCreateButtonsContainer } from './styled';

const IFIKRContent = ({ navigation }) => {
  const [showClosedOnly, setShowClosedOnly] = useState(false);

  useEffect(() => {
    if (!showClosedOnly) {
      navigation.setOptions({
        headerRight: () => <ProjectNavBlueButton title={'CLOSED TOPICS'} onPress={() => setShowClosedOnly(true)} />,
      });
    } else {
      navigation.setOptions({
        headerRight: () => <ProjectNavBlueButton title={'SHOW SEARCH'} onPress={() => setShowClosedOnly(false)} />,
      });
    }
  }, [showClosedOnly]);

  return (
    <WebScreenContainer>
      <FormCreateButtonsContainer>
        {ifikrContentArray.map((t) => (
          <FormCreateComponent key={t.slug} categoryObject={t} />
        ))}
      </FormCreateButtonsContainer>
      {!showClosedOnly && (
        <SearchComponent
          itemType={ITEM_TYPE.topic}
          types={ifikrContentArray}
          title={'SEARCH I-FIKR CONTENT'}
          placeholder={'Enter an id, part of the title or a complete username (@username) to search for I-FIKR content'}
        />
      )}
      {showClosedOnly && (
        <>
          <DisabledListComponent itemType={ITEM_TYPE.topic} types={ifikrContentArray} />
        </>
      )}
    </WebScreenContainer>
  );
};

export default IFIKRContent;
