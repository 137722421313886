import styled from 'styled-components/native';

export const ButtonsContainer = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
  min-height: 34px;
`;

export const SearchListWrapper = styled.View`
  width: 100%;
  margin-top: 24px;
  max-height: 520px;
  overflow-y: auto;
`;
