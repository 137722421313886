import { AttachmentType } from '../../ui/app/shared/form-cells/attachments/attachments-cell';
import { getErrorCode } from '../../utils/error-json';
import apiCall from './index';

export const uploadFile = async (base64, extension, url) => {
  if (
    (!base64 && !url) ||
    (base64 && url) ||
    (extension !== AttachmentType.JPEG &&
      extension !== AttachmentType.PDF &&
      extension !== AttachmentType.MP4 &&
      extension !== AttachmentType.EPUB)
  ) {
    return false;
  }

  const data = await apiCall({
    url: '/m/upload.json',
    method: 'POST',
    bodyParams: {
      extension: extension,
      title: '',
      file: base64,
      url: url,
    },
  });

  const errorCode = getErrorCode(data);
  if (errorCode) {
    return data;
  }
  return data;
};
