import { IS_EXPO_DEV, PLATFORMS_OBJECT } from '../../Constants';
import {
  timeDistanceToDate,
  dateDisplayFromDates,
  parseDate,
  dateDisplayFromISO,
  dateTimeDisplayFromISO,
} from '../../utils/date-helpers';
import { categoryTypes } from './index';

export const processTopics = (rawTopics) => {
  return rawTopics.map((t) => processTopic(t)).filter((t) => t !== undefined);
};

export const processTopic = (t) => {
  try {
    const id = t?.id;
    if (id === undefined || id === null) {
      return undefined;
    }

    const category = t?.category;
    if (!category) {
      console.log(`TOPIC-IGNORED-${category.slug}`, id, 'Required category data missing');
      return undefined;
    }

    const firstPost = processPost(t?.firstPost);
    if (!firstPost || !t?.topic) {
      console.log(`TOPIC-IGNORED-${category.slug}`, id, 'Required topic or firstPost data missing');
      return undefined;
    }

    const categoryObject = Object.values(categoryTypes).find((x) => x.slug === category.slug);
    const shouldHaveMetadata = categoryObject?.shouldHaveMetadata === true;
    if (shouldHaveMetadata && (t?.object === null || t?.object === undefined)) {
      console.log(`TOPIC-IGNORED-${category.slug}`, id, t?.topic?.title, 'required metadata missing');
      return undefined;
    }

    let lastPost;
    if (category.slug === categoryTypes.questions.slug) {
      lastPost = processPost(t?.lastPost);
      if (t?.lastPost && !lastPost) {
        console.log(`TOPIC-IGNORED-${category.slug}`, id, 'Required lastPost data is invalid');
        return undefined;
      }
    }

    const isEvent = categoryTypes.events.slug === category.slug || categoryTypes.eventbrite.slug === category.slug;
    const timeDistance = timeDistanceToDate(isEvent ? t?.object?.dates.startLocal : t?.topic.createdAt);

    const dateString = isEvent
      ? dateDisplayFromDates(t?.object?.dates.startLocal, t?.object?.dates.endLocal)
      : dateDisplayFromDates(t?.topic.createdAt);

    const importedId = t?.imported?.id;
    const importedSortDate = dateTimeDisplayFromISO(t?.imported?.sortDate);

    const updatedTimeDistance = timeDistanceToDate(t?.topic?.updatedAt || t?.topic?.createdAt);
    const updatedDateString = dateDisplayFromDates(t?.topic?.updatedAt || t?.topic?.createdAt);

    const closedAtDateString = dateDisplayFromDates(t?.topic?.closedAt);

    if (!dateString || !timeDistance || !updatedTimeDistance || !updatedDateString) {
      console.log(`TOPIC-IGNORED-${category.slug}`, id, t?.topic?.title, 'Required dates data missing');
      return undefined;
    }

    return {
      ...t,
      importedId,
      importedSortDate,
      firstPost,
      lastPost,
      timeDistance: timeDistance?.toUpperCase(),
      dateString: dateString?.toUpperCase(),
      updatedTimeDistance: updatedTimeDistance?.toUpperCase(),
      updatedDateString: updatedDateString?.toUpperCase(),
      closedAtDateString: closedAtDateString?.toUpperCase(),
    };
  } catch (error) {
    console.log(
      `TOPIC-FORMAT-INVALID-${t?.category?.slug} ${t?.id} ${t?.topic?.title}.${IS_EXPO_DEV ? `Details: ${error}` : ''}`,
    );
    return undefined;
  }
};

export const processPosts = (rawPosts) => {
  return rawPosts?.map((p) => processPost(p)).filter((p) => p !== undefined);
};

export const processPost = (p) => {
  try {
    const id = p?.id;
    if (id === undefined || id === null) {
      return undefined;
    }

    const topicId = p?.topicId;
    if (topicId === undefined || topicId === null) {
      return undefined;
    }

    const category = p?.category;
    if (!category) {
      console.log(`POST-IGNORED-${category.slug}`, id, 'Required category data missing');
      return undefined;
    }

    const user = processUser(p?.user);
    if (!user) {
      console.log(`POST-IGNORED-${category.slug}`, id, 'Required user data missing');
      return undefined;
    }

    const timeDistance = timeDistanceToDate(p?.createdAt);
    const dateString = dateDisplayFromDates(p?.createdAt);

    const updatedTimeDistance = timeDistanceToDate(p?.updatedAt || p?.createdAt);
    const updatedDateString = dateDisplayFromDates(p?.updatedAt || p?.createdAt);

    const hiddenAtDateString = dateDisplayFromDates(p?.hiddenAt);

    if (!dateString || !timeDistance || !updatedTimeDistance || !updatedDateString) {
      console.log(`POST-IGNORED-${category.slug}`, id, p?.topicId, 'Required dates data missing');
      return undefined;
    }

    return {
      ...p,
      user,
      timeDistance: timeDistance?.toUpperCase(),
      dateString: dateString?.toUpperCase(),
      updatedTimeDistance: updatedTimeDistance?.toUpperCase(),
      updatedDateString: updatedDateString?.toUpperCase(),
      hiddenAtDateString: hiddenAtDateString?.toUpperCase(),
    };
  } catch (error) {
    console.log(
      `POST-FORMAT-INVALID-${p?.category?.slug} ${p?.id} ${p?.topicId}.${IS_EXPO_DEV ? `Details: ${error}` : ''}`,
    );
    return undefined;
  }
};

export const processUsers = (rawUsers) => {
  return rawUsers.map((u) => processUser(u, false)).filter((u) => u !== undefined);
};

export const processUser = (u, withPrivateData) => {
  try {
    const id = u?.id;
    if (id === undefined || id === null) {
      return undefined;
    }

    const timeDistance = timeDistanceToDate(u?.createdAt);
    const dateString = dateDisplayFromDates(u?.createdAt);

    const updatedTimeDistance = timeDistanceToDate(u?.updatedAt || u?.createdAt);
    const updatedDateString = dateDisplayFromDates(u?.updatedAt || u?.createdAt);

    const suspendedAtDateString = dateDisplayFromDates(u?.suspendedAt);
    const subscriptionExpireDateString = dateDisplayFromDates(u?.private?.subscription?.expirationDate);

    const lastSeenAtDateString = dateDisplayFromDates(u?.lastSeenAt);

    if (!dateString || !timeDistance || !updatedTimeDistance || !updatedDateString) {
      console.log('USER-IGNORED', id, 'Required dates data missing');
      return undefined;
    }

    let userResponse = {
      ...u,
      timeDistance: timeDistance?.toUpperCase(),
      dateString: dateString?.toUpperCase(),
      updatedTimeDistance: updatedTimeDistance?.toUpperCase(),
      updatedDateString: updatedDateString?.toUpperCase(),
      suspendedAtDateString: suspendedAtDateString?.toUpperCase(),
      subscriptionExpireDateString: subscriptionExpireDateString?.toUpperCase(),
      lastSeenAtDateString: lastSeenAtDateString?.toUpperCase(),
    };

    if (withPrivateData && (u?.private === undefined || u?.private === null)) {
      console.log('USER-IGNORED', id, 'User fetched is not current user');
      return undefined;
    }

    if (u?.private !== undefined && u?.private !== null) {
      const expirationDate = parseDate(u?.private?.subscription?.expirationDate);

      const expirationDateString = dateDisplayFromISO(u?.private?.subscription?.expirationDate);

      const expirationDateTs = expirationDate?.valueOf();

      const adminOrModerator = u?.private?.admin === true || u?.private?.moderator === true;

      const subscription = processSubscription(u?.private?.subscription);

      const private_data = { ...(u?.private || {}), subscription };

      userResponse = {
        ...userResponse,
        adminOrModerator,
        expirationDateTs,
        expirationDateString,
        private: private_data,
      };
    }

    return userResponse;
  } catch (error) {
    console.log(`USER-FORMAT-INVALID ${u?.id}.${IS_EXPO_DEV ? `Details: ${error}` : ''}`);
    return undefined;
  }
};

export const parseCategorySlugsArray = (categoryObjects) =>
  categoryObjects.map((x) => x && x?.slug).filter((x) => x !== undefined && x !== null);

export const processSubscription = (s) => {
  try {
    const type = s?.type;
    if (type === undefined || type === null) {
      return undefined;
    }

    if (s?.hidden === true) {
      return undefined;
    }

    let product;
    if (s?.product !== undefined && s?.product !== null) {
      product = processSubscriptionProduct(s?.product, s);
      if (!product) {
        return undefined;
      }
    }

    return {
      ...s,
      product,
      updatedAtString: dateTimeDisplayFromISO(s.updatedAt),
      autoRefreshedAtString: dateTimeDisplayFromISO(s.autoRefreshedAt),
    };
  } catch {
    console.log(`SUBSCRIPTION-INVALID ${s?.id}`);
    return undefined;
  }
};

export const processSubscriptionProduct = (p, s) => {
  try {
    const platform = s.platform;
    if (platform === undefined || platform === null) {
      return undefined;
    }

    const transactionId = s.transactionId;
    if (transactionId === undefined || transactionId === null) {
      return undefined;
    }

    let id;
    switch (platform) {
      case PLATFORMS_OBJECT.ios:
        id = p.iosId;
        break;

      case PLATFORMS_OBJECT.android:
        id = p.androidId;
        break;
    }
    if (id === undefined || id === null) {
      return undefined;
    }

    if (p?.defaultPriceDisplay === undefined || p?.defaultPriceDisplay === null) {
      return undefined;
    }

    return {
      ...p,
      id,
    };
  } catch {
    console.log(`PRODUCT-INVALID ${p?.id}`);
    return undefined;
  }
};
