import styled from 'styled-components/native';

const FillScreenAndCenterContainer = styled.View`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  ${(props) => (props.backgroundColor ? `background-color: ${props.backgroundColor};` : '')}
`;

export default FillScreenAndCenterContainer;
