import React, { useState } from 'react';
import { ImageBackground } from 'react-native';
import styled from 'styled-components/native';
import AppStyles, { genericImageRatio } from '../index';

const GenericPlaceholder = require('../../../assets/placeholders/placeholder-generic-square.png');

const StyledImageContent = ({ source, placeholder = GenericPlaceholder, onLoadEnd, children, ...rest }) => {
  const sourceProp =
    source?.uri !== undefined && source?.uri !== null ? { cache: 'force-cache', ...source } : placeholder;

  return (
    <ImageBackground {...rest} source={sourceProp}>
      {children}
    </ImageBackground>
  );
};

export const StyledImage = styled(StyledImageContent)`
  width: 100%;
  resize-mode: cover;
  aspect-ratio: ${genericImageRatio};
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const RoundTopCornersStyledImage = (props) => {
  return <StyledImage {...props} imageStyle={AppStyles.borderRadiusTopOnly} />;
};

export const RoundCornersStyledImage = (props) => {
  return <StyledImage {...props} imageStyle={AppStyles.borderRadius} />;
};
