import { getErrorCode } from '../../utils/error-json';
import apiCall from './index';
import { parseCategorySlugsArray, processTopics, processUsers } from './utils';

export const getClosedTopics = async (categoryObjects) => {
  try {
    if (categoryObjects === undefined || categoryObjects?.length === 0) {
      console.log('Empty categoryObjects for getClosedTopics.');
      return undefined;
    }
    const params = {
      category_slugs: parseCategorySlugsArray(categoryObjects),
    };
    const data = await apiCall({
      url: '/m/closed-topics.json',
      queryParams: params,
      method: 'GET',
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return undefined;
    }
    const results = (data?.results || [])
      .map((d) => {
        const value = processTopics(d?.topics);
        const key = d?.slug;
        if (key && value) {
          const title = categoryObjects.find((ct) => ct.slug === key)?.title;
          if (title) {
            return { title, topics: value };
          }
        }
        return undefined;
      })
      .filter((x) => x !== undefined && x?.topics?.length > 0);

    return results;
  } catch (error) {
    console.log(`Error getClosedTopics. Details: ${error}`);
    return undefined;
  }
};

export const getSuspendedUsers = async () => {
  try {
    const data = await apiCall({
      url: '/m/suspended-users.json',
      method: 'GET',
    });
    const errorCode = getErrorCode(data);
    if (errorCode) {
      return undefined;
    }
    const results = processUsers(data?.suspended || []);

    return results;
  } catch (error) {
    console.log(`Error getSuspendedUsers. Details: ${error}`);
    return undefined;
  }
};
