import React from 'react';
import { GENERIC_BLACK } from '../../../assets/colors';
import AppStyles from '../index';
import { ProjectBackImage } from './NavigationBarItem';

export const noNavBarOptions = { header: undefined, headerShown: false, gestureEnabled: false };

export const navBarOptions = {
  gestureEnabled: true,
  headerStyle: AppStyles.navBarStyle,
  headerTintColor: GENERIC_BLACK,
  headerTitleStyle: AppStyles.navBarTitleStyle,
  headerBackTitleStyle: AppStyles.navBarButtonStyle,
  headerTitleAlign: 'center',
  headerBackImage: () => <ProjectBackImage />,
  headerBackTitleVisible: false,
};

export const navBarWithoutShadowOptions = { ...navBarOptions, headerStyle: AppStyles.noNavBarShadowStyle };
export const navBarWithoutShadowAndTitleOptions = { ...navBarWithoutShadowOptions, title: '' };

export const navBarOptionsWithoutTitle = { ...navBarOptions, title: '' };
