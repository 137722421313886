import { IS_EXPO_DEV } from '../../Constants';
import {
  setUserApiKey,
  pushUrl,
  baseUrl,
  clientDeviceName,
  getKeyPair,
  setClientId,
  setUsername,
  getUserApiKey,
  getClientId,
  getUsername,
} from '../index';
import { decryptBase64 } from '../utils/crypto-utils';
import { createQueryStringFromHash, parseQueryParams } from '../utils/query-params';
import randomBytesString from '../utils/random-bytes-string';
import apiCall from './index';

const scopesList = ['notifications', 'session_info', 'one_time_password', 'read', 'write', 'message_bus', 'push'];

export const ERRORS = {
  apiNotReady: 'apiNotReady', // Should never happen
  invalidAuthParams: 'invalidAuthParams',
  invalidRedirectParams: 'invalidRedirectParams',
  invalidAuthUrl: 'invalidAuthUrl',
};

export const showSession = async () => {
  if (IS_EXPO_DEV) {
    const userApiKey = await getUserApiKey();
    const clientId = await getClientId();
    const username = await getUsername();
    console.log(`Username ${username} logged in with client-id: ${clientId} and user-api-key: ${userApiKey}`);
  }
};

export const clearSessionData = async () => {
  const userApiKey = await getUserApiKey();
  if (userApiKey) {
    try {
      await apiCall({ url: '/user-api-key/revoke', method: 'POST' });
    } catch {
      // Do nothing
    }
  }

  await setUserApiKey(undefined);
  await setClientId(undefined);
  await setUsername(undefined);
};

export const getLoginUrl = async (redirectUrl, privateKeyRef, uniqueStringRef) => {
  const clientId = randomBytesString(32);
  await setClientId(clientId);

  const { private: decryptKey, public: pem } = await getKeyPair();
  privateKeyRef.current = decryptKey;

  if (!clientId || !decryptKey || !pem) {
    throw ERRORS.apiNotReady;
  }

  const scopes = scopesList.join(',');
  const nonce = randomBytesString(16);
  uniqueStringRef.current = nonce;

  if (!redirectUrl || !clientDeviceName || !scopes || !pushUrl || !nonce) {
    return ERRORS.invalidAuthParams;
  }
  const queryString = createQueryStringFromHash({
    auth_redirect: redirectUrl,
    application_name: clientDeviceName,
    client_id: clientId,
    scopes: scopes,
    push_url: pushUrl,
    public_key: pem,
    nonce: nonce,
  });
  return `${baseUrl}/user-api-key/new${queryString}`;
};

export const processAuthUrl = async (authUrl, privateKeyRef, uniqueStringRef) => {
  if (authUrl) {
    const params = parseQueryParams(authUrl);
    const { payload } = params;
    if (payload && privateKeyRef.current) {
      const decryptedPayload = await decryptBase64(payload, privateKeyRef.current);
      const payloadObject = decryptedPayload && JSON.parse(decryptedPayload);
      if (
        payloadObject &&
        payloadObject.nonce &&
        uniqueStringRef.current &&
        uniqueStringRef.current === payloadObject.nonce &&
        payloadObject.key
      ) {
        await setUserApiKey(payloadObject.key);
        return;
      }
    }
    throw ERRORS.invalidRedirectParams;
  }
  throw ERRORS.invalidAuthUrl;
};
