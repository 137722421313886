import React from 'react';
import { DetailedInputLabel } from './input/styled';
import { TimezonePicker } from '../../../../containers/views/timezone-picker';
import { CellContainer } from './styled';

export const TimezoneCell = ({ title = 'Timezone', initial, setValue }) => {
  return (
    <CellContainer>
      <DetailedInputLabel title={(title || '').toUpperCase()} />
      <TimezonePicker {...{ initial, setValue }} />
    </CellContainer>
  );
};
