import { useState } from 'react';
import { categoryTypes } from '../networking/calls';
import { createTopic, updateTopic } from '../networking/calls/topics';
import { ErrorAlert, SimpleAlert } from '../utils/alert';
import { parseDate } from '../utils/date-helpers';
import { getErrorCode } from '../utils/error-json';
import isCompleteUrl from '../utils/is-complete-url';

const useFormSubmit = () => {
  const [loading, setLoading] = useState(false);

  const processFields = (fields) => {
    const attachmentsList = fields?.attachments || [];
    const results = {
      id: fields?.id,
      title: fields?.title,
      raw: fields?.raw,
      attachments: {
        list: attachmentsList,
      },
      category: fields?.category,
      session_id: fields?.session_id,
    };

    const m = fields?.raw_metadata;

    try {
      const creation_flow = !fields?.id;

      const attachmentsValid = true;

      let idValid = true;
      let titleValid = true;
      const contentValid = results?.raw && results?.raw?.trim().length >= 3;
      let metadataValid = true;

      if (!creation_flow && (!results?.id || results?.id?.length === 0)) {
        idValid = false;
      }
      if (!results?.title || results?.title?.length >= 255 || results?.title?.length === 0) {
        titleValid = false;
      }

      let priceValueValid = true;
      let priceCurrencyValid = true;
      let buyUrlValid = true;
      let zoomUrlValid = true;

      const price = m?.price;
      const value = price?.value;
      if (price) {
        if (value !== undefined && value !== null) {
          const number = parseFloat(value);
          if (!isNaN(number) && number >= 0) {
            if (number !== 0) {
              if (!isCompleteUrl(m?.buy_url)) {
                buyUrlValid = false;
              }
              if (!price?.currency || price?.currency?.length === 0) {
                priceCurrencyValid = false;
              }
            } else {
              if (m?.buy_url?.length > 0) {
                buyUrlValid = false;
              }
            }
          } else {
            priceValueValid = false;
          }
        } else {
          priceValueValid = false;
        }
      }

      let timezoneValid = true;
      let startDateValid = true;
      let endDateValid = true;
      let locationValid = true;

      let authorValid = true;
      let yearValid = true;

      switch (fields?.category) {
        case categoryTypes.news.slug:
          break;

        case categoryTypes.fatwas.slug:
        case categoryTypes.glossary.slug:
        case categoryTypes.excerpts.slug:
        case categoryTypes.fiqh.slug:
          break;

        case categoryTypes.events.slug:
          if (!m) {
            metadataValid = false;
            break;
          }
          if (!price) {
            priceValueValid = false;
            priceCurrencyValid = false;
          }

          if (!m?.timezone || m?.timezone?.length === 0) {
            timezoneValid = false;
          }
          if (!m?.start || m?.start?.length === 0) {
            startDateValid = false;
          }
          if (!m?.end || m?.end?.length === 0) {
            endDateValid = false;
          }
          const startDate = parseDate(m?.start);
          const endDate = parseDate(m?.end);
          if (!startDate) {
            startDateValid = false;
          }
          if (!endDate) {
            endDateValid = false;
          }
          if (startDate && endDate) {
            endDateValid = endDateValid && endDate.valueOf() > startDate.valueOf();
          }

          if (!(m?.location?.length >= 0)) {
            locationValid = false;
          }

          buyUrlValid = isCompleteUrl(m?.buy_url);

          if (m?.zoom_url?.length > 0) {
            zoomUrlValid = isCompleteUrl(m?.zoom_url);
          }

          metadataValid =
            metadataValid &&
            priceValueValid &&
            priceCurrencyValid &&
            buyUrlValid &&
            zoomUrlValid &&
            timezoneValid &&
            startDateValid &&
            endDateValid &&
            locationValid;

          if (metadataValid) {
            results.raw_metadata = m;
          }

          break;

        case categoryTypes.books.slug:
        case categoryTypes.researchPapers.slug:
        case categoryTypes.ifikrDigests.slug:
        case categoryTypes.articles.slug:
          if (!m) {
            metadataValid = false;
            break;
          }

          if (!m?.author || m?.author?.length === 0) {
            authorValid = false;
          }

          const year = m?.year && parseInt(m?.year);
          if (year === undefined || year === null || isNaN(year)) {
            yearValid = false;
          }

          metadataValid =
            metadataValid && priceValueValid && priceCurrencyValid && buyUrlValid && authorValid && yearValid;

          if (metadataValid) {
            results.raw_metadata = m;
          }

          break;

        case categoryTypes.posts.slug:
          break;

        case categoryTypes.questions.slug:
          break;
      }

      if (!attachmentsValid || !idValid || !titleValid || !contentValid || !metadataValid) {
        SimpleAlert(
          'Validations failed:',
          `${!idValid ? '\n- Id is missing or invalid.' : ''}${
            !attachmentsValid ? '\n- Attachments are missing or invalid.' : ''
          }${!titleValid ? '\n- Title needs to contain between 1 and 254 characters' : ''}${
            !contentValid ? '\n- Content needs to contain at least 3 characters' : ''
          }${!priceValueValid ? '\n- Price value needs to be a number >= 0' : ''}${
            !priceCurrencyValid ? '\n- Price currency needs to be introduced if price value is not 0' : ''
          }${
            !buyUrlValid
              ? '\n- Buy URL needs to be a valid url (prefixed with http:// or https://) if working with events and is optional for the rest of topics if price value is 0. '
              : ''
          }${!zoomUrlValid ? '\n- Zoom meeting URL needs to be a valid url (prefixed with https://)' : ''}${
            !timezoneValid ? '\n- A timezone needs to be picked from the picker' : ''
          }${!startDateValid ? '\n- An event start date needs to be picked from the picker' : ''}${
            !endDateValid
              ? '\n- An event end date needs to be picked from the picker and needs to be after the start date'
              : ''
          }${!locationValid ? '\n- Location field data is missing' : ''}${
            !authorValid ? '\n- Author needs to be present' : ''
          }${!yearValid ? '\n- Year needs to be a valid number' : ''}`,
        );
        return undefined;
      }

      return results;
    } catch (error) {
      SimpleAlert('Validation process failed', `Something went wrong. Details: ${error.toString()}`);
      return undefined;
    }
  };

  const submit = async (raw_fields) => {
    setLoading(true);
    let topic;

    const fields = processFields(raw_fields);
    if (!fields) {
      setLoading(false);
      return; // Handled in processFields
    }

    if (fields?.id) {
      topic = await updateTopic(fields?.id, fields?.title, fields?.raw, fields?.raw_metadata, fields?.attachments);
    } else {
      if (fields?.category && fields?.session_id) {
        topic = await createTopic(
          fields?.category,
          fields?.session_id,
          fields?.title,
          fields?.raw,
          fields?.raw_metadata,
          fields?.attachments,
        );
      } else {
        ErrorAlert('Form submit procedure was blocked by backend. Please contact a developer.');
        return undefined;
      }
    }
    setLoading(false);

    const errorCode = getErrorCode(topic);
    if (errorCode) {
      ErrorAlert(`Something went wrong with processing topic request. Details: ${topic?.error?.message || errorCode}`);
      return undefined;
    }

    if (!topic?.id) {
      ErrorAlert('Form submit procedure was blocked by backend. Please contact a developer.');
      return undefined;
    }

    if (!topic?.topic) {
      ErrorAlert(
        `Topic was created/updated successfully [ID: ${topic?.id}], but the interface could not be updated. Topic format may be invalid, please contact developer for more details.`,
      );
      return undefined;
    }

    SimpleAlert(
      fields?.id ? 'Topic updated' : 'Topic created',
      `Topic was successfully created/updated. ID: ${topic?.id}`,
    );

    return topic;
  };

  return { submit, loading };
};

export default useFormSubmit;
