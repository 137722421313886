import React, { useEffect, useState } from 'react';
import { SolidButtonLabel } from '../../../../containers/labels';
import { ProjectNavGreenText, ProjectNavRedText } from '../../../../containers/navigation/NavigationBarItem';
import { MiddleBlueSeparatorWithPadding } from '../../../../containers/views/Separators';
import WebScreenContainer from '../../../../containers/views/WebScreenContainer';
import TopicCell from '../../shared/cells/topic-cell';
import UserCell from '../../shared/cells/user-cell';
import AttachmentsCell from '../../shared/form-cells/attachments/attachments-cell';
import DetailedInputCell from '../../shared/form-cells/input/detailed-input-cell';
import { categoryTypes } from '../../../../networking/calls';
import RichTextEditorCell from '../../shared/form-cells/rich-text-editor-cell';
import { CellButton, CellLoader, ReverseCellRow } from '../../shared/form-cells/styled';
import useFormSubmit from '../../../../hooks/useFormSubmit';
import TopicCloseComponent from '../../shared/TopicCloseComponent';

const categoryObject = categoryTypes.excerpts;
const typeCounts = { image: 100, video: 100, youtube: 100, vimeo: 100, topic: 100, link: 100 };

const Excerpt = ({ navigation, route }) => {
  const session_id = route.params.session_id;
  const item = route.params.item;

  const [topic, setTopic] = useState(item);

  const [title, setTitle] = useState(item?.topic?.title || '');
  const [raw, setRaw] = useState(item?.firstPost?.raw || '');

  const { submit, loading } = useFormSubmit();

  const [attachments, setAttachments] = useState(
    item?.firstPost?.attachments?.list?.map((t, i) => ({ ...t, key: t?.id || t?.url || i })) || [],
  );
  useEffect(() => {
    if (topic) {
      if (topic?.topic?.closed) {
        navigation.setOptions({
          headerRight: () => <ProjectNavRedText title={categoryObject.object.toUpperCase() + ' CLOSED'} />,
        });
      } else {
        navigation.setOptions({
          headerRight: () => <ProjectNavGreenText title={categoryObject.object.toUpperCase() + ' OPEN'} />,
        });
      }
    }
  }, [topic]);

  const onTapSubmit = async () => {
    const t = await submit({
      id: topic?.id,
      category: categoryObject.slug,
      session_id: session_id,
      title,
      attachments,
      raw,
    });
    if (t) {
      setTopic(t);
    }
  };

  return (
    <WebScreenContainer>
      {topic && (
        <>
          <TopicCell item={topic} disabled={true} />
          <MiddleBlueSeparatorWithPadding />
          <TopicCloseComponent item={topic} setItem={setTopic} capitalizedCustomTag={categoryObject.object} />
          <MiddleBlueSeparatorWithPadding />
        </>
      )}
      {topic && topic?.importedId && (
        <>
          <DetailedInputCell
            disabled={true}
            value={topic?.importedId}
            setValue={() => {}}
            title={'Old I-FIKR Database ID'}
            placeholder={'Unknown'}
          />
          <MiddleBlueSeparatorWithPadding />
        </>
      )}
      <DetailedInputCell value={title} setValue={setTitle} title={'Title'} placeholder={'Enter title'} />
      <RichTextEditorCell initial={raw} placeholder={'Enter content'} setValue={setRaw} />
      <AttachmentsCell value={attachments} setValue={setAttachments} typeCounts={typeCounts} />
      <MiddleBlueSeparatorWithPadding />
      <ReverseCellRow>
        <CellButton onPress={onTapSubmit}>
          <SolidButtonLabel title={(!topic ? 'CREATE' : 'UPDATE') + ' ' + categoryObject.object.toUpperCase()} />
        </CellButton>
        {loading && <CellLoader />}
      </ReverseCellRow>
      {topic && (
        <>
          <MiddleBlueSeparatorWithPadding />
          <UserCell user={topic?.firstPost?.user} />
        </>
      )}
    </WebScreenContainer>
  );
};

export default Excerpt;
