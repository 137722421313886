import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import Fonts from './assets/fonts';
import { resumeSessionWithRawReduxStore } from './src/hooks/useAuth';
import preloadImages from './src/preparation/AssetsLoader';
import store from './src/store';
import AppStack from './src/ui';

export default function App() {
  const [didSetup, setDidSetup] = useState();

  const initializeApp = async () => {
    await resumeSessionWithRawReduxStore(store);

    await preloadImages();
    await Fonts.load();

    setDidSetup(true);
  };

  useEffect(() => {
    initializeApp().then();
  }, []);

  return !didSetup ? null : (
    <Provider store={store}>
      <AppStack />
    </Provider>
  );
}
