import React, { useEffect, useState } from 'react';
import { SolidButtonLabel } from '../../../../containers/labels';
import { ProjectNavGreenText, ProjectNavRedText } from '../../../../containers/navigation/NavigationBarItem';
import { MiddleBlueSeparatorWithPadding } from '../../../../containers/views/Separators';
import WebScreenContainer from '../../../../containers/views/WebScreenContainer';
import { SimpleAlert } from '../../../../utils/alert';
import { parseDate } from '../../../../utils/date-helpers';
import TopicCell from '../../shared/cells/topic-cell';
import UserCell from '../../shared/cells/user-cell';
import AttachmentsCell from '../../shared/form-cells/attachments/attachments-cell';
import { DateTimeCell } from '../../shared/form-cells/date-time-cell';
import DetailedInputCell from '../../shared/form-cells/input/detailed-input-cell';
import { categoryTypes } from '../../../../networking/calls';
import RichTextEditorCell from '../../shared/form-cells/rich-text-editor-cell';
import { CellButton, CellLoader, ReverseCellRow } from '../../shared/form-cells/styled';
import useFormSubmit from '../../../../hooks/useFormSubmit';
import { TimezoneCell } from '../../shared/form-cells/timezone-cell';
import TopicCloseComponent from '../../shared/TopicCloseComponent';

const categoryObject = categoryTypes.events;
const typeCounts = { image: 100, video: 100, youtube: 100, vimeo: 100, topic: 100, link: 100 };

const EventPage = ({ navigation, route }) => {
  const session_id = route.params.session_id;
  const item = route.params.item;

  const [topic, setTopic] = useState(item);

  const [title, setTitle] = useState(item?.topic?.title || '');
  const [raw, setRaw] = useState(item?.firstPost?.raw || '');

  const [location, setLocation] = useState(item?.object?.location || '');

  const [startDate, setStartDate] = useState(parseDate(item?.object?.dates?.start));
  const [endDate, setEndDate] = useState(parseDate(item?.object?.dates?.end));
  const [timezone, setTimezone] = useState(item?.object?.dates?.timezone) || '';
  const [value, setValue] = useState(item?.object?.price?.value === 0 ? '0' : item?.object?.price?.value || '');
  const [currency, setCurrency] = useState(item?.object?.price?.currency || '');
  const [buyUrl, setBuyUrl] = useState(item?.object?.buyUrl || '');
  const [zoomUrl, setZoomUrl] = useState(item?.object?.zoomUrl || '');

  const [attachments, setAttachments] = useState(
    item?.firstPost?.attachments?.list?.map((t, i) => ({ ...t, key: t?.id || t?.url || i })) || [],
  );
  const { submit, loading } = useFormSubmit();

  useEffect(() => {
    if (topic) {
      if (topic?.topic?.closed) {
        navigation.setOptions({
          headerRight: () => <ProjectNavRedText title={categoryObject.object.toUpperCase() + ' CLOSED'} />,
        });
      } else {
        navigation.setOptions({
          headerRight: () => <ProjectNavGreenText title={categoryObject.object.toUpperCase() + ' OPEN'} />,
        });
      }
    }
  }, [topic]);

  const onTapSubmit = async () => {
    const startISOString = startDate && typeof startDate.getMonth === 'function' && startDate.toISOString();
    if (!startISOString) {
      SimpleAlert(
        'Validations failed:',
        'Event start date seems to be invalid. Please change the date in the picker and try again.',
      );
      return;
    }

    const endISOString = endDate && typeof endDate.getMonth === 'function' && endDate.toISOString();
    if (!endISOString) {
      SimpleAlert(
        'Validations failed:',
        'Event end date seems to be invalid. Please change the date in the picker and try again.',
      );
      return;
    }

    const valueNumber = parseFloat(value);
    if (isNaN(valueNumber) || (!isNaN(valueNumber) && valueNumber < 0)) {
      SimpleAlert('Validations failed:', 'Price value is not a number or is lesser than 0.');
      return;
    }

    const t = await submit({
      id: topic?.id,
      category: categoryObject.slug,
      session_id: session_id,
      attachments,
      raw_metadata: {
        location: location,
        start: startISOString,
        end: endISOString,
        timezone: timezone,
        price: {
          value: valueNumber,
          currency,
        },
        buy_url: buyUrl,
        zoom_url: zoomUrl,
      },
      title,
      raw,
    });
    if (t) {
      setTopic(t);
    }
  };

  const valueShouldChange = (text) => {
    return text
      .replace(/[^0-9.]/g, '')
      .replace(/(\.)(?=.*\1)/g, '')
      .slice(0, 7);
  };

  return (
    <WebScreenContainer>
      {topic && (
        <>
          <TopicCell item={topic} disabled={true} />
          <MiddleBlueSeparatorWithPadding />
          <TopicCloseComponent item={topic} setItem={setTopic} capitalizedCustomTag={categoryObject.object} />
          <MiddleBlueSeparatorWithPadding />
        </>
      )}
      <DetailedInputCell value={title} setValue={setTitle} title={'Title'} placeholder={'Enter title'} />
      <DetailedInputCell
        value={location}
        setValue={setLocation}
        title={'Location'}
        placeholder={'Enter location details'}
      />
      <DateTimeCell initial={startDate} setValue={setStartDate} title={'Start date'} />
      <DateTimeCell initial={endDate} setValue={setEndDate} title={'End date'} />
      <TimezoneCell initial={timezone} setValue={setTimezone} title={'Timezone'} />
      <DetailedInputCell
        value={value}
        setValue={setValue}
        title={'Price value'}
        shouldChangeText={valueShouldChange}
        placeholder={'Enter price value'}
      />
      <DetailedInputCell
        value={currency}
        setValue={setCurrency}
        title={'Price currency'}
        placeholder={'Enter price currency'}
      />
      <DetailedInputCell
        value={buyUrl}
        setValue={setBuyUrl}
        title={'Buy tickets or Event url'}
        placeholder={'Enter buy or event url'}
      />
      <DetailedInputCell
        value={zoomUrl}
        setValue={setZoomUrl}
        title={'Zoom meeting invite link'}
        placeholder={'https://zoom.us/j/xxxxxxxxxxx?pwd=yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy'}
      />
      <RichTextEditorCell initial={raw} placeholder={'Enter content'} setValue={setRaw} />
      <AttachmentsCell value={attachments} setValue={setAttachments} typeCounts={typeCounts} />
      <MiddleBlueSeparatorWithPadding />
      <ReverseCellRow>
        <CellButton onPress={onTapSubmit}>
          <SolidButtonLabel title={(!topic ? 'CREATE' : 'UPDATE') + ' ' + categoryObject.object.toUpperCase()} />
        </CellButton>
        {loading && <CellLoader />}
      </ReverseCellRow>
      {topic && (
        <>
          <MiddleBlueSeparatorWithPadding />
          <UserCell user={topic?.firstPost?.user} />
        </>
      )}
    </WebScreenContainer>
  );
};

export default EventPage;
