import React, { useEffect, useState } from 'react';
import { ProjectNavBlueButton } from '../../../containers/navigation/NavigationBarItem';
import WebScreenContainer from '../../../containers/views/WebScreenContainer';
import DisabledListComponent from '../shared/disabled-list';
import SearchComponent from '../shared/search';
import { ITEM_TYPE } from '../shared/search/search-list';

const Users = ({ navigation }) => {
  const [showClosedOnly, setShowClosedOnly] = useState(false);

  useEffect(() => {
    if (!showClosedOnly) {
      navigation.setOptions({
        headerRight: () => <ProjectNavBlueButton title={'SUSPENDED USERS'} onPress={() => setShowClosedOnly(true)} />,
      });
    } else {
      navigation.setOptions({
        headerRight: () => <ProjectNavBlueButton title={'SHOW SEARCH'} onPress={() => setShowClosedOnly(false)} />,
      });
    }
  }, [showClosedOnly]);

  return (
    <WebScreenContainer>
      {!showClosedOnly && (
        <SearchComponent
          itemType={ITEM_TYPE.user}
          title={'SEARCH USERS'}
          placeholder={'Enter an user id, part of a user username or full email address to search for an I-FIKR user'}
        />
      )}
      {showClosedOnly && (
        <>
          <DisabledListComponent itemType={ITEM_TYPE.user} />
        </>
      )}
    </WebScreenContainer>
  );
};

export default Users;
