import React, { useEffect, useState } from 'react';
import { SolidButtonLabel } from '../../../../containers/labels';
import { ProjectNavGreenText, ProjectNavRedText } from '../../../../containers/navigation/NavigationBarItem';
import { MiddleBlueSeparatorWithPadding } from '../../../../containers/views/Separators';
import WebScreenContainer from '../../../../containers/views/WebScreenContainer';
import { ErrorAlert, SimpleAlert } from '../../../../utils/alert';
import TopicCell from '../../shared/cells/topic-cell';
import UserCell from '../../shared/cells/user-cell';
import AttachmentsCell from '../../shared/form-cells/attachments/attachments-cell';
import DetailedInputCell from '../../shared/form-cells/input/detailed-input-cell';
import { categoryTypes } from '../../../../networking/calls';
import RichTextEditorCell from '../../shared/form-cells/rich-text-editor-cell';
import { CellButton, CellLoader, ReverseCellRow } from '../../shared/form-cells/styled';
import useFormSubmit from '../../../../hooks/useFormSubmit';
import TopicCloseComponent from '../../shared/TopicCloseComponent';

const categoryObject = categoryTypes.researchPapers;
const typeCounts = { epub: 1, image: 1, pdf: 100, topic: 100, link: 100 };

const ResearchPaper = ({ navigation, route }) => {
  const session_id = route.params.session_id;
  const item = route.params.item;

  const [topic, setTopic] = useState(item);

  const [title, setTitle] = useState(item?.topic?.title || '');
  const [raw, setRaw] = useState(item?.firstPost?.raw || '');

  const [author, setAuthor] = useState(item?.object?.author || '');
  const [year, setYear] = useState(item?.object?.year === 0 ? '0' : item?.object?.year || '');

  const [attachments, setAttachments] = useState(
    item?.firstPost?.attachments?.list?.map((t, i) => ({ ...t, key: t?.id || t?.url || i })) || [],
  );
  const { submit, loading } = useFormSubmit();

  useEffect(() => {
    if (topic) {
      if (topic?.topic?.closed) {
        navigation.setOptions({
          headerRight: () => <ProjectNavRedText title={categoryObject.object.toUpperCase() + ' CLOSED'} />,
        });
      } else {
        navigation.setOptions({
          headerRight: () => <ProjectNavGreenText title={categoryObject.object.toUpperCase() + ' OPEN'} />,
        });
      }
    }
  }, [topic]);

  const onTapSubmit = async () => {
    let yearNumber;
    try {
      // eslint-disable-next-line radix
      yearNumber = parseInt(year);
    } catch {
      SimpleAlert('Validations failed:', 'Year is not a number');
      return;
    }
    if (isNaN(yearNumber)) {
      SimpleAlert('Validations failed:', 'Year is not a number');
      return;
    }

    const t = await submit({
      id: topic?.id,
      category: categoryObject.slug,
      session_id: session_id,
      attachments,
      raw_metadata: {
        author,
        year: yearNumber,
        price: {
          value: 0,
        },
      },
      title,
      raw,
    });
    if (t) {
      setTopic(t);
    }
  };

  const yearShouldChange = (text) => {
    return text.replace(/[^0-9]/g, '');
  };

  return (
    <WebScreenContainer>
      {topic && (
        <>
          <TopicCell item={topic} disabled={true} />
          <MiddleBlueSeparatorWithPadding />
          <TopicCloseComponent item={topic} setItem={setTopic} capitalizedCustomTag={categoryObject.object} />
          <MiddleBlueSeparatorWithPadding />
        </>
      )}
      {topic && topic?.importedId && (
        <>
          <DetailedInputCell
            disabled={true}
            value={topic?.importedId}
            setValue={() => {}}
            title={'Old I-FIKR Database ID'}
            placeholder={'Unknown'}
          />
          <MiddleBlueSeparatorWithPadding />
        </>
      )}
      <DetailedInputCell value={title} setValue={setTitle} title={'Title'} placeholder={'Enter title'} />
      <DetailedInputCell value={author} setValue={setAuthor} title={'Author'} placeholder={'Enter author'} />
      <DetailedInputCell
        value={year}
        setValue={setYear}
        title={'Year'}
        shouldChangeText={yearShouldChange}
        placeholder={'Enter year'}
      />
      <RichTextEditorCell initial={raw} placeholder={'Enter content'} setValue={setRaw} />
      <AttachmentsCell value={attachments} setValue={setAttachments} typeCounts={typeCounts} />
      <MiddleBlueSeparatorWithPadding />
      <ReverseCellRow>
        <CellButton onPress={onTapSubmit}>
          <SolidButtonLabel title={(!topic ? 'CREATE' : 'UPDATE') + ' ' + categoryObject.object.toUpperCase()} />
        </CellButton>
        {loading && <CellLoader />}
      </ReverseCellRow>
      {topic && (
        <>
          <MiddleBlueSeparatorWithPadding />
          <UserCell user={topic?.firstPost?.user} />
        </>
      )}
    </WebScreenContainer>
  );
};

export default ResearchPaper;
