import React, { useState } from 'react';
import { SolidButtonLabel } from '../../../../../../containers/labels';
import { SimpleAlert } from '../../../../../../utils/alert';
import { ATTACHMENT_BUTTON_WIDTH } from '../../../../../defines';
import { CREATE_YT_VIDEO_URL } from '../../../../../defines';
import DetailedInputRaw from '../../input/detailed-input-raw';
import { CellButton, CellRow } from '../../styled';

const AttachmentsAddYoutube = ({ onSuccessfulProcessing, disabled, shouldStartProcess }) => {
  const [caption, setCaption] = useState('');
  const [id, setId] = useState('');

  const validId = () => {
    if (!(id?.length > 0)) {
      return false;
    }
    return !(
      id?.startsWith('http') ||
      id?.startsWith('www') ||
      id?.startsWith('youtu.be') ||
      id?.startsWith('youtube')
    );
  };

  const onPress = async () => {
    if (!shouldStartProcess()) {
      return;
    }
    if (!validId()) {
      SimpleAlert(
        'Youtube video id missing',
        'Please enter only the Youtube video id.\n\nExamples:' +
          '\nhttps://www.youtube.com/watch?v=fCXxHcG14XI => fCXxHcG14XI' +
          '\nyoutu.be/fCXxHcG14XI => fCXxHcG14XI' +
          '\nhttps://www.youtube.com/embed/fCXxHcG14XI?rel=0&autoplay=0&showinfo=0&controls=0 => fCXxHcG14XI',
      );
      return;
    }
    onSuccessfulProcessing(caption, { caption, id, url: CREATE_YT_VIDEO_URL(id), type: 'youtube' });
    setCaption('');
    setId('');
  };

  return (
    <CellRow>
      <DetailedInputRaw value={caption} setValue={setCaption} placeholder={'Enter youtube caption (optional)'} />
      <DetailedInputRaw addMarginLeft={true} value={id} setValue={setId} placeholder={'Enter youtube video id'} />
      <CellButton disabled={disabled} buttonWidth={ATTACHMENT_BUTTON_WIDTH} onPress={() => onPress()}>
        <SolidButtonLabel title={'ADD YOUTUBE VIDEO'} />
      </CellButton>
    </CellRow>
  );
};

export default AttachmentsAddYoutube;
