import React, { useState } from 'react';
import WebScreenContainer from '../../../../containers/views/WebScreenContainer';
import { MiddleBlueSeparatorWithPadding } from '../../../../containers/views/Separators';
import DetailedInputCell from '../../shared/form-cells/input/detailed-input-cell';
import { DateTimeCell } from '../../shared/form-cells/date-time-cell';
import { SolidButtonLabel } from '../../../../containers/labels';
import { SolidButton } from '../../../../containers/buttons';
import { ConfirmationAlert, ErrorAlert, SimpleAlert } from '../../../../utils/alert';
import {
  addUserToBenefactor,
  removeUserFromBenefactor,
  updateBenefactor,
} from '../../../../networking/calls/benefactors';
import { RED } from '../../../../../assets/colors';
import { ButtonsContainer, SearchListWrapper } from './styled';
import { CellLoader } from '../../shared/form-cells/styled';
import ContentList, { CONTENT_TYPE } from '../../shared/content-list';
import { getErrorCode } from '../../../../utils/error-json';
import useSearch from '../../../../hooks/useSearch';
import SearchList, { ITEM_TYPE } from '../../shared/search/search-list';
import UserCell from '../../shared/cells/user-cell';

const Benefactor = ({ route }) => {
  const item = route?.params?.item;
  const defaultExpirationDate = new Date(new Date().getTime() + 1 * 365 * 24 * 60 * 60 * 1000);

  const [benefactor, setBenefactor] = useState(item);
  const [refreshTrigger, setRefreshTrigger] = useState(new Date().toISOString());
  const [refreshDate, setRefreshDate] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [didEditFields, setDidEditFields] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const [name, setName] = useState(benefactor?.name);
  const [description, setDescription] = useState(benefactor?.description);
  const [canPost, setCanPost] = useState(benefactor?.canPost);
  const [canPostVideos, setCanPostVideos] = useState(benefactor?.canPostVideos);

  const { results, setResults, searchText, loading, setSearchText, noFiltersRef, resetSearching } = useSearch({
    itemType: ITEM_TYPE.user,
  });

  const [expiryDate, setExpiryDate] = useState(defaultExpirationDate);

  const _setName = (value) => {
    setName(value);
    setDidEditFields(true);
  };
  const _setDescription = (value) => {
    setDescription(value);
    setDidEditFields(true);
  };

  const clearUserStates = () => {
    setSearchText('');
    setExpiryDate(defaultExpirationDate);
    setRefreshDate(defaultExpirationDate);
    setSelectedUser(undefined);
  };

  const onAddUser = async () => {
    if (!(selectedUser && selectedUser?.username?.length > 0)) {
      SimpleAlert('Validations failed:', 'Username should not be empty. Please enter a valid username and try again.');
      return;
    }

    const expirationISOString = expiryDate && typeof expiryDate.getMonth === 'function' && expiryDate.toISOString();
    if (!expirationISOString) {
      SimpleAlert(
        'Validations failed:',
        'Expiration date seems to be invalid. Please change the date in the picker and try again.',
      );
      return;
    }

    const result = await addUserToBenefactor(item?.id, selectedUser?.username, expirationISOString);
    const errorCode = getErrorCode(result);
    if (errorCode) {
      if (errorCode === 'found_user_already') {
        const message = result?.error?.message?.concat(' Benefactor name: ', result?.error?.details);
        ErrorAlert(message || errorCode);
        return;
      }
      ErrorAlert(result?.error?.message || errorCode);
    } else if (result && result?.id) {
      setBenefactor(result);
      setRefreshTrigger(new Date().toISOString());
      clearUserStates();
    }
  };

  const toggleCanPost = () => {
    setDidEditFields(true);
    setCanPost(!canPost);
  };
  const toggleCanPostVideos = () => {
    setDidEditFields(true);
    setCanPostVideos(!canPostVideos);
  };

  const setStatesWithBenefactorInfo = (benefactorData) => {
    setName(benefactorData?.name);
    setDescription(benefactorData?.description);
    setCanPost(benefactorData?.canPost);
    setCanPostVideos(benefactorData?.canPostVideos);
  };

  const onClearUpdates = () => {
    setDidEditFields(false);
    setStatesWithBenefactorInfo(benefactor);
  };

  const onSubmitUpdates = async () => {
    setIsLoading(true);
    const result = await updateBenefactor(benefactor?.id, name, description, canPost, canPostVideos);
    setIsLoading(false);

    if (result && result?.id) {
      setBenefactor(result);
    } else {
      onClearUpdates();
    }
  };

  const removeUserAction = async (_username) => {
    await removeUserFromBenefactor(benefactor?.id, _username);
    setRefreshTrigger(new Date().toISOString());
  };

  const onRemoveBenefactorUser = async (_username) => {
    ConfirmationAlert('Confirmation', 'Are you sure you want to remove the user from the benefactor?', async () =>
      removeUserAction(_username),
    );
  };

  const onSuggestedUserPress = (user) => {
    if (user) {
      setSearchText('');
      setSelectedUser(user);
    }
    resetSearching();
  };

  const onClearSelectedUser = () => {
    setSelectedUser(undefined);
  };

  return (
    <WebScreenContainer>
      <DetailedInputCell title={'id'} value={benefactor?.id} disabled={true} />
      <DetailedInputCell
        title={'name'}
        value={name}
        setValue={_setName}
        placeholder={'Enter name'}
        disabled={isLoading}
      />
      <DetailedInputCell
        title={'description'}
        value={description}
        setValue={_setDescription}
        placeholder={'Enter description'}
        disabled={isLoading}
      />
      <DetailedInputCell
        title={'can post'}
        value={canPost ? 'Yes' : 'No'}
        disabled={true}
        buttonTitle={'Toggle'}
        buttonPress={toggleCanPost}
        forceEnableButton={!isLoading}
      />
      <DetailedInputCell
        title={'can post videos'}
        value={canPostVideos ? 'Yes' : 'No'}
        disabled={true}
        buttonTitle={'Toggle'}
        buttonPress={toggleCanPostVideos}
        forceEnableButton={!isLoading}
      />
      <ButtonsContainer>
        {isLoading && <CellLoader />}
        {didEditFields && (
          <>
            <SolidButton disabled={false} onPress={onSubmitUpdates}>
              <SolidButtonLabel title={'Apply'} />
            </SolidButton>
            <SolidButton disabled={false} onPress={onClearUpdates} backgroundColor={RED}>
              <SolidButtonLabel title={'Discard changes'} />
            </SolidButton>
          </>
        )}
      </ButtonsContainer>
      <MiddleBlueSeparatorWithPadding />
      {selectedUser ? (
        <UserCell user={selectedUser} buttonPress={onClearSelectedUser} buttonTitle={'Deselect user'} />
      ) : (
        <DetailedInputCell
          title={'username'}
          value={searchText}
          setValue={setSearchText}
          disabled={false}
          placeholder={'Enter username'}
          loading={loading}
        />
      )}
      {selectedUser && (
        <>
          <DateTimeCell
            initial={expiryDate}
            setValue={setExpiryDate}
            title={'Expiration date'}
            refreshDate={refreshDate}
          />
          <SolidButton disabled={false} onPress={onAddUser}>
            <SolidButtonLabel title={'Add user'} />
          </SolidButton>
        </>
      )}
      {results && results?.length > 0 && (
        <SearchListWrapper>
          <SearchList
            loading={loading}
            searchText={searchText}
            results={results}
            setResults={setResults}
            noFiltersRef={noFiltersRef}
            itemType={ITEM_TYPE.user}
            onCellPress={onSuggestedUserPress}
          />
        </SearchListWrapper>
      )}
      <MiddleBlueSeparatorWithPadding />
      <ContentList
        contentType={CONTENT_TYPE.benefactorUser}
        itemId={benefactor?.id}
        refreshTrigger={refreshTrigger}
        buttonTitle={'Remove user'}
        buttonPress={onRemoveBenefactorUser}
      />
    </WebScreenContainer>
  );
};

export default Benefactor;
